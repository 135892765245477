import {SvgProps} from "../index";

export const IconFolder: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="-1 -1 30 30"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M11.5479 22.083C12.1367 22.2412 12.6729 21.9248 12.8486 21.336L17.1729 6.50882C17.3398 5.95511 17.0674 5.41019 16.4785 5.25199C15.8984 5.09378 15.3887 5.34867 15.1953 5.99906L10.8887 20.7647C10.7129 21.3623 10.9414 21.916 11.5479 22.083ZM1.29102 13.6543C1.29102 13.9532 1.42285 14.2608 1.67773 14.4805L7.36426 19.6133C7.83008 20.0352 8.4541 20.0264 8.84082 19.5869C9.23633 19.1475 9.18359 18.5147 8.73535 18.1104L3.81348 13.6543L8.73535 9.18949C9.18359 8.78519 9.23633 8.16117 8.84082 7.72171C8.4541 7.28226 7.83008 7.27347 7.36426 7.69535L1.67773 12.8194C1.42285 13.0479 1.29102 13.3555 1.29102 13.6543ZM26.7002 13.6543C26.7002 13.3555 26.5684 13.0479 26.3135 12.8194L20.627 7.69535C20.1611 7.27347 19.5371 7.28226 19.1504 7.72171C18.7549 8.16117 18.8076 8.78519 19.2559 9.18949L24.1777 13.6543L19.2559 18.1104C18.8076 18.5147 18.7549 19.1475 19.1504 19.5869C19.5371 20.0264 20.1611 20.0352 20.627 19.6133L26.3135 14.4805C26.5684 14.2608 26.7002 13.9532 26.7002 13.6543Z"
            fill="black"
        />
    </svg>
)

export default IconFolder;
