import {SvgProps} from "../index";

export const IconCross: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="-1 -1 31 31"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.17898 20.8144C5.64401 21.3494 5.61853 22.3047 6.19171 22.8524C6.73942 23.4256 7.70746 23.4001 8.24243 22.8651L14.4201 16.6875L20.5977 22.8651C21.1454 23.4128 22.088 23.4256 22.6357 22.8524C23.2088 22.3047 23.1961 21.3494 22.6484 20.8017L16.4708 14.6241L22.6484 8.45917C23.1961 7.89873 23.2088 6.95616 22.6357 6.40845C22.088 5.83527 21.1454 5.84801 20.5977 6.39572L14.4201 12.5733L8.24243 6.39572C7.70746 5.86075 6.73942 5.83527 6.19171 6.40845C5.61853 6.95616 5.64401 7.91146 6.17898 8.44643L12.3566 14.6241L6.17898 20.8144Z"
            fill="black"
        />
    </svg>
)

export default IconCross;