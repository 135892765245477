import {SvgProps} from "../index";

export const IconStrikethrough: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <g clipPath="url(#clip0_520_539)">
            <mask id="mask0_520_539" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="29">
                <path d="M28 0.610001H0V28.61H28V0.610001Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_520_539)">
                <path d="M18 16.45C18.8 16.99 19.29 17.87 19.29 19.37C19.29 22.29 16.92 24.66 14 24.66C11.8 24.66 9.87 23.34 9.07 21.3C8.87 20.79 8.28 20.53 7.77 20.73C7.26 20.94 7 21.52 7.21 22.03C8.31 24.84 10.98 26.66 14 26.66C18.02 26.66 21.29 23.39 21.29 19.37C21.29 18.15 21.04 17.2 20.64 16.45H18Z" fill="black"/>
                <path d="M23.56 13.14H13.03C11.51 12.57 9.71999 11.25 9.71999 8.81C9.71999 6.37 11.64 4.53 14 4.53C15.72 4.53 17.27 5.55 17.94 7.14C18.16 7.65 18.75 7.89 19.25 7.67C19.76 7.46 20 6.87 19.78 6.36C18.8 4.03 16.53 2.53 14 2.53C10.53 2.53 7.71999 5.34 7.71999 8.81C7.71999 10.7 8.43999 12.12 9.42999 13.14H4.42999C3.87999 13.14 3.42999 13.58 3.42999 14.14C3.42999 14.39 3.51999 14.62 3.67999 14.8C3.85999 15.01 4.12999 15.14 4.42999 15.14H23.56C23.86 15.14 24.13 15.01 24.31 14.8C24.47 14.62 24.56 14.39 24.56 14.14C24.56 13.58 24.11 13.14 23.56 13.14Z" fill="black"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_520_539">
                <rect width="28" height="28" fill="white" transform="translate(0 0.610001)"/>
            </clipPath>
        </defs>    </svg>
)

export default IconStrikethrough;
