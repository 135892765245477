import {SvgProps} from "../index";

export const IconAddContactCircle: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.5125 23.0869C19.4958 23.0869 23.6003 18.9736 23.6003 13.999C23.6003 9.02435 19.4783 4.91107 14.5037 4.91107C9.88062 4.91107 6.01343 8.45306 5.49487 12.9443C6.03979 12.8212 6.7605 12.7861 7.34058 12.8828C7.85913 9.3847 10.8474 6.73041 14.5037 6.73041C18.5378 6.73041 21.7898 9.96478 21.7898 13.999C21.7898 15.9238 21.0515 17.664 19.8298 18.956C18.9246 17.954 17.0085 17.04 14.5037 17.04C13.7214 17.04 12.9919 17.1279 12.3328 17.2861C12.4382 17.7079 12.491 18.1474 12.491 18.5956C12.491 20.0283 11.9373 21.3554 11.0408 22.3837C12.113 22.832 13.2908 23.0869 14.5125 23.0869ZM14.5037 15.5986C16.2087 15.6074 17.5359 14.1572 17.5359 12.2763C17.5359 10.5009 16.2 9.01556 14.5037 9.01556C12.8162 9.01556 11.4626 10.5009 11.4802 12.2763C11.489 14.1572 12.7986 15.581 14.5037 15.5986ZM6.70776 23.1308C9.1687 23.1308 11.2341 21.0742 11.2341 18.5956C11.2341 16.1171 9.19507 14.0693 6.70776 14.0693C4.22925 14.0693 2.1814 16.1171 2.1814 18.5956C2.1814 21.0829 4.22925 23.1308 6.70776 23.1308ZM3.83374 18.5956C3.83374 18.2353 4.07983 17.998 4.44019 17.998H6.10132V16.3369C6.10132 15.9765 6.33862 15.7304 6.70776 15.7304C7.0769 15.7304 7.31421 15.9765 7.31421 16.3369V17.998H8.97534C9.33569 17.998 9.58179 18.2353 9.58179 18.5956C9.58179 18.9648 9.33569 19.2021 8.97534 19.2021H7.31421V20.872C7.31421 21.2324 7.0769 21.4785 6.70776 21.4785C6.33862 21.4785 6.10132 21.2324 6.10132 20.872V19.2021H4.44019C4.07983 19.2021 3.84253 18.9648 3.83374 18.5956Z"
            fill="#020202"
        />
    </svg>
)

export default IconAddContactCircle;