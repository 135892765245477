import {SvgProps} from "../index";

export const IconSettingsAccount: React.FC<SvgProps> = ({
                                                            width = 28,
                                                            height = 28,
                                                            ariaHidden,
                                                            title,
                                                            description
                                                        }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.1867 12.6817C14.07 12.67 13.93 12.67 13.8017 12.6817C12.4626 12.6362 11.1938 12.0713 10.2639 11.1066C9.33406 10.1419 8.8162 8.85319 8.82001 7.51333C8.82001 4.655 11.13 2.33333 14 2.33333C14.6795 2.32107 15.3547 2.44277 15.9872 2.69147C16.6196 2.94018 17.1969 3.31101 17.686 3.78281C18.1752 4.25461 18.5666 4.81813 18.8379 5.4412C19.1093 6.06427 19.2552 6.73468 19.2675 7.41416C19.2798 8.09365 19.1581 8.76889 18.9094 9.40134C18.6607 10.0338 18.2898 10.6111 17.818 11.1002C17.3462 11.5893 16.7827 11.9807 16.1596 12.2521C15.5366 12.5234 14.8662 12.6694 14.1867 12.6817ZM8.35334 16.9867C5.53001 18.8767 5.53001 21.9567 8.35334 23.835C11.5617 25.9817 16.8233 25.9817 20.0317 23.835C22.855 21.945 22.855 18.865 20.0317 16.9867C16.835 14.8517 11.5733 14.8517 8.35334 16.9867Z"
            stroke="#0D112B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default IconSettingsAccount;
