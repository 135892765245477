/* eslint-disable react/jsx-props-no-spreading */
import IconKAccept from "./IconKAccept";
import IconKDecline from "./IconKDecline";
import IconKDecline1 from "./IconKDecline1";

export type KeypadIconName =
    'kDecline'
    | 'kDecline1'
    | 'kAccept'
    ;

export interface SvgProps {
    width?: number;
    height?: number;
    ariaHidden?: boolean;
    title?: string;
    description?: string;
    onClick?: any;
}

interface IconProps extends SvgProps {
    name: KeypadIconName;
}

export const KeypadIcon: React.FC<IconProps> = (props) => {
    switch (props.name) {
        case 'kAccept':
            return <span onClick={props.onClick}><IconKAccept {...props} /></span>
        case 'kDecline':
            return <span onClick={props.onClick}><IconKDecline {...props} /></span>
        case 'kDecline1':
            return <span onClick={props.onClick}><IconKDecline1 {...props} /></span>

        default:
            return (
                <></>
            )
    }

}

export default KeypadIcon;
