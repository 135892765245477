/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {lazy, useRef, useState, Suspense} from "react";
import {ChatHeader} from "../ChatHeader/ChatHeader";
import ChatInputProvider from "../../../context/ChatInputContext/context";
import {isIntegration} from "../../../types";
import FilePreview from "../FilePreview";
import {useChatContext} from "../../../context/ChatContext/context";
import {useTypedSelector} from "../../../redux/hooks";
import ChatInput from "../ChatInput";
import {selectDragAndDropData} from "../../../redux/slices";
import {DragAndDrop} from "../../../components";
import LoadingSpinner from "../../../components/loading/LoadingSpinner";
import ChatBodySkeleton from "../../../components/Skeletons/ChatBodySkeleton";
import ChatSettings from "../ChatSettings";

const MessageDetails = lazy(() => import('../MessageDetails/MessageDetails'))
const ChatMessages = lazy(() => import('../ChatMessages'))

const ChatBody: React.FC = () => {
    const {chat, expandMessage} = useChatContext();

    const dadData = useTypedSelector(selectDragAndDropData);

    const uploadInputRef = useRef<HTMLInputElement>(null);

    const [getMessagesAfterDisabled] = useState(false);

    if (expandMessage) {
        return (
            <Suspense fallback={<LoadingSpinner color='mamba'/>}>
                <MessageDetails/>
            </Suspense>
        );
    }

    const fallback = (
        <div
            style={{
                maxHeight: '100%',
                overflow: 'hidden',
                position: 'relative',
                height: '100%',
            }}
        >
            <div
                style={{
                    height: 'fit-content',
                    position: 'absolute',
                    width: '100%',
                    bottom: 0,
                    padding: 12,
                }}
            >
            <ChatBodySkeleton/>
            </div>
        </div>
    )

    return (
        <DragAndDrop
            uploadInputRef={uploadInputRef}
            disabled={isIntegration(chat) || !!dadData}
        >
            <div className='chat'>
                <ChatHeader/>
                <ChatSettings/>
                <Suspense fallback={fallback}>
                    <ChatMessages/>
                </Suspense>
                <ChatInputProvider chat={chat} shouldReset={!getMessagesAfterDisabled}>
                    <ChatInput
                        uploadInput={uploadInputRef}
                    />
                </ChatInputProvider>
                <FilePreview/>
            </div>
        </DragAndDrop>
    );
};

export default ChatBody;
