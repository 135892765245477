import React, {FunctionComponent} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import Select, {DropdownIndicatorProps, StylesConfig} from "react-select";
import styles from './styles.module.scss';
import ariaStyles from '../AriaComponents/styles.module.scss';
import AriaLabel from "../AriaComponents/AriaLabel";
import Icon from "../Icon";

type Props = {
    name: string;
    label: string;
    required?: boolean;
    readOnly?: boolean;
    options: {
        label: string;
        value: string;
    }[];
};

export const CustomDropdownIndicator: React.FC<DropdownIndicatorProps> = () => (
    <div className={styles.dropDownIcon}>
        <Icon
            name='arrowDown'
            width={24}
            height={24}
        />
    </div>
);

export const selectDefaultStyles: StylesConfig = {
    option: (optionStyles, {isDisabled, isFocused, isSelected}: any) => ({
        ...optionStyles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isDisabled
            ? undefined
            // eslint-disable-next-line no-nested-ternary
            : isSelected
                ? 'var(--tertiary-color)'
                : isFocused
                    ? 'var(--secondary-background)'
                    : undefined,
        // eslint-disable-next-line no-nested-ternary
        color: isDisabled
            ? 'var(--secondary-font-color)'
            // eslint-disable-next-line no-nested-ternary
            : isSelected
                ? 'var(--font-color-white)'
                : 'var(--primary-font-color)',
        cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    placeholder: () => ({
        color: 'var(--secondary-font-color)',
        gridArea: '1/1/2/3',
        boxSizing: 'border-box',
    }),
    indicatorSeparator: () => ({
        width: 4,
        height: '100%',
    }),
};

const FormSelect: FunctionComponent<Props> = ({name, options, label, required, readOnly}) => {
    const {control} = useFormContext();

    return (
        <div className={styles.form_select_input}>
            <AriaLabel label={label}/>
            {required ? (
                <mark>*</mark>
            ) : null}
            <Controller
                name={name}
                control={control}
                render={({
                             field: {onChange, value},
                             fieldState: {invalid, isTouched, isDirty, error},
                         }) => (
                    <div
                        data-invalid={invalid || null}
                        data-is-touched={isTouched || null}
                        data-is-dirty={isDirty || null}
                        data-error={!!error || null}
                    >
                        <Select
                            unstyled
                            styles={selectDefaultStyles}
                            options={options}
                            value={value && options.find((c) => c.value === value)}
                            onChange={(val) => val && onChange((val as any).value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            isDisabled={readOnly}
                            components={{
                                DropdownIndicator: CustomDropdownIndicator,
                            }}
                            defaultValue={value && options.find(option => option.value === value)}
                        />
                        {error?.message ? (
                            <div
                                className={ariaStyles.errorMessage}
                                // {...errorMessageProps}
                            >
                                {error.message}
                            </div>
                        ) : null}
                    </div>
                )}
            />
        </div>
    );
};

export default FormSelect;
