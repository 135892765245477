import {SvgProps} from "../index";

export const IconPin: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
            <path fillRule="evenodd" clipRule="evenodd" d="M17.1544 4.32204C17.1541 4.32247 17.1539 4.32291 17.1536 4.32334L13.1265 10.5375C13.014 10.7111 12.8504 10.8455 12.6582 10.9223L8.74056 12.4871L15.5129 19.2594L17.0777 15.3418C17.1545 15.1496 17.2889 14.986 17.4625 14.8735L23.6796 10.8445L23.6827 10.8425C24.6235 10.2377 24.7717 8.90788 23.969 8.10525L19.8947 4.03096C19.0961 3.23237 17.7769 3.3706 17.1544 4.32204ZM15.479 3.22977C16.7884 1.22476 19.603 0.910776 21.309 2.61675L25.3832 6.69104C27.0851 8.39291 26.7811 11.2269 24.7657 12.5239C24.7652 12.5242 24.7647 12.5245 24.7642 12.5249L18.8171 16.379L16.8074 21.4104C16.6826 21.7226 16.4093 21.9513 16.08 22.019C15.7507 22.0867 15.4093 21.9843 15.1716 21.7466L6.25342 12.8284C6.01567 12.5907 5.91331 12.2493 5.981 11.92C6.04868 11.5906 6.27735 11.3174 6.58959 11.1926L11.621 9.18292L15.4771 3.23271L15.479 3.22977Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.1343 15.8658C12.5248 16.2563 12.5248 16.8895 12.1343 17.28L3.21611 26.1981C2.82559 26.5887 2.19242 26.5887 1.8019 26.1981C1.41137 25.8076 1.41137 25.1745 1.8019 24.7839L10.7201 15.8658C11.1106 15.4752 11.7438 15.4752 12.1343 15.8658Z" fill="black"/>
    </svg>
)

export default IconPin;
