import {SvgProps} from "../../index";

export const IconBLFCalling: React.FC<SvgProps> = ({
                                                  width = 10,
                                                  height = 10,
                                                  ariaHidden,
                                                  title,
                                                  description
                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 10"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M10.5264 5.17578C10.5264 7.93721 8.28779 10.1758 5.52637 10.1758C2.76494 10.1758 0.526367 7.93721 0.526367 5.17578C0.526367 2.41436 2.76494 0.175781 5.52637 0.175781C8.28779 0.175781 10.5264 2.41436 10.5264 5.17578Z"
            fill="#fbae35"/>
        <path
            d="M5.93234 2.61534C5.77135 2.77174 5.77595 3.03393 5.93234 3.19032L7.47789 4.74047C7.63428 4.89686 7.89648 4.89686 8.05287 4.74047C8.21386 4.58407 8.21386 4.32648 8.05287 4.16549L6.50272 2.61534C6.34633 2.45895 6.08414 2.45895 5.93234 2.61534ZM5.46776 5.20505C5.72075 5.44885 6.12094 5.44884 6.37393 5.20045C6.61772 4.95206 6.61772 4.54728 6.37393 4.29888C6.12094 4.05049 5.71615 4.05049 5.46776 4.29428C5.21477 4.54728 5.21477 4.94746 5.46776 5.20505ZM3.78422 4.75887C3.62782 4.91526 3.62782 5.17285 3.78422 5.32925L5.33436 6.8794C5.49536 7.04039 5.75295 7.04039 5.90934 6.8794C6.06574 6.723 6.06574 6.46541 5.90934 6.30902L5.13427 5.53394L4.3592 4.75887C4.2028 4.60247 3.94061 4.60247 3.78422 4.75887ZM3.32423 7.34398C3.57722 7.59237 3.97741 7.59237 4.2304 7.34398C4.47879 7.09099 4.47879 6.6862 4.2304 6.44241C3.97741 6.18942 3.57722 6.18942 3.32423 6.43781C3.07584 6.6862 3.07584 7.09099 3.32423 7.34398Z"
            fill="#FEFEFE"
        />
    </svg>
)

export default IconBLFCalling;
