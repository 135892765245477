import {SvgProps} from "../index";

export const IconHold: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M10.1232 21.5972H12.1886C13.0236 21.5972 13.4542 21.1666 13.4542 20.3316V8.23785C13.4542 7.38531 13.0236 6.98102 12.1886 6.97223H10.1232C9.28822 6.97223 8.85755 7.40289 8.85755 8.23785V20.3316C8.84876 21.1666 9.27943 21.5972 10.1232 21.5972ZM16.8029 21.5972H18.8595C19.6945 21.5972 20.1251 21.1666 20.1251 20.3316V8.23785C20.1251 7.38531 19.6945 6.97223 18.8595 6.97223H16.8029C15.9591 6.97223 15.5372 7.40289 15.5372 8.23785V20.3316C15.5372 21.1666 15.9591 21.5972 16.8029 21.5972Z" fill="#1C1C1E"/>
    </svg>
)

export default IconHold;
