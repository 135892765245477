import {SvgProps} from "../index";

export const IconArchive: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M9.66919 22.2267H19.3108C21.1301 22.2267 22.1233 21.2599 22.1233 19.4494V10.9416C22.9495 10.7219 23.4153 10.0187 23.4153 9.04315V7.76874C23.4153 6.57343 22.7209 5.79999 21.5168 5.79999H7.47192C6.31177 5.79999 5.5647 6.57343 5.5647 7.76874V9.04315C5.5647 10.0187 6.03052 10.7131 6.86548 10.9416V19.4494C6.86548 21.2599 7.85864 22.2267 9.66919 22.2267ZM7.80591 9.4035C7.40161 9.4035 7.22583 9.21893 7.22583 8.81464V7.99725C7.22583 7.59296 7.40161 7.40839 7.80591 7.40839H21.1917C21.5872 7.40839 21.7542 7.59296 21.7542 7.99725V8.81464C21.7542 9.21893 21.5872 9.4035 21.1917 9.4035H7.80591ZM9.75708 20.548C9.0188 20.548 8.6145 20.1525 8.6145 19.4055V11.0119H20.3655V19.4055C20.3655 20.1525 19.9612 20.548 19.2317 20.548H9.75708ZM11.6643 14.6066H17.3245C17.7463 14.6066 18.0452 14.299 18.0452 13.8683V13.5871C18.0452 13.1564 17.7463 12.8664 17.3245 12.8664H11.6643C11.2424 12.8664 10.9436 13.1564 10.9436 13.5871V13.8683C10.9436 14.299 11.2424 14.6066 11.6643 14.6066Z"
            fill="#020202"
        />
    </svg>
)

export default IconArchive;
