/* eslint-disable */
import defaultAxios, {AxiosResponse, ResponseType} from 'axios';
import axiosRetry from 'axios-retry';
import moment from 'moment';
import { isCallSwitch, isPulseHd} from '../helpers';
import {
    ApiGlobalSetting,
    ChatType,
    Department,
    IAccount,
    IAuthUser,
    ICallHandlingPutData,
    ICallRoute,
    IChat,
    IChatMessage,
    ICRMIntegration,
    IHuntGroup,
    IMailboxMenu,
    IParkingSlot,
    IPhonebook,
    IPhonebookContact,
    IPhoneNumber,
    ISendChatMessageResponse,
    IShortcode,
    ISound,
    ITelecommunicationsIdentificationDisplay,
    ITimeDiary,
    IUser,
    IVerification,
    IYeOldeCall
} from '../types';

export interface ILoginResponse {
    auth: IAuthUser;
    call_routes: ICallRoute[];
    caller_ids: null;
    hunt_groups: IHuntGroup[];
    numbers: IPhoneNumber[];
    parking_slots: null;
    phone_books: IPhonebook[];
    selectable_users: [];
    selectable_accounts: IAccount[];
    matched_accounts: IAccount[];
    short_codes: IShortcode[];
    integrations: ICRMIntegration[] | undefined;
    time_diaries: ITimeDiary[];
    users: IUser[];
    sounds: ISound[];
    mailboxes: IMailboxMenu[];
    departments: Department[];
}

// const baseUrl = window.location.pathname.split('/')[1] === 'x' ? '/x/' : '';

const axios = defaultAxios.create({
    baseURL: ''
});

type TypedAxiosResponse<T> = Promise<AxiosResponse<{ result: T }>>

axiosRetry(axios, {retries: 5, retryDelay: axiosRetry.exponentialDelay});

export const api = {
    getAppVersion: async () => {
        const response = await axios({
            method: 'GET',
            url: '/'
        })
        return response
    },
    postAllMessagesRead: async ({voip_user_uuid, type, chat_uuid}) => {
        await axios({
            method: 'PUT',
            url: '/api/chat-mark-all-read',
            params: {voip_user_uuid, type, chat_uuid}
        });
    },
    getIpCountryCode: async (): Promise<AxiosResponse<string>> => {
        const response = await axios.get('/get-ip-country-code');
        return response?.data === '' ? 'GB' : response?.data;
    },
    loginWithEmailAndPassword: async (
        email: string,
        password: string
    ): Promise<AxiosResponse<ILoginResponse>> => {
        const response = await axios({
            method: 'POST',
            url: '/login',
            data: {
                email_address: email,
                password,
                app_name: isPulseHd ? 'pulsehd' : isCallSwitch ? 'callswitchone' : 'yay'
            }
        });
        return response;
    },
    checkMeetingId: async ({meetingId}: { meetingId: string; }): Promise<AxiosResponse<any>> => {
        const response = await axios.post('/api/check-meeting-room',
            {
                custom_url: meetingId
            }
        );

        return response;
    },
    loginWithToken: async (): Promise<AxiosResponse<ILoginResponse>> => {
        const response = await axios({
            method: 'GET',
            url: '/login'
        });

        return response;
    },
    loginWithGoogle: async (tokenId: string): Promise<AxiosResponse<ILoginResponse>> => {
        const response = await axios({
            method: 'POST',
            url: '/login',
            data: {
                id_token: tokenId
            }
        });

        return response;
    },
    putCallHandling: async (
        userUuid: string,
        data: ICallHandlingPutData
    ): Promise<AxiosResponse<{ result: IUser }>> => {
        const response = await axios({
            method: 'PUT',
            url: `/api/put-call-handling?user_uuid=${userUuid}`,
            data
        });

        return response;
    },
    getIntegrationDetails: async (chat_uuid: string,): Promise<AxiosResponse<{
        result: {
            channel: number,
            name: string,
            number: string,
            uuid: string
        }
    }>> => {
        const response = await axios({
            method: 'GET',
            url: '/api/chat-get-name',
            params: {
                type: 'integration',
                chat_uuid,
            }
        });

        return response;
    },
    getPhonebookContacts: async (
        phonebook_uuid?: string,
        limit?: number,
        page?: number
    ): Promise<AxiosResponse<{
        result: IPhonebookContact[]
        total_count: number;
        total_pages: number;
    }>> => {
        const response = await axios({
            method: 'GET',
            url: '/api/get-phonebook-contacts',
            params: {
                phonebook_uuid,
                page: page || 1,
                limit
            }
        });

        return response;
    },
    getSingleContact: async (
        phonebook_uuid: string,
        contact_uuid: string,
    ): Promise<AxiosResponse<IPhonebookContact>> => {
        const response = await axios({
            method: 'GET',
            url: '/api/get-phonebook-single-contact',
            params: {
                phonebook_uuid,
                contact_uuid
            }
        });

        return response;
    },
    matchIntegrationNumber: async ({
                                       uriUser,
                                       integrationUuid
                                   }: {
        uriUser: string | undefined;
        integrationUuid: string;
    }): Promise<AxiosResponse<any>> => {
        const response = await axios({
            method: 'GET',
            url: `/api/match-integration-number?number=${uriUser}&integration_uuid=${integrationUuid}`
        });

        return response;
    },
    getCallHistory: async ({
                               username,
                               call_from,
                               call_to,
                               week,
                               page
                           }: {
        username: string;
        call_from?: boolean;
        call_to?: boolean;
        week: number;
        page: number;
    }): Promise<AxiosResponse<{ result: IYeOldeCall[], total_pages: number }>> => {
        const params: any = {
            sip_username: username,
            limit: 250,
            start: moment.utc().subtract((7 * week), 'days').format(),
            call_to: call_to ? username : null,
            call_from: call_from ? username : null,
            page,
        }

        if (week > 1) {
            params.end = moment.utc().subtract((7 * (week - 1)), 'days').format()
        }

        const response = await axios({
            method: 'GET',
            url: '/api/get-call-history',
            params
        });

        return response;
    },
    getCallHistoryFiller: async ({
                               username,
        startTimestamp
                           }: {
        username: string;
        startTimestamp: number;
    }): Promise<AxiosResponse<{ result: IYeOldeCall[], total_pages: number }>> => {

        const params = {
            sip_username: username,
            limit: 250,
            start: moment.unix(startTimestamp).utc().format(),
            call_to: null,
            call_from: username,
            page: 1,
        }

        const response = await axios({
            method: 'GET',
            url: '/api/get-call-history',
            params
        });

        return response;
    },
    sendMessageWithImage: async (message: IChatMessage): Promise<AxiosResponse<ISendChatMessageResponse>> => {
        const response = await axios({
            method: 'POST',
            url: '/api/send-chat-file',
            params: {voip_user_uuid: message.from},
            data: message
        });

        return response;
    },
    postChatMessagesRead: async ({
                                     voip_user_uuid,
                                     type,
                                     chat_uuid
                                 }: {
        voip_user_uuid: string,
        type: ChatType,
        chat_uuid: string
    }): Promise<AxiosResponse<{ result: null }>> => {
        const response = await axios({
            method: 'PUT',
            url: '/api/chat-mark-all-read',
            params: {voip_user_uuid, type, chat_uuid}
        });

        return response;
    },
    getCallerIds: async (
        uuid: string
    ): Promise<AxiosResponse<{ result: ITelecommunicationsIdentificationDisplay[] }>> => {
        const response = await axios({
            method: 'GET',
            url: '/api/get-caller-ids',
            params: {user_uuid: uuid}
        });

        return response;
    },
    changeCallerId: async (
        user_uuid: string,
        callerid_uuid: string | undefined
    ): Promise<AxiosResponse<{ result: null }>> => {
        const response = await axios({
            method: 'PUT',
            url: '/api/change-caller-id',
            params: {user_uuid},
            data: {
                caller_id: {
                    uuid: callerid_uuid
                }
            }
        });

        return response;
    },
    getParkingSlots: async (): Promise<AxiosResponse<{ result: IParkingSlot[] }>> => {
        const response = await axios({
            method: 'GET',
            url: '/api/get-parking-slots'
        });

        return response;
    },
    muteUser: async (voip_user_uuid: string, muted_user_uuid: string): Promise<AxiosResponse> => {
        const response = await axios({
            method: 'PUT',
            url: '/api/mute-user',
            params: {
                voip_user_uuid,
                muted_user_uuid
            }
        });

        return response;
    },
    unmuteUser: async (voip_user_uuid: string, muted_user_uuid: string): Promise<AxiosResponse> => {
        const response = await axios({
            method: 'PUT',
            url: '/api/unmute-user',
            params: {
                voip_user_uuid,
                muted_user_uuid
            }
        });

        return response;
    },
    // TODO BELOW
    muteChannel: async (voip_user_uuid: string, channel_uuid: string): Promise<AxiosResponse> => {
        const response = await axios({
            method: 'PUT',
            url: '/api/mute-channel',
            params: {
                voip_user_uuid,
                channel_uuid
            }
        });

        return response;
    },
    unmuteChannel: async (voip_user_uuid: string, channel_uuid: string): Promise<AxiosResponse> => {
        const response = await axios({
            method: 'PUT',
            url: '/api/unmute-channel',
            params: {
                voip_user_uuid,
                channel_uuid
            }
        });

        return response;
    },

    // // deleteChannel: async (voip_user_uuid: string, channel_uuid: string): Promise<AxiosResponse> => {
    //     const response = await axios({
    //         method: 'DELETE',
    //         url: '/api/delete-channel',
    //         params: {
    //             voip_user_uuid,
    //             channel_uuid
    //         }
    //     });
    //
    //     return response;
    // },

    // removeChannelUser: async (
    //     voip_user_uuid: string,
    //     channel_uuid: string,
    //     removed_user_uuid: string
    // ): Promise<AxiosResponse> => {
    //     const response = await axios({
    //         method: 'DELETE',
    //         url: '/api/remove-channel-user',
    //         params: {
    //             voip_user_uuid,
    //             channel_uuid,
    //             removed_user_uuid
    //         }
    //     });
    //
    //     return response;
    // },

    // leaveChannel: async (voip_user_uuid: string, channel_uuid: string): Promise<AxiosResponse> => {
    //     const response = await axios({
    //         method: 'DELETE',
    //         url: '/api/leave-channel',
    //         params: {
    //             voip_user_uuid,
    //             channel_uuid
    //         }
    //     });
    //
    //     return response;
    // },
    getFileContent: async ({
                               userUuid,
                               fileUuid,
                               responseType
                           }: {
        userUuid: string;
        fileUuid: string;
        responseType?: ResponseType;
    }): Promise<AxiosResponse<string>> => {
        const response = await axios({
            method: 'GET',
            url: `/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${fileUuid}&content=true&thumbnail=false`,
            responseType
        });

        return response;
    },
    postDeliveredMessageById: async (
        voip_user_uuid: string,
        message_uuid: string
    ): Promise<AxiosResponse<{ result: null }>> => {
        const response = await axios({
            method: 'PUT',
            url: '/api/chat-message-received',
            params: {voip_user_uuid, message_uuid}
        });

        return response;
    },
    postReadMessageById: async (
        voip_user_uuid: string,
        message_uuid: string
    ): Promise<AxiosResponse<{ result: null }>> => {
        const response = await axios({
            method: 'PUT',
            url: '/api/chat-message-read',
            params: {voip_user_uuid, message_uuid}
        });

        return response;
    },
    getChatMessagesUntil: async ({
                                     voip_user_uuid,
                                     type,
                                     chat_uuid,
                                     until_message_uuid
                                 }: {
        voip_user_uuid: string,
        type: string,
        chat_uuid: string,
        until_message_uuid: string
    }): Promise<AxiosResponse<{ result: any }>> => {
        const response = await axios({
            method: 'GET',
            url: '/api/get-chat-messages-until',
            params: {
                voip_user_uuid,
                type,
                uuid: chat_uuid,
                until_uuid: until_message_uuid
            }
        });

        return response;
    },
    getChatMessagesById: async ({
                                    uuid,
                                    type,
                                    chatId,
                                    offset,
                                    limit
                                }: {
        uuid: string;
        type: ChatType;
        chatId: string;
        offset?: number;
        limit?: number;
    }): Promise<AxiosResponse<{ result: IChat }>> => {
        const response = await axios({
            method: 'GET',
            url: '/api/get-chat-messages',
            params: {
                voip_user_uuid: uuid,
                type,
                uuid: chatId,
                limit,
                offset,
                newest_first: 'yes',
                filter_by_updated: 'no',
                // TODO - waiting on backend for this
                // include_deleted: 'yes',
            }
        });

        return response;
    },
    getChatMessageByIdAPI: async ({
                                      voip_user_uuid,
                                      message_uuid,
                                  }: any): Promise<AxiosResponse<{ result: IChatMessage }>> => {
        const response = await axios({
            method: 'GET',
            url: '/api/get-chat-message',
            params: {voip_user_uuid, message_uuid}
        });

        return response;
    },
    getChatById: async ({
                            voip_user_uuid,
                            channel_uuid
                        }: {
        voip_user_uuid: string;
        channel_uuid: string;
    }): Promise<AxiosResponse> => {
        const response = await axios({
            method: 'GET',
            url: '/api/get-chat',
            params: {
                voip_user_uuid,
                channel_uuid
            }
        });

        return response;
    },
    getChatFile: async ({
                            userUuid,
                            fileUuid
                        }: {
        userUuid: string;
        fileUuid: string;
    }): Promise<AxiosResponse> => {
        const response = await axios({
            method: 'GET',
            url: `/api/get-chat-file?voip_user_uuid=${userUuid}&file_uuid=${fileUuid}&content=true&thumbnail=false`,
            responseType: 'arraybuffer'
        });

        return response;
    },
    deleteDevice: async ({
                             userUuid,
                             deviceUuid
                         }: {
        userUuid: string;
        deviceUuid: string;
    }): Promise<AxiosResponse<any>> => {
        const response = await axios({
            method: 'DELETE',
            url: `/api/delete-device?voip_user_uuid=${userUuid}&device_uuid=${deviceUuid}`,
        });

        return response
    },
    resendVerification: async ({
                                   userUuid,
                                   isResend,
                                   isEmail
                               }: {
        userUuid: string;
        isResend: boolean;
        isEmail: boolean;
    }): Promise<AxiosResponse<any>> => {
        const response = await axios.post(
            `/api/send-user-verification-code?user_uuid=${userUuid}&resend=${
                isResend ? 'true' : 'false'
            }`,
            {
                verification_type: isEmail ? 'EMAIL' : 'MOBILE'
            }
        );

        return response;
    },
    checkUserVerification: async (
        userUuid: string
    ): Promise<AxiosResponse<{
        result: IVerification[];
    }>> => {
        const response = await axios.get(`/api/check-user-verification?user_uuid=${userUuid}`);

        return response;
    },
    setUpVerification: async ({
                                  userUuid,
                                  data
                              }: {
        userUuid: string;
        data: {
            verification_code: string;
            verification_type: string;
        };
    }): Promise<AxiosResponse<any>> =>
        // eslint-disable-next-line no-return-await
        await axios.put(`/api/submit-user-verification-code?user_uuid=${userUuid}`, data),
    moveCallToFollow: async ({
                                 callSipId,
                                 target
                             }: {
        callSipId: string;
        target?: string
    }): Promise<AxiosResponse<any>> => {

        const response = await axios.put(`/api/put-follow-me-call?call_uuid=${callSipId}`, {target: target || ''})
        return response;
    },
    getSingleVoicemail: async ({
                                   mailboxUuid,
                                   messageUuid
                               }: {
        mailboxUuid: string;
        messageUuid: string
    }): Promise<AxiosResponse<any>> => {

        const response = await axios.get(`/api/get-single-voicemail?mailboxUuid=${mailboxUuid}&messageUuid=${messageUuid}`)
        return response;
    },
    putUserGlobalDnd: async ({
                                 userUuid,
                                 dnd,
                             }: {
        userUuid: string;
        dnd: boolean;
    }): Promise<AxiosResponse<any>> => {

        const response = await axios.put(`/api/put-sip-user-global-dnd?voip_user_uuid=${userUuid}`, {dnd})
        return response;
    },
    getGlobalSettings: async (userUuid: string): TypedAxiosResponse<ApiGlobalSetting[]> => {
        return await axios.get(`/api/get-sip-user-settings?voip_user_uuid=${userUuid}`);
    },
    getFriend: async ({
                          voip_user_uuid,
                          friend_uuid,
                      }: {
        voip_user_uuid: string,
        friend_uuid: string;
    }): Promise<AxiosResponse<{ result: unknown }>> => {

        const response = await axios({
            method: 'GET',
            url: '/api/anonymous-chatter',
            params: {voip_user_uuid, friend_uuid}
        });

        return response;
    },
};
