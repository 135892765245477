/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-classes-per-file */
import * as Sentry from '@sentry/react';
import { EventEmitter } from 'events';
import { detect } from 'detect-browser';
import store, {RootState} from "../redux/store";
import {Ringtone} from "./ringtone";

const browser = detect();

const audioElement = document.createElement('AUDIO') as HTMLAudioElement;
const audioContext = ['chrome', 'edge-chromium'].includes(browser?.name || '')
    ? new AudioContext()
    : null;

let oscPrimary: OscillatorNode;
let oscSecondary: OscillatorNode;
let gainNode: GainNode;
let destination: MediaStreamAudioDestinationNode;
let playEnabled = false;
let compressor: DynamicsCompressorNode;

const initAudio = () => {
    if (audioContext) {
        oscPrimary = audioContext.createOscillator();
        oscSecondary = audioContext.createOscillator();
        gainNode = audioContext.createGain();
        destination = audioContext.createMediaStreamDestination();
        compressor = audioContext.createDynamicsCompressor();

        gainNode.gain.value = 0;

        oscPrimary.connect(gainNode).connect(compressor);
        oscSecondary.connect(gainNode).connect(compressor);
        gainNode.connect(destination);

        audioElement.srcObject = destination.stream;
        audioElement.autoplay = true;

        oscPrimary.start();
        oscSecondary.start();
    }
};

initAudio();

export class Tone extends EventEmitter {
    private osc1: OscillatorNode = oscPrimary;

    private osc2: OscillatorNode = oscSecondary;

    private wait?: number;

    private duration?: number;

    private outputDeviceId?: string;

    private volume?: number;

    constructor({
                    freq1,
                    freq2,
                    wait,
                    duration,
                    outputDeviceId,
                    volume
                }: {
        freq1: number;
        freq2: number;
        wait?: number;
        duration?: number;
        outputDeviceId?: string;
        volume?: number;
    }) {
        super();
        this.wait = wait;
        this.duration = duration;
        this.osc1.frequency.value = freq1;
        this.osc2.frequency.value = freq2;
        this.volume = volume;
        this.outputDeviceId =
            outputDeviceId ||
            (store.getState() as RootState)?.user?.settings?.phone.settings?.outputDeviceIdCall;

        audioElement.autoplay = true;

        if (!playEnabled) {
            audioElement
                .play()
                .then(() => {
                    playEnabled = true;
                })
                .catch((error: Error) => {
                    console.warn(error.message);
                    playEnabled = false;
                });
        }
        // @ts-ignore
        if (audioElement.sinkId !== this.outputDeviceId) {
            // prettier-ignore
            // @ts-ignore
            audioElement.setSinkId?.(this.outputDeviceId || 'default').catch(() => console.warn('error with setSinkId in dtmf'));
        }
    }

    play = () => {
        this.emit('playing');

        if (audioContext?.state === 'suspended') {
            audioContext.resume();
        }

        setTimeout(() => {
            if (audioContext) {
                const now = audioContext.currentTime;
                gainNode.gain.cancelScheduledValues(now);
                gainNode.gain.setValueAtTime(gainNode.gain.value, now);
                gainNode.gain.linearRampToValueAtTime(0.1, audioContext.currentTime + 0.01);

                try {
                    audioElement.volume =
                        (typeof this.volume === 'number' ? this.volume : 100) / 100;
                } catch (error) {
                    Sentry.captureMessage('Error setting volume', {
                        extra: {
                            volume: this.volume,
                            error
                        },
                        tags: {
                            info: 'Custom Log'
                        }
                    });
                }

                setTimeout(() => {
                    this.stop();
                }, this.duration || 150);
            }
        }, this.wait || 0);
    };

    stop = () => {
        this.emit('stopped');

        if (audioContext) {
            const now = audioContext.currentTime;
            gainNode.gain.cancelScheduledValues(now);
            gainNode.gain.setValueAtTime(gainNode.gain.value, now);
            gainNode.gain.linearRampToValueAtTime(0.0, audioContext.currentTime + 0.01);
        }
    };
}

export class CallWaitingBeep {
    private beepTone?: Tone;

    private beepTimeout?: NodeJS.Timeout;

    private outputDeviceId?: string;

    constructor({ outputDeviceId }: { outputDeviceId?: string }) {
        this.outputDeviceId = outputDeviceId;
    }

    play = () => {
        this.beepTone = new Tone({
            duration: 125,
            freq1: 400,
            freq2: 400,
            outputDeviceId: this.outputDeviceId
        });
        this.beepTone.play();

        this.beepTimeout = setTimeout(() => {
            this.beepTone = new Tone({
                duration: 125,
                freq1: 400,
                freq2: 400,
                outputDeviceId: this.outputDeviceId
            });

            this.beepTone.play();
        }, 1000);
    };

    destroy = () => {
        if (this.beepTimeout) {
            clearTimeout(this.beepTimeout);
        }
        this.beepTone?.stop();
    };
}

export const playTone = ({ key, outputDeviceId }: { key?: string; outputDeviceId?: string }) => {
    if (!key) return;

    const dtmfFrequencies: { [key: string]: { f1: number; f2: number } } = {
        '1': { f1: 697, f2: 1209 },
        '2': { f1: 697, f2: 1336 },
        '3': { f1: 697, f2: 1477 },
        '4': { f1: 770, f2: 1209 },
        '5': { f1: 770, f2: 1336 },
        '6': { f1: 770, f2: 1477 },
        '7': { f1: 852, f2: 1209 },
        '8': { f1: 852, f2: 1336 },
        '9': { f1: 852, f2: 1477 },
        '*': { f1: 941, f2: 1209 },
        '0': { f1: 941, f2: 1336 },
        '#': { f1: 941, f2: 1477 }
    };

    const tone = new Tone({
        freq1: dtmfFrequencies[key].f1,
        freq2: dtmfFrequencies[key].f2,
        outputDeviceId:
            outputDeviceId ||
            (store.getState() as RootState)?.user?.settings?.phone.settings?.outputDeviceIdCall
    });

    tone.play();
};

let inboundRingtone: { [key: string]: Ringtone } = {};
let outboundRingtone: { [key: string]: Ringtone } = {};
let callWaiting: CallWaitingBeep;

export const playRingtone = ({
                                 callId,
                                 outputDeviceId,
                                 isOutbound,
                                 volume
                             }: {
    callId: string;
    outputDeviceId?: string;
    isOutbound?: boolean;
    volume?: number;
}) => {
    if (isOutbound) {
        outboundRingtone = {
            ...outboundRingtone,
            [callId]: new Ringtone({
                outbound: true,
                outputDeviceId:
                    outputDeviceId ||
                    (store.getState() as RootState)?.user?.settings?.phone.settings
                        ?.outputDeviceIdCall,
                volume
            })
        };

        outboundRingtone[callId].startRinging();
    } else {
        inboundRingtone = {
            ...inboundRingtone,
            [callId]: new Ringtone({
                outbound: false,
                outputDeviceId:
                    outputDeviceId ||
                    (store.getState() as RootState)?.user?.settings?.phone.settings
                        ?.outputDeviceIdRingtone,
                volume
            })
        };

        inboundRingtone[callId].startRinging();
    }
};

export const playCallWaiting = ({
                                    outputDeviceId
                                }: {
    outputDeviceId?: string;
} = {}) => {
    callWaiting = new CallWaitingBeep({
        outputDeviceId:
            outputDeviceId ||
            (store.getState() as RootState)?.user?.settings?.phone.settings?.outputDeviceIdCall
    });
    callWaiting.play();
};

export const stopRingtone = (callId: string) => {
    outboundRingtone[callId]?.stopRinging();
    delete outboundRingtone[callId];

    inboundRingtone[callId]?.stopRinging();
    delete inboundRingtone[callId];

    callWaiting?.destroy();
};

