import {SvgProps} from "../index";

export const IconDeviceDesktop: React.FC<SvgProps> = ({
                                                  width = 28,
                                                  height = 28,
                                                  ariaHidden,
                                                  title,
                                                  description
                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M3.41797 16.9941C3.41797 18.6289 4.25293 19.4375 5.85254 19.4375H11.2227L10.9854 21.0283H10.0537C9.61426 21.0283 9.25391 21.3975 9.25391 21.8369C9.25391 22.2676 9.61426 22.6367 10.0537 22.6367H18.0078C18.4561 22.6367 18.8164 22.2676 18.8164 21.8369C18.8164 21.3975 18.4561 21.0283 18.0078 21.0283H17.0059L16.7773 19.4375H22.1387C23.7383 19.4375 24.582 18.6289 24.582 16.9941V7.10645C24.582 5.48047 23.7383 4.67188 22.1387 4.67188H5.85254C4.25293 4.67188 3.41797 5.48047 3.41797 7.10645V16.9941ZM5.13184 16.8887V7.2207C5.13184 6.61426 5.38672 6.38574 5.9668 6.38574H22.0244C22.6045 6.38574 22.8682 6.61426 22.8682 7.2207V16.8887C22.8682 17.4863 22.6045 17.7236 22.0244 17.7236H5.9668C5.38672 17.7236 5.13184 17.4863 5.13184 16.8887Z"
            fill="black"
        />
    </svg>
)

export default IconDeviceDesktop;