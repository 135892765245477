import React, {Fragment, ReactNode, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion';
import styles from './styles.module.scss';
import AriaButton from "../AriaComponents/AriaButton";
import {ButtonSize, ButtonStyle, StyledButton} from "./StyledButton";
import Icon from "../Icon";
import { PlaceAboveProps, useOnClickOutside } from '../../helpers';
import usePlaceOver from "../../helpers/placeAbove";
import LoadingSpinner from "../loading/LoadingSpinner";


/**
 * If using a custom component to render list options.
 * Selected and onSelected logic should be handled
 * by the parent component
 */
type Props<T> = OptionProps<T> & CustomOpenProps & SharedProps;

type CustomOpenProps = {
    open: boolean;
    setOpen: (val: boolean) => void;
} | {
    open?: never;
    setOpen?: never;
}

type OptionProps<T> = {
    options: StyledDropdownOption[];
    onSelect?: (val: string) => void;
    selected?: string | string[];
    component?: never;
} | {
    options: T[];
    onSelect?: never;
    selected?: never;
    component: React.FC<T>;
} | {
    options: {
        label: string;
        value: T;
    }[];
    onSelect: (val: T) => void;
    selected?: never;
    component?: never;
}

interface SharedProps {
    placeHolder?: string;
    direction?: 'right'
    display?: ReactNode;
    loading?: boolean;
    iconOnly?: boolean;
    noDownArrow?: boolean;
    buttonStyle?: ButtonStyle;
    iconSize?: ButtonSize;
    children?: ReactNode;
    className?: string;
    placeAboveProps?: Pick<PlaceAboveProps, 'xPos' | 'yPos' | 'offset' | 'flipped'>;
    buttonTitle?: string;
    buttonOverride?: ReactNode;
    disabled?: boolean
}

export type StyledDropdownOption = ({
    value: string;
    onSelect?: never;
    divider?: never;
} | {
    value?: never;
    onSelect: () => void | undefined;
    divider?: never
} | {
    value?: never;
    onSelect?: never;
    divider?: boolean
}) & SharedOptionProps;

export interface SharedOptionProps {
    label: string | ReactNode;
    large?: boolean;
    disabled?: boolean | string;
    context?: 'danger';
    selected?: boolean;
}


const StyledDropdown = <T, >({
                                 options,
                                 onSelect,
                                 selected,
                                 placeHolder,
                                 component,
                                 iconOnly,
                                 direction,
                                 display,
                                 loading,
                                 iconSize,
                                 children,
                                 className,
                                 placeAboveProps,
                                 noDownArrow,
                                 buttonStyle,
                                 open,
                                 setOpen,
                                 buttonTitle,
                                 buttonOverride,
                                 disabled
                             }: Props<T>) => {
    const [openLocal, setOpenLocal] = useState<boolean>(false);

    const dropdownOpen = open ?? openLocal;
    const setDropdownOpen = setOpen ?? setOpenLocal;

    const dropDownRef = useRef<HTMLUListElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(dropDownRef, () => setDropdownOpen(false))

    usePlaceOver({
        relativeElement: containerRef,
        fixedElement: dropDownRef,
        xPos: 'right',
        yPos: 'bottom',
        display: dropdownOpen,
        flipped: true,
        ...placeAboveProps
    })

    return (
        <div
            className={[styles.styled_dropdown, className || ''].join(' ')}
            ref={containerRef}
            onBlur={(e) => {
                if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget)) {
                    setDropdownOpen(false)
                }
            }}
            data-loading={loading || null}
        >
            {iconOnly && !buttonOverride ? (
                <StyledButton
                    buttonStyle='text'
                    size={iconSize || 'small'}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    iconOnly={iconOnly}
                    title={buttonTitle}
                    disabled={disabled}
                >
                    <Icon name='threeDots'/>
                </StyledButton>) : null}
            {!iconOnly && !buttonOverride ? (
                <StyledButton
                    buttonStyle={buttonStyle || 'tertiary'}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    size={iconSize}
                    title={buttonTitle}
                    disabled={disabled}
                >
                    {loading ? (
                        <LoadingSpinner color='mamba'/>
                    ) : display || (
                        <p>
                            {
                                (
                                    typeof selected === 'string'
                                    && selected
                                    && options && options.find(opt => opt.value === selected)?.label
                                )
                                || placeHolder
                                || 'Select'
                            }
                        </p>
                    )}
                    {!noDownArrow ? (
                        <Icon name={dropdownOpen ? 'arrowUp' : 'arrowDown'} width={20} height={20}/>
                    ) : null}
                </StyledButton>
            ) : null}
            {buttonOverride}
            <AnimatePresence>
                {dropdownOpen ? (
                    <motion.ul
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{
                            opacity: 0
                        }}
                        ref={dropDownRef}
                        data-menu-direction={direction || null}
                        className={styles.styled_list}
                    >
                        {options.map((opt, idx) => {

                            if (component) {
                                return (
                                    <Fragment key={opt.value || idx}>
                                        {component(opt, () => setDropdownOpen(false))}
                                    </Fragment>
                                )
                            }

                            let isSelected;

                            if (typeof selected === 'string' && selected === opt.value) {
                                isSelected = true
                            }

                            if (Array.isArray(selected) && selected.includes(opt.value)) {
                                isSelected = true
                            }

                            if (opt.selected) {
                                isSelected = true;
                            }

                            const title = typeof opt.label === 'string' ? opt.label : '';

                            if (opt.divider) {
                                return (
                                    <p key={`l-${opt.label}-${idx}`}>
                                        {opt.label}
                                    </p>
                                )
                            }

                            return (
                                <li
                                    key={opt.value || idx}
                                    title={
                                        opt.disabled && typeof opt.disabled === 'string'
                                            ? opt.disabled
                                            : undefined
                                    }
                                >
                                    <AriaButton
                                        dataTags={{
                                            'data-selected': isSelected || null,
                                            'data-is-large': opt.large || null,
                                            'data-is-danger':
                                                opt.danger || opt.context === 'danger' || null
                                        }}
                                        onClick={e => {
                                            if (opt.onSelect) {
                                                opt.onSelect();
                                            } else if (onSelect) {
                                                onSelect(opt.value);
                                            }
                                            if (!e.shiftKey) {
                                                setDropdownOpen(false);
                                            }
                                        }}
                                        title={`Select ${title}`}
                                        disabled={opt.disabled}
                                    >
                                        {typeof opt.label === 'string' ? (
                                            <div>
                                                <p>{opt.label}</p>
                                            </div>
                                        ) : (
                                            opt.label
                                        )}

                                        {isSelected ? <Icon name='tick' /> : null}
                                    </AriaButton>
                                </li>
                            );
                        })}
                        {children}
                    </motion.ul>
                ) : null}
            </AnimatePresence>
        </div>
    );
};

export default StyledDropdown;
