import {SvgProps} from "../index";

export const IconContactUnfilled: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.4792 23.0876C19.4626 23.0876 23.5759 18.9743 23.5759 13.9997C23.5759 9.02509 19.4539 4.9118 14.4705 4.9118C9.49585 4.9118 5.39136 9.02509 5.39136 13.9997C5.39136 18.9743 9.50464 23.0876 14.4792 23.0876ZM14.4792 17.0407C12.0007 17.0407 10.0759 17.9284 9.14429 18.9479C7.94897 17.6559 7.21948 15.9157 7.21948 13.9997C7.21948 9.96552 10.4451 6.73114 14.4705 6.73114C18.5046 6.73114 21.7478 9.96552 21.7566 13.9997C21.7566 15.9157 21.0271 17.6559 19.823 18.9567C18.8914 17.9284 16.9666 17.0407 14.4792 17.0407ZM14.4792 15.5993C16.1843 15.6169 17.5115 14.1579 17.5115 12.277C17.5115 10.5016 16.1755 9.0163 14.4792 9.0163C12.7917 9.0163 11.447 10.5016 11.4558 12.277C11.4646 14.1579 12.783 15.5905 14.4792 15.5993Z"
            fill="black"
        />
</svg>
)

export default IconContactUnfilled;
