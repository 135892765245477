import {SvgProps} from "../index";

export const IconPhoneCall: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M10.7502 18.0331C13.343 20.6347 16.4983 22.6386 19.0735 22.6386C20.2249 22.6386 21.2356 22.2343 22.0442 21.3378C22.5188 20.8105 22.8088 20.204 22.8088 19.5976C22.8088 19.1318 22.6243 18.6747 22.176 18.3583L19.4778 16.4423C19.0471 16.1435 18.6956 16.0029 18.3792 16.0029C17.9661 16.0029 17.5969 16.2314 17.1926 16.6357L16.5598 17.2685C16.4543 17.3652 16.3313 17.4091 16.217 17.4091C16.0764 17.4091 15.9446 17.3564 15.8567 17.3036C15.303 17.0048 14.3538 16.1962 13.4749 15.3173C12.5959 14.4384 11.7786 13.498 11.4885 12.9355C11.4358 12.8388 11.3918 12.7158 11.3918 12.5751C11.3918 12.4697 11.427 12.3466 11.5237 12.2411L12.1565 11.5995C12.552 11.1865 12.7893 10.8261 12.7893 10.413C12.7893 10.0878 12.6487 9.73627 12.3499 9.31439L10.4426 6.64252C10.1174 6.18549 9.6604 5.99213 9.15942 5.99213C8.56177 5.99213 7.96411 6.2558 7.44556 6.75677C6.56665 7.58295 6.17993 8.61127 6.17993 9.74506C6.17993 12.3203 8.15747 15.4404 10.7502 18.0331Z"
            fill="#020202"
        />
    </svg>
)

export default IconPhoneCall;