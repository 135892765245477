import {SvgProps} from "../index";

export const IconLink: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="-2 -2 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.30847 12C2.30847 8.72499 4.97631 6.05715 8.2513 6.05715H9.66753C10.0817 6.05715 10.4175 6.39293 10.4175 6.80715C10.4175 7.22136 10.0817 7.55715 9.66753 7.55715H8.2513C5.80474 7.55715 3.80847 9.55341 3.80847 12C3.80847 14.438 5.80562 16.4428 8.2513 16.4428H9.66753C10.0817 16.4428 10.4175 16.7786 10.4175 17.1928C10.4175 17.607 10.0817 17.9428 9.66753 17.9428H8.2513C4.97542 17.9428 2.30847 15.2646 2.30847 12ZM14.5731 6.80715C14.5731 6.39293 14.9089 6.05715 15.3231 6.05715H16.7488C20.0246 6.05715 22.6916 8.73532 22.6916 12C22.6916 15.2759 20.0134 17.9428 16.7488 17.9428H15.3231C14.9089 17.9428 14.5731 17.607 14.5731 17.1928C14.5731 16.7786 14.9089 16.4428 15.3231 16.4428H16.7488C19.1868 16.4428 21.1916 14.4457 21.1916 12C21.1916 9.56197 19.1944 7.55715 16.7488 7.55715H15.3231C14.9089 7.55715 14.5731 7.22136 14.5731 6.80715ZM7.97338 12C7.97338 11.5858 8.30916 11.25 8.72338 11.25H16.2766C16.6908 11.25 17.0266 11.5858 17.0266 12C17.0266 12.4142 16.6908 12.75 16.2766 12.75H8.72338C8.30916 12.75 7.97338 12.4142 7.97338 12Z"
              fill="#0D112B"/>
    </svg>
)

export default IconLink;
