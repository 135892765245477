import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {HardwarePhone, IHuntGroup, ILoggedInUser, IPhonebookContact, IShortcode, IUser} from "../../../types";
import {authApi} from "../../services/authApi";

const initialState: ProvisionState = {
    lineKeys: [],
    apiLineKeys: [],
    changedPositions: [],
    hardwarePhones: [],
    selectedHardwarePhone: '',
};

interface ProvisionState {
    lineKeys: LineKey[];
    apiLineKeys: LineKey[];
    changedPositions: number[],
    provisionItem?: ProvisionItem;
    hardwarePhones: HardwarePhone[];
    selectedHardwarePhone: string;
}

export type ProvisionItem = IUser | IPhonebookContact | IHuntGroup | IShortcode | undefined;

export interface LineKey {
    position: number;
    function: LineKeyFunctionType;
    value: string;
    label: string;
}

export type LineKeyFunctionType = 'blf' | 'speedDial' | undefined;

export const provisionSlice = createSlice({
    name: 'provision',
    initialState,
    reducers: {
        setApiKeys: (state, {payload}: PayloadAction<LineKey[]>) => ({
            ...state,
            apiLineKeys: payload
        }),
        setLineKeys: (state, {payload}: PayloadAction<LineKey[]>) => ({
            ...state,
            lineKeys: payload
        }),
        resetLineKeys: (state) => ({
            ...state,
            lineKeys: state.apiLineKeys,
            changedPositions: [],
        }),
        setAllLineKeys: (state, {payload}: PayloadAction<LineKey[]>) => ({
            ...state,
            lineKeys: payload,
            apiLineKeys: payload,
        }),
        updateOneLineKey: (state, {payload}: PayloadAction<LineKey>) => ({
                ...state,
                lineKeys: state.lineKeys.some(stateLineKey => stateLineKey.position === payload.position) ?
                    state.lineKeys.map(stateLineKey => {
                        if (stateLineKey.position === payload.position) {
                            return payload;
                        }
                        return stateLineKey
                    })
                    : [...state.lineKeys, payload]
            }
        ),
        deleteOneLineKey: (state, {payload}: PayloadAction<number>) => ({
            ...state,
            lineKeys: state.lineKeys.filter(stateLineKey => stateLineKey.position !== payload)
        }),
        addOnePositionChanged: (state, {payload}: PayloadAction<number>) => ({
            ...state,
            changedPositions: [...state.changedPositions, payload],
        }),
        removeOnePositionChanged: (state, {payload}: PayloadAction<number>) => ({
            ...state,
            changedPositions: state.changedPositions.filter(statePosition => statePosition !== payload),
        }),
        clearPositionChanged: (state) => ({
            ...state,
            changedPositions: []
        }),
        setProvisionItem: (state, {payload}: PayloadAction<ProvisionItem>) => ({
            ...state,
            provisionItem: payload
        }),
        setSelectedHardwarePhone: (state, {payload}: PayloadAction<string>) => ({
            ...state,
            selectedHardwarePhone: payload
        }),
    },
    extraReducers: builder => {
        builder.addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, {payload}): ProvisionState => ({
            ...state,
            hardwarePhones: (payload.user as ILoggedInUser)?.hardware_phones || [],
            selectedHardwarePhone: (payload.user as ILoggedInUser)?.hardware_phones?.[0]?.mac_address || '',
        }))
    },
});

export const provision = provisionSlice.reducer;