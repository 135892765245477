import {SvgProps} from "../../index";

export const IconBLFAvailable: React.FC<SvgProps> = ({
                                                         width = 10,
                                                         height = 10,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 11"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M10.5264 5.82422C10.5264 8.58564 8.28779 10.8242 5.52637 10.8242C2.76494 10.8242 0.526367 8.58564 0.526367 5.82422C0.526367 3.06279 2.76494 0.824219 5.52637 0.824219C8.28779 0.824219 10.5264 3.06279 10.5264 5.82422Z"
            fill="#80CD42"
        />
        <path
            d="M4.76496 8.34254L2.54126 6.0042L3.60483 4.88943L4.76496 6.112L7.4479 3.30518L8.51147 4.4134L4.76496 8.34254Z"
            fill="#FEFEFE"
        />
    </svg>
)

export default IconBLFAvailable;