import {SvgProps} from "../index";

export const IconPaperplane: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M15.3359 23.4102C16.0215 23.4102 16.4961 22.8564 16.8213 22.0127L22.6221 6.83398C22.7715 6.44727 22.8594 6.10449 22.8594 5.80566C22.8594 5.18164 22.4639 4.78613 21.8311 4.78613C21.541 4.78613 21.1895 4.86523 20.8027 5.01465L5.5625 10.8506C4.80664 11.1406 4.23535 11.6152 4.23535 12.3008C4.23535 13.1357 4.85938 13.4521 5.7207 13.7158L10.2734 15.1045C10.8887 15.2979 11.249 15.2891 11.6797 14.8848L21.4707 5.81445C21.5938 5.7002 21.7432 5.71777 21.8398 5.80566C21.9365 5.89355 21.9365 6.05176 21.8311 6.16602L12.7783 15.9746C12.4092 16.3877 12.374 16.7744 12.5586 17.3896L13.9121 21.8545C14.1758 22.751 14.4922 23.4102 15.3359 23.4102Z"
            fill="black"
        />
    </svg>
)

export default IconPaperplane;
