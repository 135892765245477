import {SvgProps} from "../index";

export const IconSmallThreeDots: React.FC<SvgProps> = ({
                                                    width = 26,
                                                    height = 26,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 26 26"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <circle cx="8.14048" cy="12.9082" r="1.76145" fill="#868895"/>
        <circle cx="13.7771" cy="12.9082" r="1.76145" fill="#868895"/>
        <circle cx="19.4137" cy="12.9082" r="1.76145" fill="#868895"/>
    </svg>
)

export default IconSmallThreeDots;
