import {SvgProps} from "../index";

export const IconWhatsapp: React.FC<SvgProps> = ({
                                                     width = 28,
                                                     height = 28,
                                                     ariaHidden,
                                                     title,
                                                     description
                                                 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 33 33"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M27.587 5.6472C24.5788 2.65019 20.5788 0.998777 16.3171 0.996948C7.53521 0.996948 0.388496 8.10994 0.38498 16.8528C0.383956 19.6475 1.1174 22.3756 2.51148 24.7803L0.251221 32.9969L8.69703 30.7921C11.024 32.0555 13.644 32.7213 16.3103 32.7224H16.317H16.3171C25.0976 32.7224 32.2454 25.6081 32.2491 16.8657C32.2506 12.6285 30.5948 8.64443 27.587 5.6472ZM16.3171 30.0443H16.312C13.9354 30.043 11.6049 29.4078 9.57211 28.2069L9.08834 27.9214L4.07642 29.2296L5.41453 24.3663L5.09934 23.8677C3.77369 21.7692 3.07386 19.344 3.07485 16.8538C3.07776 9.58704 9.01784 3.67517 16.3221 3.67517C19.8593 3.67663 23.1838 5.0493 25.6841 7.54032C28.1842 10.0313 29.5601 13.3428 29.559 16.8646C29.5559 24.1316 23.6156 30.0443 16.3171 30.0443Z"
            fill="#E0E0E0"
        />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.3799 10.1212C12.0851 9.4688 11.7748 9.45574 11.4944 9.44432C11.265 9.43453 11.0024 9.43513 10.7402 9.43513C10.4779 9.43513 10.0515 9.53329 9.69095 9.92533C9.33018 10.3174 8.31348 11.2651 8.31348 13.1926C8.31348 15.1204 9.72374 16.983 9.92032 17.2446C10.1171 17.5058 12.6427 21.5878 16.6425 23.1582C19.9666 24.4633 20.6431 24.2037 21.3646 24.1384C22.0861 24.0731 23.6928 23.1909 24.0207 22.276C24.3487 21.3613 24.3487 20.5772 24.2503 20.4133C24.1519 20.2501 23.8896 20.1521 23.496 19.9562C23.1024 19.7603 21.1678 18.8123 20.8071 18.6817C20.4463 18.5511 20.1839 18.4858 19.9215 18.8781C19.6592 19.2699 18.9054 20.1521 18.6759 20.4133C18.4463 20.6752 18.2167 20.7078 17.8231 20.5119C17.4296 20.3154 16.1622 19.9021 14.6587 18.5674C13.4888 17.5291 12.699 16.2466 12.4695 15.8544C12.2399 15.4626 12.4448 15.2503 12.6423 15.055C12.8189 14.8795 13.0358 14.5977 13.2326 14.3689C13.429 14.1401 13.4946 13.9768 13.6257 13.7156C13.7569 13.454 13.6913 13.2252 13.5929 13.0293C13.4946 12.8334 12.7298 10.8959 12.3799 10.1212Z"
              fill="black"
        />
        <path
            d="M27.4568 5.59683C24.4831 2.63422 20.5291 1.00179 16.3163 0.999939C7.63518 0.999939 0.570515 8.03128 0.567025 16.6738C0.566002 19.4365 1.29102 22.1332 2.6691 24.5103L0.434814 32.6326L8.78368 30.453C11.084 31.702 13.6739 32.3601 16.3095 32.3612H16.3162H16.3163C24.996 32.3612 32.0617 25.3286 32.0654 16.6865C32.0668 12.498 30.4301 8.55965 27.4568 5.59683ZM16.3163 29.7139H16.3112C13.9619 29.7126 11.6581 29.0847 9.6487 27.8976L9.17047 27.6153L4.21609 28.9086L5.53883 24.1011L5.22726 23.6082C3.91682 21.5338 3.22502 19.1364 3.22604 16.6749C3.2289 9.49148 9.10078 3.64747 16.3212 3.64747C19.8178 3.6489 23.1041 5.00582 25.5757 7.46825C28.0472 9.93067 29.4072 13.2041 29.4062 16.6855C29.4031 23.869 23.531 29.7139 16.3163 29.7139Z"
            fill="black"
        />
    </svg>
)

export default IconWhatsapp;
