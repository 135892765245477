import {SvgProps} from "../index";

export const IconWhisper: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M25.1165 16.1618C25.512 16.4079 25.9954 16.3112 26.2678 15.9157C27.4104 14.2985 28.0784 12.1364 28.0784 9.91279C28.0784 7.68037 27.4192 5.50947 26.2678 3.90107C25.9954 3.50556 25.512 3.40888 25.1165 3.66377C24.7034 3.91865 24.6418 4.4372 24.9495 4.89423C25.8811 6.25654 26.4172 8.04951 26.4172 9.91279C26.4172 11.7673 25.8723 13.5515 24.9495 14.9226C24.6506 15.3796 24.7034 15.8981 25.1165 16.1618ZM21.6008 13.8151C21.97 14.0524 22.4622 13.9645 22.7346 13.5866C23.4377 12.6374 23.8508 11.3015 23.8508 9.91279C23.8508 8.51533 23.4377 7.18818 22.7346 6.23017C22.4622 5.85224 21.97 5.76435 21.6008 6.01923C21.179 6.2917 21.0999 6.81025 21.4338 7.30244C21.926 7.98798 22.1985 8.92841 22.1985 9.91279C22.1985 10.8884 21.9172 11.82 21.4338 12.5231C21.1086 13.0153 21.179 13.5251 21.6008 13.8151ZM14.9827 13.9206C16.9075 13.9206 18.5334 12.2155 18.5334 10.0095C18.5334 7.85615 16.8987 6.21259 14.9827 6.21259C13.0754 6.21259 11.4407 7.88252 11.4407 10.027C11.4495 12.2155 13.0667 13.9206 14.9827 13.9206ZM9.50708 22.0944H20.4583C21.9084 22.0944 22.4094 21.655 22.4094 20.8552C22.4094 18.5876 19.5442 15.4763 14.9827 15.4763C10.4299 15.4763 7.55591 18.5876 7.55591 20.8552C7.55591 21.655 8.05688 22.0944 9.50708 22.0944Z"
            fill="black"
        />
    </svg>
)

export default IconWhisper;
