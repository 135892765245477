import {SvgProps} from "../index";

export const IconExpand: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.50293 12.5557C7.05664 12.5557 7.46973 12.1514 7.46973 11.5889V10.9648L7.28516 8.24023L9.31543 10.376L11.8291 12.9072C12.0137 13.1006 12.251 13.1885 12.5059 13.1885C13.1035 13.1885 13.5342 12.7842 13.5342 12.1865C13.5342 11.9141 13.4287 11.6768 13.2441 11.4834L10.7217 8.96094L8.57715 6.93945L11.3193 7.12402H12.0049C12.5586 7.12402 12.9805 6.71973 12.9805 6.15723C12.9805 5.59473 12.5674 5.18164 12.0049 5.18164H7.12695C6.11621 5.18164 5.52734 5.77051 5.52734 6.78125V11.5889C5.52734 12.1426 5.94043 12.5557 6.50293 12.5557ZM15.9863 22.1357H20.8643C21.875 22.1357 22.4639 21.5469 22.4639 20.5361V15.7285C22.4639 15.1748 22.042 14.7617 21.4883 14.7617C20.9346 14.7617 20.5215 15.166 20.5215 15.7285V16.3525L20.7061 19.0771L18.6758 16.9414L16.1621 14.4102C15.9775 14.2168 15.7402 14.1289 15.4766 14.1289C14.8789 14.1289 14.4482 14.5332 14.4482 15.1309C14.4482 15.4033 14.5537 15.6494 14.7471 15.834L17.2695 18.3564L19.4141 20.3779L16.6719 20.1934H15.9863C15.4238 20.1934 15.0107 20.5977 15.0107 21.1602C15.0107 21.7227 15.4238 22.1357 15.9863 22.1357Z"
            fill="black"
        />
    </svg>
)

export default IconExpand;
