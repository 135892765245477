import {SvgProps} from "../index";

export const IconPin: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M7.729 7.45116C7.729 6.70822 8.44218 6.253 8.9961 6.64237L13.3334 9.69121C13.8609 10.062 13.8609 10.938 13.3333 11.3088L8.9961 14.3576C8.44218 14.747 7.729 14.2918 7.729 13.5488V7.45116Z"
            fill="black"
        />
    </svg>
)

export default IconPin;
