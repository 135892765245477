import {SvgProps} from "../index";

export const IconSettingsCalls: React.FC<SvgProps> = ({
                                                            width = 28,
                                                            height = 28,
                                                            ariaHidden,
                                                            title,
                                                            description
                                                        }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M25.6317 21.385C25.6317 21.805 25.5384 22.2367 25.34 22.6567C25.1417 23.0767 24.885 23.4733 24.5467 23.8467C23.975 24.4767 23.345 24.9317 22.6334 25.2233C21.9334 25.515 21.175 25.6667 20.3584 25.6667C19.1684 25.6667 17.8967 25.3867 16.555 24.815C15.2134 24.2433 13.8717 23.4733 12.5417 22.505C11.1862 21.5135 9.90701 20.4215 8.71504 19.2383C7.53527 18.0507 6.44716 16.7754 5.46004 15.4233C4.50337 14.0933 3.73337 12.7633 3.17337 11.445C2.61337 10.115 2.33337 8.84334 2.33337 7.63001C2.33337 6.83668 2.47337 6.07834 2.75337 5.37834C3.03337 4.66668 3.47671 4.01334 4.09504 3.43001C4.84171 2.69501 5.65837 2.33334 6.52171 2.33334C6.84837 2.33334 7.17504 2.40334 7.46671 2.54334C7.77004 2.68334 8.03837 2.89334 8.24837 3.19668L10.955 7.01168C11.165 7.30334 11.3167 7.57168 11.4217 7.82834C11.5267 8.07334 11.585 8.31834 11.585 8.54001C11.585 8.82001 11.5034 9.10001 11.34 9.36834C11.1884 9.63668 10.9667 9.91668 10.6867 10.1967L9.80004 11.1183C9.67171 11.2467 9.61337 11.3983 9.61337 11.585C9.61337 11.6783 9.62504 11.76 9.64837 11.8533C9.68337 11.9467 9.71837 12.0167 9.74171 12.0867C9.95171 12.4717 10.3134 12.9733 10.8267 13.58C11.3517 14.1867 11.9117 14.805 12.5184 15.4233C13.1484 16.0417 13.755 16.6133 14.3734 17.1383C14.98 17.6517 15.4817 18.0017 15.8784 18.2117C15.9367 18.235 16.0067 18.27 16.0884 18.305C16.1817 18.34 16.275 18.3517 16.38 18.3517C16.5784 18.3517 16.73 18.2817 16.8584 18.1533L17.745 17.2783C18.0367 16.9867 18.3167 16.765 18.585 16.625C18.8534 16.4617 19.1217 16.38 19.4134 16.38C19.635 16.38 19.8684 16.4267 20.125 16.5317C20.3817 16.6367 20.65 16.7883 20.9417 16.9867L24.8034 19.7283C25.1067 19.9383 25.3167 20.1833 25.445 20.475C25.5617 20.7667 25.6317 21.0583 25.6317 21.385Z"
            stroke="#0D112B" strokeWidth="1.5" strokeMiterlimit="10"/>
    </svg>
)

export default IconSettingsCalls;
