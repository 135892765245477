import {SvgProps} from "../index";

export const IconPin: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M6.67871 18.9453C6.67871 20.2021 7.46973 20.9668 8.7793 20.9668H19.2119C20.5127 20.9668 21.3125 20.2021 21.3125 18.9453V8.36328C21.3125 7.10645 20.5127 6.3418 19.2119 6.3418H8.7793C7.46973 6.3418 6.67871 7.10645 6.67871 8.36328V18.9453Z" fill="#1C1C1E"/>
    </svg>
)

export default IconPin;
