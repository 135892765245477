import {SvgProps} from "../index";

export const IconCallOutgoing: React.FC<SvgProps> = ({
                                                         width = 28,
                                                         height = 28,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M15.7754 12.8721C15.9775 12.8721 16.1621 12.8018 16.3115 12.6523L19.8535 9.10156L21.0576 7.79199L20.9082 10.0684V11.334C20.9082 11.7383 21.2158 12.0459 21.6289 12.0635C22.042 12.0723 22.3848 11.7559 22.3848 11.3428V6.39453C22.3848 5.81445 22.0859 5.49805 21.4971 5.49805H16.5225C16.1094 5.49805 15.7842 5.84082 15.8018 6.2627C15.8105 6.67578 16.127 6.97461 16.5225 6.97461H17.8232L20.126 6.83398L18.79 8.03809L15.248 11.5801C15.0811 11.7471 15.002 11.9404 15.002 12.1602C15.002 12.5645 15.3359 12.8721 15.7754 12.8721ZM10.0801 17.6885C12.6729 20.29 15.8281 22.2939 18.4033 22.2939C19.5547 22.2939 20.5654 21.8896 21.374 20.9932C21.8486 20.4658 22.1387 19.8594 22.1387 19.2529C22.1387 18.7871 21.9541 18.3301 21.5059 18.0137L18.7988 16.0977C18.377 15.7988 18.0254 15.6582 17.709 15.6582C17.2959 15.6582 16.9268 15.8867 16.5225 16.291L15.8809 16.9238C15.7842 17.0205 15.6611 17.0645 15.5469 17.0645C15.4062 17.0645 15.2744 17.0117 15.1777 16.959C14.6328 16.6602 13.6836 15.8516 12.8047 14.9727C11.9258 14.0938 11.1084 13.1533 10.8184 12.5908C10.7656 12.4941 10.7129 12.3711 10.7129 12.2305C10.7129 12.125 10.748 12.002 10.8535 11.8965L11.4863 11.2549C11.8818 10.8418 12.1191 10.4814 12.1191 10.0684C12.1191 9.74316 11.9785 9.3916 11.6797 8.96973L9.77246 6.29785C9.44727 5.84082 8.98145 5.64746 8.48926 5.64746C7.8916 5.64746 7.29395 5.91113 6.7666 6.41211C5.89648 7.23828 5.50977 8.2666 5.50977 9.40039C5.50977 11.9756 7.47852 15.0957 10.0801 17.6885Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconCallOutgoing;