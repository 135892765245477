import {SvgProps} from "../index";

export const IconDeleteChat: React.FC<SvgProps> = ({
                                                            width = 28,
                                                            height = 28,
                                                            ariaHidden,
                                                            title,
                                                            description
                                                        }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M9.32422 21.8809H18.667C20.4775 21.8809 21.2861 20.9756 21.5586 19.1738L22.8418 10.666H22.8682C24.0723 10.666 24.7754 9.89258 24.7754 8.69727V7.42285C24.7754 6.22754 24.0723 5.4541 22.8682 5.4541H5.13184C3.97168 5.4541 3.22461 6.22754 3.22461 7.42285V8.69727C3.22461 9.89258 3.92773 10.666 5.13184 10.666H5.14941L6.43262 19.1738C6.71387 20.9844 7.51367 21.8809 9.32422 21.8809ZM5.46582 9.0752C5.06152 9.0752 4.88574 8.89062 4.88574 8.49512V7.625C4.88574 7.2207 5.06152 7.04492 5.46582 7.04492H22.543C22.9385 7.04492 23.1055 7.2207 23.1055 7.625V8.49512C23.1055 8.89062 22.9385 9.0752 22.543 9.0752H5.46582ZM9.4209 20.2021C8.68262 20.2021 8.27832 19.877 8.15527 19.0596L6.87207 10.666H21.1191L19.8359 19.0596C19.7129 19.877 19.3086 20.2021 18.5791 20.2021H9.4209ZM11.5654 18.5674C11.7676 18.5674 11.9434 18.4883 12.084 18.3389L13.9912 16.4316L15.8984 18.3389C16.0479 18.4795 16.2148 18.5674 16.4346 18.5674C16.8389 18.5674 17.1816 18.2246 17.1816 17.8115C17.1816 17.5918 17.0938 17.4336 16.9531 17.2842L15.0547 15.3857L16.9531 13.4697C17.1113 13.3115 17.1904 13.1533 17.1904 12.9512C17.1904 12.5293 16.8477 12.1953 16.4346 12.1953C16.2324 12.1953 16.0742 12.2744 15.916 12.4238L13.9912 14.3398L12.084 12.4326C11.9258 12.2832 11.7676 12.2041 11.5654 12.2041C11.1523 12.2041 10.8096 12.5381 10.8096 12.9512C10.8096 13.1621 10.8887 13.3291 11.0381 13.4785L12.9365 15.3857L11.0381 17.2842C10.8975 17.4336 10.8096 17.6006 10.8096 17.8115C10.8096 18.2246 11.1436 18.5674 11.5654 18.5674Z"
            fill="black"
        />
    </svg>
)

export default IconDeleteChat;