import {SvgProps} from "../index";

export const IconStar: React.FC<SvgProps> = ({
                                                            width = 28,
                                                            height = 28,
                                                            ariaHidden,
                                                            title,
                                                            description
                                                        }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 -1 28 31"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M15.4141 5.93326L17.4456 10.1772C17.594 10.4821 17.8739 10.6993 18.2066 10.7505L18.2121 10.751L22.7529 11.4323C23.2488 11.5088 23.6252 11.9448 23.6252 12.4719C23.6252 12.7667 23.5074 13.0328 23.3181 13.2235L20.0319 16.5263C19.8421 16.7176 19.7242 16.9836 19.7242 17.2784C19.7242 17.3409 19.7297 17.4022 19.7395 17.4618L19.7384 17.4556L20.5142 22.121C20.524 22.1755 20.5294 22.2385 20.5294 22.3032C20.5294 22.8827 20.0739 23.3524 19.512 23.3524C19.3358 23.3524 19.1706 23.3063 19.026 23.2253L19.0314 23.2281L14.9695 21.0262C14.8321 20.9503 14.669 20.9058 14.4955 20.9058C14.322 20.9058 14.1589 20.9503 14.016 21.029L14.0214 21.0262L9.95955 23.2281C9.82099 23.3057 9.6557 23.3518 9.48004 23.3518C8.91762 23.3518 8.46157 22.8815 8.46157 22.3015C8.46157 22.238 8.46703 22.1755 8.47739 22.1153L8.4763 22.1215L9.25202 17.4562C9.26129 17.4033 9.26675 17.342 9.26675 17.2796C9.26675 16.9848 9.14892 16.7181 8.95908 16.5268L5.6729 13.2241C5.48307 13.0333 5.36523 12.7667 5.36523 12.4719C5.36523 11.9453 5.74109 11.5088 6.23151 11.4329L6.23697 11.4323L10.7789 10.751C11.1171 10.6987 11.397 10.4816 11.5426 10.1828L11.5454 10.1767L13.5769 5.9327C13.7449 5.57716 14.0929 5.33694 14.495 5.33694C14.897 5.33694 15.245 5.57716 15.4103 5.92651L15.4131 5.9327L15.4141 5.93326Z"
            fill="#FF7461"
        />
    </svg>
)

export default IconStar;