import {SvgProps} from "../index";

export const IconQuestionMark: React.FC<SvgProps> = ({
                                                    width = 12,
                                                    height = 12,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
            <path fillRule="evenodd" clipRule="evenodd" d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM5.36279 7.4254H6.4C6.4031 7.18705 6.43566 6.97536 6.49767 6.79032C6.55969 6.60215 6.67752 6.42809 6.85116 6.26815C7.05271 6.07684 7.24031 5.88553 7.41395 5.69422C7.5876 5.49978 7.72868 5.29122 7.83721 5.06855C7.94574 4.84274 8 4.59185 8 4.31586C8 3.93324 7.92093 3.60708 7.76279 3.33737C7.60775 3.06452 7.38295 2.85753 7.08837 2.7164C6.7938 2.57213 6.43876 2.5 6.02326 2.5C5.64806 2.5 5.30853 2.56743 5.00465 2.70229C4.70078 2.83714 4.45891 3.03943 4.27907 3.30914C4.09922 3.57885 4.0062 3.91129 4 4.30645H5.12093C5.12713 4.09946 5.17209 3.93324 5.25581 3.8078C5.33953 3.67921 5.44961 3.58513 5.58605 3.52554C5.72248 3.46281 5.86822 3.43145 6.02326 3.43145C6.2093 3.43145 6.36434 3.46752 6.48837 3.53965C6.6155 3.61178 6.71163 3.71685 6.77674 3.85484C6.84186 3.99283 6.87442 4.16062 6.87442 4.3582C6.87442 4.5401 6.84031 4.70161 6.77209 4.84274C6.70388 4.98073 6.6124 5.11246 6.49767 5.2379C6.38295 5.36022 6.25426 5.49037 6.11163 5.62836C5.92868 5.81026 5.7814 5.98275 5.66977 6.14583C5.56124 6.30892 5.48372 6.49082 5.43721 6.69153C5.3907 6.88911 5.36589 7.13374 5.36279 7.4254ZM5.44186 8.46505C5.32713 8.58109 5.26977 8.72379 5.26977 8.89315C5.26977 9.0625 5.32713 9.20677 5.44186 9.32594C5.55659 9.44198 5.71318 9.5 5.91163 9.5C6.11008 9.5 6.26667 9.44198 6.3814 9.32594C6.49612 9.20677 6.55349 9.0625 6.55349 8.89315C6.55349 8.72379 6.49612 8.58109 6.3814 8.46505C6.26667 8.34588 6.11008 8.28629 5.91163 8.28629C5.71318 8.28629 5.55659 8.34588 5.44186 8.46505Z" fill="#027FFE"/>

    </svg>
)

export default IconQuestionMark;
