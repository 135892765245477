import {SvgProps} from "../index";

export const IconAddUser: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.426 22.8058C16.426 22.2535 16.8737 21.8058 17.426 21.8058H24.9929C25.5452 21.8058 25.9929 22.2535 25.9929 22.8058C25.9929 23.3581 25.5452 23.8058 24.9929 23.8058H17.426C16.8737 23.8058 16.426 23.3581 16.426 22.8058Z"
              fill="#010101"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.2095 18.0223C21.7618 18.0223 22.2095 18.4701 22.2095 19.0223V26.5892C22.2095 27.1415 21.7618 27.5892 21.2095 27.5892C20.6572 27.5892 20.2095 27.1415 20.2095 26.5892V19.0223C20.2095 18.4701 20.6572 18.0223 21.2095 18.0223Z"
              fill="#010101"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.1826 3.51587C11.8973 3.51587 10.0464 5.37247 10.0464 7.66372C10.0464 9.89074 11.7837 11.6971 13.9781 11.7984C14.1165 11.7892 14.2623 11.7887 14.4011 11.7983C16.5918 11.6963 18.3305 9.93859 18.3305 7.66372C18.3305 5.37541 16.471 3.51587 14.1826 3.51587ZM8.04639 7.66372C8.04639 4.27378 10.7868 1.51587 14.1826 1.51587C17.5755 1.51587 20.3305 4.27084 20.3305 7.66372C20.3305 11.051 17.6926 13.6887 14.4135 13.7994C14.369 13.8009 14.3245 13.7994 14.2802 13.795C14.2333 13.7903 14.1581 13.7895 14.0877 13.7959C14.0464 13.7996 14.005 13.8008 13.9635 13.7994C10.6638 13.6885 8.04639 10.9842 8.04639 7.66372Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.26 16.5543C12.3267 16.5866 10.295 16.9961 8.92998 17.9094L8.9278 17.9109L8.9278 17.9109C7.73366 18.7053 7.26953 19.6543 7.26953 20.4769C7.26953 21.3001 7.73439 22.2527 8.93017 23.0532C9.8166 23.6465 11.608 24.3121 14.0705 24.3121C14.6228 24.3121 15.0705 24.7598 15.0705 25.3121C15.0705 25.8644 14.6228 26.3121 14.0705 26.3121C11.2821 26.3121 9.08709 25.565 7.81761 24.7151C6.20757 23.6373 5.26953 22.1203 5.26953 20.4769C5.26953 18.8332 6.20788 17.3187 7.81889 16.2465C9.64221 15.027 12.1196 14.5898 14.2267 14.5546C15.2963 14.5367 16.3157 14.6215 17.1703 14.7816C17.9811 14.9335 18.7845 15.1779 19.306 15.569C19.7479 15.9004 19.8374 16.5272 19.5061 16.969C19.1747 17.4108 18.5479 17.5004 18.1061 17.169C17.9712 17.0679 17.5535 16.8881 16.8021 16.7474C16.0946 16.6148 15.2108 16.5384 14.26 16.5543Z"
              fill="black"
        />
    </svg>
)

export default IconAddUser;