import {SvgProps} from "../index";

export const IconPin: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.39355 5.35443C9.39355 2.80949 11.4552 0.747803 14.0002 0.747803C16.5451 0.747803 18.6068 2.80949 18.6068 5.35443L18.6068 12.6535C18.6068 15.1985 16.5451 17.2601 14.0002 17.2601C11.4552 17.2601 9.39355 15.1985 9.39355 12.6535V5.35443ZM14.0002 2.8478C12.615 2.8478 11.4936 3.96929 11.4936 5.35443V12.6535C11.4936 14.0387 12.615 15.1601 14.0002 15.1601C15.3853 15.1601 16.5068 14.0387 16.5068 12.6535L16.5068 5.35443C16.5068 3.96929 15.3853 2.8478 14.0002 2.8478Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.0749 10.2083C5.6548 10.2083 6.1249 10.6784 6.1249 11.2583V13.2417C6.1249 17.5851 9.65647 21.1167 13.9999 21.1167C18.3433 21.1167 21.8749 17.5851 21.8749 13.2417V11.2583C21.8749 10.6784 22.345 10.2083 22.9249 10.2083C23.5048 10.2083 23.9749 10.6784 23.9749 11.2583V13.2417C23.9749 18.3903 20.0608 22.6361 15.0499 23.1619V25.6667C15.0499 26.2466 14.5798 26.7167 13.9999 26.7167C13.42 26.7167 12.9499 26.2466 12.9499 25.6667V23.1619C7.93898 22.6361 4.0249 18.3903 4.0249 13.2417V11.2583C4.0249 10.6784 4.495 10.2083 5.0749 10.2083Z"
              fill="black"/>
    </svg>
)

export default IconPin;
