import {SvgProps} from "../index";

export const IconNumberList: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.98189 6.52643C6.37425 6.68895 6.63007 7.07182 6.63007 7.4965V14.0002C6.63007 14.5801 6.15997 15.0502 5.58007 15.0502C5.00017 15.0502 4.53007 14.5801 4.53007 14.0002V10.0314L4.08006 10.4814C3.67001 10.8915 3.00519 10.8915 2.59514 10.4814C2.18508 10.0714 2.18509 9.40656 2.59514 8.99651L4.83761 6.75404C5.13791 6.45374 5.58953 6.36391 5.98189 6.52643ZM10.0991 7.4965C10.0991 6.9166 10.5692 6.4465 11.1491 6.4465H24.5987C25.1786 6.4465 25.6487 6.9166 25.6487 7.4965C25.6487 8.0764 25.1786 8.5465 24.5987 8.5465H11.1491C10.5692 8.5465 10.0991 8.0764 10.0991 7.4965ZM10.0991 14.0002C10.0991 13.4203 10.5692 12.9502 11.1491 12.9502H24.5987C25.1786 12.9502 25.6487 13.4203 25.6487 14.0002C25.6487 14.5801 25.1786 15.0502 24.5987 15.0502H11.1491C10.5692 15.0502 10.0991 14.5801 10.0991 14.0002ZM10.0991 20.5038C10.0991 19.9239 10.5692 19.4538 11.1491 19.4538H24.5987C25.1786 19.4538 25.6487 19.9239 25.6487 20.5038C25.6487 21.0837 25.1786 21.5538 24.5987 21.5538H11.1491C10.5692 21.5538 10.0991 21.0837 10.0991 20.5038Z"
              fill="black"
        />
    </svg>
)

export default IconNumberList;
