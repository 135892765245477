import { createSelector, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
    ICall,
    IMailboxMessage,
    InterTenantClient,
    InterTenantUser,
    ITelecommunicationsIdentificationDisplay,
    IYeOldeCall,
    LastCalled,
    MailboxDisplay
} from '../../../types';
import { voicemailAdapter } from './slice';

export interface ICallerId {
    cli_display: string;
    cli_name: string;
    number: string;
    uuid: string;
    created_on: string;
    status_id: number;
    supports_sms?: boolean;
}

export const voicemailEntitySelector = voicemailAdapter.getSelectors();

export const selectCallHistoryLoading = (state: RootState) => state.sip.callHistoryLoading;

export const selectAllCallerIds = (state: RootState) => state.sip.callerIds;

export const selectShowFeedback = (state: RootState) => state.sip.callFeedback?.show;

export const selectActiveFeedbackForms = (state: RootState) => state.sip.callFeedback?.forms;

export const selectUsersForGroupCall = (state: RootState) => state.sip.usersForGroupCall;

export const selectConferenceInvitations = (state: RootState) => state.sip.conferenceInvitations;

export const selectAllCalls = (state: RootState): ICall[] => state.sip.calls;

export const selectCallRecording = (state: RootState) => state.sip.call_recording;

export const selectCallFeedbackTags = (state: RootState) => state.sip.feedback_tags;

export const selectTransferringCall = (state: RootState) => state.sip.transferring_call;

export const selectParkingSlots = (state: RootState) => state.sip.parkingSlots;

export const selectCallHistoryWeek = (state: RootState) => state.sip.callHistoryWeek;

export const selectCallHistory = createSelector(
    [(state: RootState) => state.sip.callHistory],
    (callHistory) => callHistory
);

export const selectMailboxGotten = (state: RootState, mailbox: string): boolean =>
    state.sip.mailbox_gotten[mailbox] || false;

export const selectLastCalledNumber = (state: RootState): LastCalled | undefined =>
    state.sip.last_called;

export const handleFindingATransfer = createSelector(
    [selectAllCalls],
    (c?: ICall[]): boolean => c?.some(i => i.beingTransferred) || false
);

export const selectCallHistoryById = createSelector(
    [selectCallHistory, (_: RootState, id: string | undefined) => id],
    (calls: IYeOldeCall[] | undefined, id: string | undefined): IYeOldeCall | undefined =>
        calls?.find(call => call.uuid === id)
);

export const selectCallerIdDictionary = createSelector(
    [selectAllCallerIds],
    (
        callerIds: ITelecommunicationsIdentificationDisplay[]
    ): Record<string, ITelecommunicationsIdentificationDisplay> =>
        Object.fromEntries(callerIds.map(callerId => [callerId.uuid, callerId]))
);

export const selectCallerIdById = createSelector(
    [selectAllCallerIds, (_: RootState, id: string) => id],
    (callerIds: ICallerId[] | undefined, id: string): ICallerId | undefined =>
        callerIds?.find(callerId => callerId.uuid === id)
);

export const selectMessageIds = createSelector(
    [selectAllCallerIds],
    (callerIds: ICallerId[]): ICallerId[] =>
        callerIds?.filter(callerId => callerId.supports_sms) || []
);

export const selectCurrentMessageId = createSelector(
    [selectAllCallerIds, (state: RootState) => state.user.message_caller_id],
    (callerIds: ICallerId[], messageId: string | undefined): ICallerId | undefined =>
        callerIds?.find(callerId => callerId.uuid === messageId)
);

export const selectAllVoicemailsOnBox = (state: RootState, mailbox: string): IMailboxMessage[] => {
    const addr = state.sip?.mailbox_messages?.[mailbox];
    if (!addr) return [];
    return voicemailEntitySelector.selectAll(addr);
};

export const selectVoicemailByUuid = (
    state: RootState,
    mailbox: string,
    messageUuid: string
): IMailboxMessage | undefined => {
    const addr = state.sip?.mailbox_messages?.[mailbox];
    if (!addr) return undefined;
    return voicemailEntitySelector.selectById(addr, messageUuid);
};

const selectVoicemailBoxByUuid = createSelector(
    [
        (state: RootState, mailbox: string): MailboxDisplay | undefined =>
            state.sip?.mailboxes?.find(m => m.uuid === mailbox)
    ],
    mailbox => mailbox
);

export const selectNewVoicemailCountOnBox = createSelector(
    [selectAllVoicemailsOnBox, selectVoicemailBoxByUuid],
    (voicemails, details): number => {
        if (!voicemails || voicemails.length < 1) return details?.unseen_count || 0;

        let count = 0;

        voicemails.forEach(vm => {
            if (vm.is_new) {
                count += 1;
            }
        });

        return count;
    }
);

export const selectNewVoicemailCount = createSelector(
    [
        (state: RootState): Record<string, EntityState<IMailboxMessage>> | undefined =>
            state.sip?.mailbox_messages,
        (state: RootState): MailboxDisplay[] | undefined => state.sip?.mailboxes
    ],
    (boxes, displays): number => {
        if (!boxes || !displays) return 0;

        let count = 0;

        displays.forEach(d => {
            const box = boxes[d.uuid];
            if (box.ids.length < 1) {
                count += d.unseen_count || 0;
            } else {
                Object.values(boxes[d.uuid]).forEach(m => {
                    if (m?.is_new) {
                        count += 1;
                    }
                });
            }
        });

        return count;
    }
);

export const selectInterUserTenants = createSelector(
    [(state: RootState) => state.sip.inter_tenant_clients],
    (clients): Record<string, InterTenantClient> => clients
);

export const selectInterTenantById = createSelector(
    [selectInterUserTenants, (_: any, uuid: string | undefined) => uuid],
    (tenants, uuid): InterTenantClient | undefined => {
        if (!uuid) return undefined;

        return tenants[uuid];
    }
);

export const selectInterTenantClient = createSelector(
    [selectInterUserTenants, (_: any, tenant: string) => tenant],
    (tenants, tenant): InterTenantClient | undefined => {
        const found = tenants[tenant];

        if (!found) {
            return undefined;
        }

        return found;
    }
);

export const selectInterTenantList = createSelector(
    [selectInterUserTenants],
    (tenants): InterTenantClient[] =>
        Object.values(tenants).map(c => ({
            ...c,
            users: []
        }))
);

export const selectAnyInterTenantUserId = createSelector(
    [selectInterUserTenants, (_: any, userUuid?: string) => userUuid],
    (tenants, userUuid) => {
        if (!userUuid) return undefined;

        const ts = Object.values(tenants);

        for (let i = 0; i < ts.length; i += 1) {
            const t = ts[i];
            if (t && t.users) {
                const foundUsr = t.users?.find(u => u.user_uuid === userUuid);

                if (foundUsr) {
                    return {
                        tenant: t.uuid,
                        prefix: t.prefix,
                        client: foundUsr
                    };
                }
            }
        }

        return undefined;
    }
);

interface TenantRefClient extends InterTenantUser {
    tenant_uuid: string;
    prefix: number;
}

export const selectTenantClients = createSelector(
    [selectInterUserTenants],
    (tenants): Record<string, TenantRefClient> => {
        const res: Record<string, TenantRefClient> = {};

        Object.values(tenants).forEach(t => {
            t.users?.forEach(u => {
                res[u.user_uuid] = {
                    ...u,
                    tenant_uuid: t.uuid,
                    prefix: t.prefix
                };
            });
        });

        return res;
    }
);

export const selectMailboxes = createSelector(
    [(state: RootState) => state.sip.mailboxes],
    (mailboxes: MailboxDisplay[]) => mailboxes
);
