import {SvgProps} from "../index";

export const IconPhoneUnfill: React.FC<SvgProps> = ({
                                                        width = 28,
                                                        height = 28,
                                                        ariaHidden,
                                                        title,
                                                        description
                                                    }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M18.7578 22.7704C20.2959 22.7704 21.3418 22.331 22.291 21.3026C22.3525 21.2323 22.4141 21.1532 22.4756 21.0829C22.9941 20.5028 23.249 19.8964 23.249 19.3339C23.249 18.6747 22.8975 18.0771 22.1152 17.5409L19.4961 15.7128C18.6699 15.1415 17.7119 15.0976 16.9736 15.8359L16.3232 16.4862C16.0947 16.7148 15.875 16.7323 15.6113 16.5478C15.1367 16.2314 14.2402 15.4403 13.625 14.8251C13.001 14.2011 12.3682 13.498 12.0254 12.9618C11.8408 12.6894 11.8584 12.4784 12.0869 12.2499L12.7373 11.5995C13.4756 10.8612 13.4316 9.90323 12.8516 9.07706L10.9883 6.41398C10.4697 5.65812 9.8457 5.30656 9.18652 5.29777C8.62402 5.28898 8.02637 5.54386 7.44629 6.05363C7.37598 6.11515 7.30566 6.17667 7.23535 6.2382C6.18945 7.1962 5.74121 8.22452 5.74121 9.82413C5.74121 12.4169 7.24414 15.4755 10.1709 18.3847C13.0801 21.2763 16.1035 22.7704 18.7578 22.7704ZM18.7578 21.3026C16.5166 21.3378 13.7305 19.747 11.2871 17.3212C8.82617 14.8954 7.19141 12.0038 7.22656 9.76261C7.24414 8.82218 7.57812 7.99601 8.2373 7.41593C8.30762 7.3632 8.36035 7.31925 8.43066 7.26652C8.67676 7.05558 8.94043 6.94132 9.18652 6.94132C9.44141 6.94132 9.66992 7.04679 9.8457 7.31046L11.4893 9.78019C11.7002 10.079 11.709 10.4218 11.4014 10.7206L10.6982 11.415C10.0303 12.0653 10.0918 12.83 10.5137 13.4276C10.9883 14.0868 11.876 15.0976 12.623 15.8359C13.4229 16.6269 14.5127 17.6025 15.1455 18.0595C15.7432 18.4814 16.5078 18.5429 17.1582 17.8749L17.8525 17.1718C18.1426 16.8642 18.4854 16.873 18.793 17.0751L21.2188 18.6923C21.4912 18.8681 21.5879 19.0878 21.5879 19.3427C21.5879 19.5888 21.4736 19.8525 21.2627 20.1073C21.21 20.1689 21.166 20.2216 21.1133 20.2919C20.5332 20.9599 19.707 21.2939 18.7578 21.3026Z"
            fill="black"
        />
    </svg>
)

export default IconPhoneUnfill;
