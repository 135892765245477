import {SvgProps} from "../../index";

export const IconBLFCallswitchCalling: React.FC<SvgProps> = ({
                                                         width = 50,
                                                         height = 50,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 50 50"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_367_10340)"/>
        <path d="M31.3513 12.2084C30.9668 12.5819 30.9778 13.2081 31.3513 13.5817L35.0427 17.2841C35.4163 17.6576 36.0425 17.6576 36.416 17.2841C36.8005 16.9105 36.8005 16.2953 36.416 15.9108L32.7136 12.2084C32.3401 11.8348 31.7139 11.8348 31.3513 12.2084ZM19.0137 29.6107C22.2546 32.8627 26.1987 35.3676 29.4177 35.3676C30.8569 35.3676 32.1204 34.8622 33.1311 33.7416C33.7244 33.0824 34.0869 32.3243 34.0869 31.5663C34.0869 30.984 33.8562 30.4127 33.2959 30.0172L29.9121 27.6222C29.3848 27.2487 28.9453 27.0729 28.5498 27.0729C28.0334 27.0729 27.572 27.3585 27.0667 27.8639L26.2646 28.6549C26.1438 28.7758 25.99 28.8307 25.8472 28.8307C25.6714 28.8307 25.5066 28.7648 25.3857 28.6989C24.7046 28.3253 23.5181 27.3146 22.4194 26.2159C21.3208 25.1173 20.2991 23.9418 19.9365 23.2386C19.8706 23.1178 19.8047 22.964 19.8047 22.7882C19.8047 22.6564 19.8486 22.5026 19.9805 22.3707L20.7715 21.5687C21.2659 21.0524 21.5625 20.6019 21.5625 20.0856C21.5625 19.6791 21.3867 19.2396 21.0132 18.7123L18.6292 15.3724C18.2227 14.8011 17.6404 14.5594 17.0251 14.5594C16.2781 14.5594 15.531 14.889 14.8718 15.5153C13.7842 16.548 13.3008 17.8334 13.3008 19.2506C13.3008 22.4696 15.7617 26.3698 19.0137 29.6107ZM30.2417 18.3937C30.8459 18.976 31.8018 18.976 32.406 18.3827C32.9883 17.7894 32.9883 16.8226 32.406 16.2294C31.8018 15.6361 30.835 15.6361 30.2417 16.2184C29.6375 16.8226 29.6375 17.7784 30.2417 18.3937ZM26.2207 17.328C25.8472 17.7015 25.8472 18.3168 26.2207 18.6903L29.9231 22.3927C30.3076 22.7772 30.9229 22.7772 31.2964 22.3927C31.6699 22.0192 31.6699 21.4039 31.2964 21.0304L27.594 17.328C27.2205 16.9545 26.5942 16.9545 26.2207 17.328ZM25.1221 23.5023C25.7263 24.0956 26.6821 24.0956 27.2864 23.5023C27.8796 22.8981 27.8796 21.9313 27.2864 21.349C26.6821 20.7448 25.7263 20.7448 25.1221 21.338C24.5288 21.9313 24.5288 22.8981 25.1221 23.5023Z" fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_367_10340" x1="35.3369" y1="49.9999" x2="8.4854" y2="-47.4943" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E25201"/>
                <stop offset="1" stopColor="#F8AF8F"/>
            </linearGradient>
        </defs>
    </svg>
)

export default IconBLFCallswitchCalling;
