import { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useTypedSelector } from '../../../redux/hooks';
import {
    IHuntGroup,
    InterTenantClient,
    IPhonebookContact,
    isHuntGroup,
    isInterTenantclient,
    isPhonebookContact,
    isUser,
    IUser
} from '../../../types';
import {
    selectAllPhonebookContacts,
    selectAllUsers,
    selectApiOnlyPhonebooks,
    selectFavouriteContacts,
    selectHuntGroups,
    selectInterTenantList
} from '../../../redux/slices';
import { usePolyglot } from '../../../context/Polyglot';
import { useSearchContactsQuery } from '../../../redux/services/sipApi';

export type ContactListItem = IUser | IPhonebookContact | IHuntGroup | InterTenantClient;

export type ContactDisplayItem =
    | {
          type: 1;
          data: IUser;
      }
    | {
          type: 3;
          data: IPhonebookContact;
      }
    | {
          type: 2;
          data: IHuntGroup;
      }
    | {
          type: 4;
          data: InterTenantClient;
      };

type getFirstOrLastnameItem =
    | {
          user: IUser;
          contact?: never;
      }
    | {
          user?: never;
          contact: IPhonebookContact;
      };

export type ContactsFilterType =
    | 'Internal Users'
    | 'Phonebook Contacts'
    | 'Hunt Groups'
    | 'Tenants'
    | string;

export const useGetfirstOrLastName = () => {
    const sortByLastName = useTypedSelector(
        state => state.user.settings.phone.settings?.contactsSortByLastName
    );

    return ({ user, contact }: getFirstOrLastnameItem): string => {
        if (contact) {
            if (!contact.last_name && !contact.first_name) {
                return contact.company_name || '';
            }
            if (!contact.first_name) {
                return contact.last_name || '';
            }
            if (!contact.last_name) {
                return contact.first_name || '';
            }

            if (sortByLastName) {
                return `${contact.last_name} ${contact.first_name}`;
            }
            return `${contact.first_name} ${contact.last_name}`;
        }

        if (!user) return '?';

        if (!sortByLastName) return user.nickname;

        const arr = user.nickname.split(' ');
        arr.reverse();
        return arr.join(' ');
    };
};

export const useAllContacts = ({
    searchQuery,
    filter
}: {
    searchQuery: string;
    filter?: ContactsFilterType[];
}) => {
    const favouriteContacts = useTypedSelector(selectFavouriteContacts);
    const sortByLastName = useTypedSelector(
        state => state.user.settings.phone.settings?.contactsSortByLastName
    );
    const disableHuntGroupDisplay = useTypedSelector(
        state => state.user.settings.phone.settings?.disableHuntGroupDisplay
    );
    const appDisableHuntGroups =
        useTypedSelector(state => state.user.app_hide_hunt_groups) || false;
    const huntGroups: IHuntGroup[] = useTypedSelector(state => state.auth.huntGroups) || [];
    const users: IUser[] = useTypedSelector(state => state.auth.users) || [];
    const phonebookContacts = useTypedSelector(selectAllPhonebookContacts);
    const apiOnlyPhonebookUuids = useTypedSelector(selectApiOnlyPhonebooks);

    const [initialLoad, setInitialLoad] = useState<boolean>(true);

    const getFirstOrLastName = useGetfirstOrLastName();
    const { t } = usePolyglot();

    const { data: apiContacts, isLoading: apiContactsLoading } = useSearchContactsQuery(
        {
            search_term: searchQuery.trim(),
            phone_books: apiOnlyPhonebookUuids,
            sort_by_last_name: sortByLastName
        },
        {
            skip:
                !searchQuery ||
                searchQuery.length < 1 ||
                searchQuery.trim().length < 1 ||
                apiOnlyPhonebookUuids.length < 1 ||
                !(!filter || filter.length < 1 || filter.includes('Phonebook Contacts'))
        }
    );

    useEffect(() => {
        if (users.length > 0) {
            setInitialLoad(false);
        }
    }, [users.length]);

    let dividerLetter: string | undefined;

    type ItemType = IUser | IPhonebookContact | IHuntGroup | InterTenantClient;

    function getCompStr(item: ItemType): string {
        if (isUser(item)) {
            return getFirstOrLastName({ user: item });
        }

        if (isHuntGroup(item)) {
            return item.name;
        }

        if (isPhonebookContact(item)) {
            return getFirstOrLastName({ contact: item });
        }

        if (isInterTenantclient(item)) {
            return item.name;
        }

        return '';
    }

    /** OPTIMISE THE SORT - come up with alternative solution */
    const allContactsMergedSorted: ContactListItem[] = useMemo(() => {
        const enabled = {
            users: !filter || filter.length < 1 || filter.includes('Internal Users'),
            huntGroups:
                !appDisableHuntGroups &&
                !disableHuntGroupDisplay &&
                (!filter || filter.length < 1 || filter.includes('Hunt Groups')),
            phonebookContacts:
                !filter || filter.length < 1 || filter.includes('Phonebook Contacts'),
            tenants: !filter || filter.length < 1 || filter.includes('Tenants')
        };

        /**
         * Generate an empty array with n empty elements where n is the count of allowed elements
         * Optimisation: declaring the length of the array initially prevents shape change & memory re allocation
         */
        const contacts: ContactListItem[] = Array(
            (enabled.users ? users.length : 0) +
                (enabled.phonebookContacts ? phonebookContacts.length : 0) +
                (enabled.huntGroups ? huntGroups.length : 0) +
                (enabled.tenants ? 1 : 0)
        );

        const pushIntoContacts = (arr2: ContactListItem[], start: number) => {
            for (let i = 0; i < arr2.length; i += 1) {
                contacts[start + i] = arr2[i];
            }
        };

        let start = 0;

        if (enabled.users) {
            pushIntoContacts(users, start);
            start += users.length;
        }

        if (enabled.huntGroups) {
            pushIntoContacts(huntGroups, start);
            start += huntGroups.length;
        }

        if (enabled.phonebookContacts) {
            pushIntoContacts(
                phonebookContacts.filter(c => c.first_name || c.last_name || c.company_name),
                start
            );
        }

        return (
            contacts.sort((a, b) =>
                getCompStr(a).toLowerCase().localeCompare(getCompStr(b).toLowerCase())
            ) || []
        );
    }, [
        users.length,
        phonebookContacts.length,
        huntGroups.length,
        sortByLastName,
        disableHuntGroupDisplay,
        filter
    ]);

    /** turn into a separate api call - GET RID OF EXPENSIVE SORTING */
    const allFavouriteContacts: ContactListItem[] = useMemo(() => {
        const hasFavourites = Object.keys(favouriteContacts).find(key => favouriteContacts[key]);

        if (!hasFavourites) {
            return [];
        }

        return allContactsMergedSorted.filter(
            contact => contact?.uuid && favouriteContacts[contact.uuid]
        ) as ContactListItem[];
    }, [favouriteContacts, allContactsMergedSorted]);

    /** turn into an api call */
    const searchResult: {
        favourites: ContactListItem[];
        contacts: ContactListItem[];
    } = useMemo(() => {
        if (!searchQuery)
            return {
                favourites: [],
                contacts: []
            };

        const hasFavourites = Object.keys(favouriteContacts).find(key => favouriteContacts[key]);

        const favourites: ContactListItem[] = [];
        const contacts: ContactListItem[] = [];

        allContactsMergedSorted.forEach(cont => {
            const item = cont as any;
            switch (true) {
                case !item.extension &&
                    item.name?.toLowerCase().includes(searchQuery.toLowerCase()):
                case item.nickname?.toLowerCase().includes(searchQuery.toLowerCase()):
                case (item.first_name || item.last_name) &&
                    `${item.first_name || ''} ${item.last_name || ''}`
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()):
                case item.company_name?.toLowerCase().includes(searchQuery.toLowerCase()):
                case !!item.details?.find(detail =>
                    detail.value.includes(searchQuery.toLowerCase())
                ):
                case item.extension?.toString().includes(searchQuery.toLowerCase()):
                case !!item.prefix && item.name.toLowerCase().includes(searchQuery.toLowerCase()):
                case item.prefix?.toString().includes(searchQuery.toLowerCase()):
                    if (hasFavourites && favouriteContacts[item.uuid]) {
                        favourites.push(item);
                        break;
                    }

                    contacts.push(item);
            }
        });

        if (apiContacts?.result?.contacts && apiContacts.result.contacts.length > 0) {
            const res = new Array(contacts.length + apiContacts.result.contacts.length);

            let x = 0;
            let y = 0;

            let curContact = contacts[x];
            let curApiContact = apiContacts.result.contacts[y];

            while (curContact || curApiContact) {
                if (!curContact) {
                    res[x + y] = curApiContact;
                    y += 1;
                    curApiContact = apiContacts.result.contacts[y];
                    continue;
                }
                if (!curApiContact) {
                    res[x + y] = curContact;
                    x += 1;
                    curContact = contacts[x];
                    continue;
                }

                const comp = getCompStr(curContact)
                    .toLowerCase()
                    .localeCompare(getCompStr(curApiContact).toLowerCase());

                if (comp === 0) {
                    res[x + y] = curContact;
                    res[x + y + 1] = curApiContact;
                    x += 1;
                    y += 1;
                } else if (comp > 0) {
                    res[x + y] = curApiContact;
                    y += 1;
                } else {
                    res[x + y] = curContact;
                    x += 1;
                }

                curContact = contacts[x];
                curApiContact = apiContacts.result.contacts[y];
            }

            return { favourites, contacts: res };
        }

        return { favourites, contacts };
    }, [allContactsMergedSorted, searchQuery, favouriteContacts, apiContacts?.result?.next_key]);

    const currentDataSet: {
        data: ContactListItem[];
        dividers: Map<string, string>;
    } = useMemo(() => {
        const data: ContactListItem[] = [];
        const dividers: Map<string, string> = new Map();

        const showDivider = (contact: ContactListItem): string | undefined => {
            switch (true) {
                case isUser(contact):
                    return getFirstOrLastName({ user: contact as IUser })
                        .substring(0, 1)
                        .toUpperCase();
                case isHuntGroup(contact):
                    return (contact as IHuntGroup).name.substring(0, 1).toUpperCase();
                case isInterTenantclient(contact):
                    return (contact as { name: string }).name.substring(0, 1).toUpperCase();
                case isPhonebookContact(contact):
                    return getFirstOrLastName({ contact: contact as IPhonebookContact })
                        .substring(0, 1)
                        .toUpperCase();
                default:
                    return undefined;
            }
        };

        const favouritesList = searchQuery ? searchResult.favourites : allFavouriteContacts;

        if (favouritesList.length > 0) {
            dividers.set(favouritesList[0].uuid, t('terms.favourite', 2));
            dividers.set(t('terms.favourite', 2), favouritesList[0].uuid);

            favouritesList.forEach(favouriteContact => {
                data.push(favouriteContact);
            });
        }

        const contactList = searchQuery ? searchResult.contacts : allContactsMergedSorted;

        contactList.forEach(contact => {
            if (contact?.uuid && !favouriteContacts[contact.uuid]) {
                const itemLetter = showDivider(contact);

                if (itemLetter && itemLetter !== dividerLetter) {
                    dividerLetter = itemLetter;
                    dividers.set(contact.uuid, dividerLetter);
                    dividers.set(dividerLetter, contact.uuid);
                }

                data.push(contact);
            }
        });

        return {
            data,
            dividers
        };
    }, [
        allContactsMergedSorted,
        allFavouriteContacts,
        searchResult.contacts.length,
        searchResult.favourites.length
    ]);

    return {
        allContacts: currentDataSet.data,
        apiContactsLoading,
        initialLoad,
        dividers: currentDataSet.dividers
    };
};

export type UseContactsProps =
    | {
          searchQuery: string | undefined;
          filter: ContactsFilterType[];
          departmentUserIds?: never;
      }
    | {
          searchQuery: string | undefined;
          filter?: never;
          departmentUserIds: string[];
      };

export const useContacts = ({ searchQuery, filter, departmentUserIds }: UseContactsProps) => {
    const favouriteContacts = useTypedSelector(selectFavouriteContacts);
    const sortByLastName = useTypedSelector(
        state => state.user.settings.phone.settings?.contactsSortByLastName
    );
    const disableHuntGroupDisplay = useTypedSelector(
        state => state.user.settings.phone.settings?.disableHuntGroupDisplay
    );
    const appDisableHuntGroups =
        useTypedSelector(state => state.user.app_hide_hunt_groups) || false;
    const huntGroups = useTypedSelector(selectHuntGroups) || [];
    const users = useTypedSelector(selectAllUsers) || [];
    const phonebookContacts = useTypedSelector(selectAllPhonebookContacts);
    const apiOnlyPhonebookUuids = useTypedSelector(selectApiOnlyPhonebooks);
    const tenants = useTypedSelector(selectInterTenantList);

    const [initialLoad, setInitialLoad] = useState<boolean>(true);

    const getFirstOrLastName = useGetfirstOrLastName();

    const { data: apiContacts, isLoading: apiContactsLoading } = useSearchContactsQuery(
        {
            search_term: searchQuery?.trim() || '',
            phone_books: apiOnlyPhonebookUuids,
            sort_by_last_name: sortByLastName
        },
        {
            skip:
                !searchQuery ||
                searchQuery.length < 1 ||
                searchQuery.trim().length < 1 ||
                apiOnlyPhonebookUuids.length < 1
        }
    );

    useEffect(() => {
        if (users.length > 0) {
            setInitialLoad(false);
        }
    }, [users.length]);

    const getStrForCDI = ({ type, data }: ContactDisplayItem): string => {
        switch (type) {
            case 1:
                return getFirstOrLastName({ user: data });
            case 2:
                return data.name;
            case 3:
                return getFirstOrLastName({ contact: data });
            case 4:
                return data.name;
            default:
                return '';
        }
    };

    const usersSorted: IUser[] = useMemo(() => {
        if (!users) return [];

        if (!sortByLastName) return users;

        /**
         * TODO
         * Update typescript and use toSorted
         */
        return [...users].sort((a, b) =>
            getFirstOrLastName({ user: a }).localeCompare(getFirstOrLastName({ user: b }))
        );
    }, [users.length]);

    const huntGroupsSorted: IHuntGroup[] = useMemo(() => {
        if (!huntGroups || appDisableHuntGroups || disableHuntGroupDisplay) return [];

        return [...huntGroups].sort((a, b) => a.name.localeCompare(b.name));
    }, [huntGroups.length]);

    const localContactsSorted: IPhonebookContact[] = useMemo(() => {
        if (!phonebookContacts) return [];

        return phonebookContacts
            .filter(c => c.first_name || c.last_name || c.company_name)
            .sort((a, b) =>
                getFirstOrLastName({ contact: a }).localeCompare(getFirstOrLastName({ contact: b }))
            );
    }, [phonebookContacts.length]);

    const tenantsSorted: InterTenantClient[] = useMemo(() => {
        if (!tenants) return [];

        return [...tenants].sort((a, b) => a.name.localeCompare(b.name));
    }, [tenants.length]);

    const mainDataSet: ContactDisplayItem[] = useMemo(() => {
        if (!huntGroupsSorted || !localContactsSorted || !tenantsSorted)
            return usersSorted.map(u => ({
                type: 1,
                data: u
            }));

        const resArr: ContactDisplayItem[] = [];

        let userIdx = 0;
        let huntGroupIdx = 0;
        let contactIdx = 0;
        let tenantIdx = 0;

        while (
            userIdx < usersSorted.length ||
            huntGroupIdx < huntGroupsSorted.length ||
            contactIdx < localContactsSorted.length ||
            tenantIdx < tenantsSorted.length
        ) {
            const user = usersSorted[userIdx];
            const userSrt = user ? getFirstOrLastName({ user }) : undefined;

            const hg = huntGroupsSorted[huntGroupIdx];
            const contact = localContactsSorted[contactIdx];
            const tenant = tenantsSorted[tenantIdx];

            if (!user && !hg && !contact && !tenant) break;

            let type: 1 | 2 | 3 | 4 | undefined = user ? 1 : undefined;

            if (hg && (!userSrt || userSrt.localeCompare(hg.name) > 0)) {
                type = 2;
            }

            let compStr: string | undefined = type === 1 ? userSrt : hg?.name;

            if (
                contact &&
                (!compStr || getFirstOrLastName({ contact }).localeCompare(compStr) < 0)
            ) {
                type = 3;
                compStr = getFirstOrLastName({ contact });
            }

            if (tenant && (!compStr || tenant.name.localeCompare(compStr) < 0)) {
                type = 4;
            }

            if (![1, 2, 3, 4].includes(type || 100)) {
                Sentry.captureMessage('Failed to Parse Contact', {
                    tags: {
                        info: `contactListHooks`
                    }
                });
                break;
            }

            switch (type) {
                case 1:
                    resArr.push({
                        type,
                        data: user
                    });
                    userIdx += 1;
                    break;
                case 2:
                    resArr.push({
                        type,
                        data: hg
                    });
                    huntGroupIdx += 1;
                    break;
                case 3:
                    resArr.push({
                        type,
                        data: contact
                    });
                    contactIdx += 1;
                    break;
                case 4:
                    resArr.push({
                        type,
                        data: tenant
                    });
                    tenantIdx += 1;
                    break;
            }
        }

        return resArr;
    }, [
        usersSorted.length,
        huntGroupsSorted.length,
        localContactsSorted.length,
        tenantsSorted.length
    ]);

    const displayData: {
        favourites: ContactDisplayItem[];
        mainDisplay: ContactDisplayItem[];
        outerDisplay: ContactDisplayItem[];
    } = useMemo(() => {
        const favourites: ContactDisplayItem[] = [];
        const mainDisplay: ContactDisplayItem[] = [];
        const outerDisplay: ContactDisplayItem[] = [];

        const testSearch = ({ type, data }: ContactDisplayItem): boolean => {
            if (!searchQuery) return true;
            switch (type) {
                case 1:
                    return (
                        String(data.extension).includes(searchQuery) ||
                        data.nickname.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                case 2:
                    return (
                        String(data.extension_number).includes(searchQuery) ||
                        data.name.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                case 3:
                    return (
                        getFirstOrLastName({ contact: data })
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                        data.details?.some(detail =>
                            detail.value.includes(searchQuery.toLowerCase())
                        ) ||
                        data.company_name?.toLowerCase().includes(searchQuery) ||
                        false
                    );
                case 4:
                    return (
                        data.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        data.prefix.toString().includes(searchQuery) ||
                        false
                    );
                default:
                    return false;
            }
        };

        const testFilters = (type: 1 | 2 | 3 | 4): boolean | undefined => {
            switch (type) {
                case 1:
                    return filter?.includes('Internal Users');
                case 2:
                    return filter?.includes('Hunt Groups');
                case 3:
                    return filter?.includes('Phonebook Contacts');
                case 4:
                    return filter?.includes('Tenants');
                default:
                    return false;
            }
        };

        const pushItem = (item: ContactDisplayItem) => {
            if (
                departmentUserIds !== undefined &&
                ((item.type === 1 && departmentUserIds.includes(item.data.uuid)) || !searchQuery)
            ) {
                if (item.type === 1 && hasFavourites && favouriteContacts[item.data.uuid]) {
                    favourites.push(item);
                }

                return;
            }

            if (hasFavourites && favouriteContacts[item.data.uuid]) {
                favourites.push(item);
                return;
            }

            if (
                departmentUserIds === undefined &&
                (filter.length === 0 || testFilters(item.type))
            ) {
                mainDisplay.push(item);
                return;
            }

            if (searchQuery) {
                outerDisplay.push(item);
            }
        };

        const hasFavourites = Object.keys(favouriteContacts).find(key => favouriteContacts[key]);

        const remoteContacts: IPhonebookContact[] = searchQuery
            ? apiContacts?.result?.contacts || []
            : [];

        let mainIdx = 0;
        let contactIdx = 0;

        while (mainIdx < mainDataSet.length || contactIdx < remoteContacts.length) {
            const mainItem = mainDataSet[mainIdx];
            const contact = remoteContacts[contactIdx];

            if (mainItem) {
                if (searchQuery && !testSearch(mainItem)) {
                    mainIdx += 1;
                    continue;
                }
            } else {
                pushItem({
                    type: 3,
                    data: contact
                });
                contactIdx += 1;
                continue;
            }

            if (!contact) {
                pushItem(mainItem);
                mainIdx += 1;
                continue;
            }

            const contactStr = getFirstOrLastName({ contact });
            const mainStr = getStrForCDI(mainItem).toLowerCase();

            if (mainStr.localeCompare(contactStr) > 0) {
                pushItem({
                    type: 3,
                    data: contact
                });
                contactIdx += 1;
            } else {
                pushItem(mainItem);
                mainIdx += 1;
            }
        }

        return {
            favourites,
            mainDisplay,
            outerDisplay
        };
    }, [
        apiContacts?.result?.contacts?.length,
        mainDataSet.length,
        departmentUserIds,
        favouriteContacts,
        searchQuery,
        filter
    ]);

    return {
        favourites: displayData.favourites,
        mainDisplay: displayData.mainDisplay,
        outerDisplay: displayData.outerDisplay,
        initialLoad,
        apiContactsLoading,
        getStrForCDI
    };
};
