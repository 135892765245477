import {SvgProps} from "../index";

export const IconSquareAndPencil: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M22.7275 6.61426L23.3867 5.91992C23.7207 5.56836 23.7471 5.08496 23.4131 4.75098L23.1846 4.51367C22.8857 4.21485 22.3848 4.25879 22.0596 4.58399L21.374 5.25196L22.7275 6.61426ZM12.3213 15.9658L14.123 15.21L22.0859 7.24707L20.7324 5.90235L12.7695 13.874L11.9785 15.6143C11.8906 15.8164 12.1191 16.0449 12.3213 15.9658ZM8.98145 21.8633H19.2207C20.9082 21.8633 21.9014 20.8789 21.9014 18.998V9.7168L20.1436 11.4658V18.8398C20.1436 19.6836 19.6953 20.1055 19.1064 20.1055H9.0957C8.28711 20.1055 7.84766 19.6836 7.84766 18.8398V9.0752C7.84766 8.23145 8.28711 7.80957 9.0957 7.80957H16.5576L18.3066 6.05176H8.98145C7.08301 6.05176 6.08984 7.03614 6.08984 8.91699V18.998C6.08984 20.8789 7.08301 21.8633 8.98145 21.8633Z"
            fill="black"
        />
    </svg>
)

export default IconSquareAndPencil;
