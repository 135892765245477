import {SvgProps} from "../index";

export const IconText: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.52994 4.87667C4.77653 4.87667 4.16494 5.48321 4.16494 6.24167V8.36501C4.16494 8.9449 3.69484 9.41501 3.11494 9.41501C2.53504 9.41501 2.06494 8.9449 2.06494 8.36501V6.24167C2.06494 4.3168 3.62335 2.77667 5.52994 2.77667H22.4699C24.3948 2.77667 25.9349 4.33508 25.9349 6.24167V8.36501C25.9349 8.9449 25.4648 9.41501 24.8849 9.41501C24.305 9.41501 23.8349 8.9449 23.8349 8.36501V6.24167C23.8349 5.48826 23.2284 4.87667 22.4699 4.87667H15.0499V23.1233H18.5966C19.1765 23.1233 19.6466 23.5934 19.6466 24.1733C19.6466 24.7532 19.1765 25.2233 18.5966 25.2233H9.40327C8.82338 25.2233 8.35327 24.7532 8.35327 24.1733C8.35327 23.5934 8.82338 23.1233 9.40327 23.1233H12.9499V4.87667H5.52994Z"
              fill="black"
        />
    </svg>
)

export default IconText;
