import {SvgProps} from "../index";

export const IconAddCall: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M10.2732 17.6885C12.866 20.29 16.0212 22.2939 18.5964 22.2939C19.7478 22.2939 20.7585 21.8896 21.5671 20.9932C22.0417 20.4658 22.3318 19.8594 22.3318 19.2529C22.3318 18.7871 22.1472 18.3301 21.699 18.0137L19.0007 16.0977C18.5701 15.7988 18.2185 15.6582 17.9021 15.6582C17.489 15.6582 17.1199 15.8867 16.7156 16.291L16.0828 16.9238C15.9773 17.0205 15.8542 17.0645 15.74 17.0645C15.5994 17.0645 15.4675 17.0117 15.3796 16.959C14.8259 16.6602 13.8767 15.8516 12.9978 14.9727C12.1189 14.0938 11.3015 13.1533 11.0115 12.5908C10.9587 12.4941 10.9148 12.3711 10.9148 12.2305C10.9148 12.125 10.95 12.002 11.0466 11.8965L11.6794 11.2549C12.075 10.8418 12.3123 10.4814 12.3123 10.0684C12.3123 9.74316 12.1716 9.3916 11.8728 8.96973L9.96558 6.29785C9.64038 5.84082 9.18335 5.64746 8.68237 5.64746C8.08472 5.64746 7.48706 5.91113 6.96851 6.41211C6.0896 7.23828 5.70288 8.2666 5.70288 9.40039C5.70288 11.9756 7.68042 15.0957 10.2732 17.6885Z" fill="#1C1C1E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3813 5.64746H18.0997V8.59806H15.1489L15.1489 9.87964H18.0997V12.8303H19.3813V9.87964H22.3317V8.59806H19.3813V5.64746Z" fill="#1C1C1E"/>

    </svg>
)

export default IconAddCall;