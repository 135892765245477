import {SvgProps} from "../index";

export const IconContact: React.FC<SvgProps> = ({
                                                         width = 28,
                                                         height = 28,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M14.4792 23.0876C19.4626 23.0876 23.5759 18.9743 23.5759 13.9997C23.5759 9.02509 19.4539 4.9118 14.4705 4.9118C9.49585 4.9118 5.39136 9.02509 5.39136 13.9997C5.39136 18.9743 9.50464 23.0876 14.4792 23.0876ZM14.4705 15.4323C12.7478 15.4235 11.4119 13.9821 11.4119 12.0749C11.3943 10.2819 12.7566 8.78778 14.4705 8.78778C16.1843 8.78778 17.5291 10.2819 17.5291 12.0749C17.5291 13.9821 16.1931 15.4499 14.4705 15.4323ZM14.4705 21.5847C12.5017 21.5847 10.4099 20.7673 9.08276 19.3434C10.0935 17.8317 12.0974 16.9528 14.4705 16.9528C16.8171 16.9528 18.8386 17.8141 19.8582 19.3434C18.531 20.7673 16.4392 21.5847 14.4705 21.5847Z" fill="#020202"/>
    </svg>
)

export default IconContact;
