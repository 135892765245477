/* eslint-disable react/jsx-props-no-spreading */
import {Skeleton, SkeletonProps} from "@figeleton/skeleton";

export const StyledSkeleton: React.FC<SkeletonProps> = ({
                                                            animation = "wave",
                                                            startColor = "var(--secondary-background)",
                                                            endColor = "var(--primary-background)",
                                                            style,
                                                            ...props
                                                        }) => (
    <Skeleton
        animation={animation}
        startColor={startColor}
        endColor={endColor}
        style={{
            position: 'absolute',
            ...style,
        }}
        {...props}
    />
)

export default StyledSkeleton;