import {SvgProps} from "../index";

export const IconSettingsNotificationsFilled: React.FC<SvgProps> = ({
                                                                  width = 28,
                                                                  height = 28,
                                                                  ariaHidden,
                                                                  title,
                                                                  description
                                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M22.5633 16.905L21.3967 14.9683C21.1517 14.5367 20.93 13.72 20.93 13.2417V10.29C20.9286 8.98805 20.5586 7.7131 19.8628 6.61265C19.1671 5.5122 18.1739 4.63124 16.9983 4.07168C16.6983 3.53891 16.2607 3.09651 15.7312 2.79072C15.2017 2.48493 14.5998 2.32697 13.9883 2.33335C12.7167 2.33335 11.5733 3.02168 10.9667 4.10668C8.69168 5.23835 7.11668 7.58335 7.11668 10.29V13.2417C7.11668 13.72 6.89501 14.5367 6.65001 14.9567L5.47168 16.905C5.00501 17.6867 4.90001 18.55 5.19168 19.3433C5.47168 20.125 6.13668 20.7317 7.00001 21.0233C9.26335 21.7933 11.6433 22.1667 14.0233 22.1667C16.4033 22.1667 18.7833 21.7933 21.0467 21.035C21.8634 20.7667 22.4934 20.1483 22.7967 19.3433C23.1 18.5383 23.0183 17.6517 22.5633 16.905ZM17.3017 23.345C17.0563 24.0242 16.6078 24.6114 16.0171 25.0267C15.4264 25.4421 14.7221 25.6655 14 25.6667C13.0783 25.6667 12.1683 25.2933 11.5267 24.6283C11.1533 24.2783 10.8733 23.8117 10.71 23.3333C10.8617 23.3567 11.0133 23.3683 11.1767 23.3917C11.445 23.4267 11.725 23.4617 12.005 23.485C12.67 23.5433 13.3467 23.5783 14.0233 23.5783C14.6883 23.5783 15.3533 23.5433 16.0067 23.485C16.2517 23.4617 16.4967 23.45 16.73 23.415L17.3017 23.345Z"
            fill="black"/>
    </svg>
)

export default IconSettingsNotificationsFilled;
