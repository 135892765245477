import {SvgProps} from "../index";

export const IconBold: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.02669 4.66667C7.70986 4.66667 7.44336 4.93317 7.44336 5.25V12.25H14C16.0902 12.25 17.7917 10.5485 17.7917 8.45834C17.7917 6.36817 16.0902 4.66667 14 4.66667H8.02669ZM19.7825 12.8978C20.7287 11.6677 21.2917 10.128 21.2917 8.45834C21.2917 4.43517 18.0232 1.16667 14 1.16667H8.02669C5.77686 1.16667 3.94336 3.00017 3.94336 5.25V22.75C3.94336 24.9998 5.77686 26.8333 8.02669 26.8333H16.7767C20.7999 26.8333 24.0684 23.5648 24.0684 19.5417C24.0684 16.5898 22.3087 14.0441 19.7825 12.8978ZM7.44336 15.75V22.75C7.44336 23.0668 7.70986 23.3333 8.02669 23.3333H16.7767C18.8669 23.3333 20.5684 21.6318 20.5684 19.5417C20.5684 17.4515 18.8669 15.75 16.7767 15.75H7.44336Z"
              fill="black"
        />
    </svg>
        )

        export default IconBold;
