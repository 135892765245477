import {SvgProps} from "../../index";

export const IconBLFOffline: React.FC<SvgProps> = ({
                                                           width = 10,
                                                           height = 10,
                                                           ariaHidden,
                                                           title,
                                                           description
                                                       }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 10"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M10.0264 5.64844C10.0264 8.13372 8.01165 10.1484 5.52637 10.1484C3.04109 10.1484 1.02637 8.13372 1.02637 5.64844C1.02637 3.16316 3.04109 1.14844 5.52637 1.14844C8.01165 1.14844 10.0264 3.16316 10.0264 5.64844Z"
            stroke="#424242"
        />
        <path
            fillRule="evenodd" clipRule="evenodd"
            d="M5.52636 4.5155L4.25157 3.24072L3.11841 4.37389L4.39319 5.64867L3.11852 6.92334L4.25168 8.05651L5.52635 6.78184L6.80103 8.05651L7.93419 6.92334L6.65952 5.64867L7.9343 4.37389L6.80114 3.24072L5.52636 4.5155Z"
            fill="#424242"
        />
    </svg>
)

export default IconBLFOffline;