import {SvgProps} from "../index";

export const IconBookmark: React.FC<SvgProps> = ({
                                                  width = 28,
                                                  height = 28,
                                                  ariaHidden,
                                                  title,
                                                  description
                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M9.82397 23.8922C10.2986 23.8922 10.6062 23.6461 11.3533 22.9166L14.7458 19.5591C14.781 19.5152 14.8601 19.5152 14.8953 19.5591L18.2966 22.9166C19.0437 23.6461 19.3425 23.8922 19.8259 23.8922C20.5291 23.8922 20.9685 23.4088 20.9685 22.6177V7.27203C20.9685 5.52301 20.0544 4.60016 18.323 4.60016H11.3181C9.58667 4.60016 8.67261 5.52301 8.67261 7.27203V22.6177C8.67261 23.4088 9.11206 23.8922 9.82397 23.8922Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconBookmark;