import {SvgProps} from "../index";

export const IconMuteMicrophone: React.FC<SvgProps> = ({
                                                        width = 28,
                                                        height = 28,
                                                        ariaHidden,
                                                        title,
                                                        description
                                                    }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M14.4893 3.84442C12.8281 3.84442 11.6328 5.00458 11.4658 6.71844V6.99969L17.5654 13.0993C17.583 13.0378 17.583 12.9675 17.583 12.8972V7.13153C17.583 5.18036 16.2734 3.84442 14.4893 3.84442ZM21.2744 20.3415C21.5557 20.614 22.0039 20.614 22.2676 20.3415C22.5312 20.0602 22.54 19.6208 22.2676 19.3483L8.17871 5.27704C7.90625 5.00458 7.45801 4.99579 7.18555 5.27704C6.91309 5.54071 6.91309 5.99774 7.18555 6.26141L21.2744 20.3415ZM7.76562 13.2136C7.76562 16.8522 10.1914 19.3395 13.6719 19.6735V21.4577H10.4463C9.98047 21.4577 9.59375 21.8268 9.59375 22.2927C9.59375 22.7497 9.98047 23.1276 10.4463 23.1276H18.5322C18.998 23.1276 19.3848 22.7497 19.3848 22.2927C19.3848 21.8268 18.998 21.4577 18.5322 21.4577H15.3066V19.6735C16.3701 19.5681 17.3281 19.2692 18.1455 18.7858L16.9414 17.5729C16.2383 17.9245 15.4121 18.1179 14.4893 18.1179C11.4922 18.1179 9.46191 16.1491 9.46191 13.152V11.4821C9.46191 11.0163 9.0752 10.6559 8.60938 10.6559C8.14355 10.6559 7.76562 11.0163 7.76562 11.4821V13.2136ZM19.2529 14.7868L20.5977 16.1227C20.9932 15.279 21.2041 14.2946 21.2041 13.2136V11.4821C21.2041 11.0163 20.8262 10.6559 20.3604 10.6559C19.8945 10.6559 19.5166 11.0163 19.5166 11.4821V13.152C19.5166 13.7497 19.4287 14.2946 19.2529 14.7868ZM14.4893 16.2985C14.8408 16.2985 15.1836 16.237 15.4912 16.1227L11.3779 12.0182V13.0114C11.3779 14.9626 12.7051 16.2985 14.4893 16.2985Z" fill="#1C1C1E"/>
    </svg>
)

export default IconMuteMicrophone;
