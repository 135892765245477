import {SvgProps} from "../index";

export const IconSingle: React.FC<SvgProps> = ({
                                                  width = 28,
                                                  height = 28,
                                                  ariaHidden,
                                                  title,
                                                  description
                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.4966 13.9196C16.4126 13.9196 18.0386 12.2145 18.0386 10.0085C18.0386 7.85516 16.4038 6.20282 14.4966 6.20282C12.5806 6.20282 10.937 7.88153 10.9458 10.0261C10.9458 12.2145 12.5718 13.9196 14.4966 13.9196ZM9.01221 22.0934H19.9634C21.4136 22.0934 21.9146 21.654 21.9146 20.8454C21.9146 18.5866 19.0493 15.4753 14.4878 15.4753C9.93506 15.4753 7.06104 18.5866 7.06104 20.8454C7.06104 21.654 7.56201 22.0934 9.01221 22.0934Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconSingle;
