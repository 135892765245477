import {SvgProps} from "../index";

export const IconDualChatBubbleFill: React.FC<SvgProps> = ({
                                                                 width = 28,
                                                                 height = 28,
                                                                 ariaHidden,
                                                                 title,
                                                                 description
                                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.83691 23.3398C7.20605 23.3398 7.4873 23.1641 7.92676 22.7598L10.4141 20.4746C9.92188 20.0791 9.5 19.4111 9.5 18.0488V11.8613C9.5 9.09277 11.1963 7.43164 13.9648 7.43164H20.293L20.2842 7.15918C20.126 5.41016 18.9658 4.23242 16.874 4.23242H4.65723C2.54785 4.23242 1.2207 5.46289 1.2207 7.60742V16.4141C1.2207 18.5322 2.52148 19.833 4.5957 19.833H6.00195V22.3818C6.00195 22.9619 6.30957 23.3398 6.83691 23.3398ZM21.6465 24.6055C22.1826 24.6055 22.4902 24.2275 22.4902 23.6475V21.0986H23.3955C25.4697 21.0986 26.7705 19.7979 26.7705 17.6797V12.0898C26.7705 9.94531 25.4434 8.71484 23.334 8.71484H14.2285C12.04 8.71484 10.792 9.93652 10.792 12.125V17.6797C10.792 19.8154 12.0488 21.0986 14.2285 21.0986H17.3223L20.5654 24.0254C21.0049 24.4297 21.2773 24.6055 21.6465 24.6055Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconDualChatBubbleFill;
