import {SvgProps} from "../index";

export const IconMuteUnfilled: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
            <path fillRule="evenodd" clipRule="evenodd" d="M6.02332 10.395C6.02332 5.98107 9.60937 2.39502 14.0233 2.39502C18.4256 2.39502 22.0233 5.99273 22.0233 10.395V13.7667C22.0233 14.0023 22.0773 14.3575 22.1831 14.7432C22.2874 15.1235 22.4209 15.4565 22.5387 15.6631L23.8758 17.8839C24.9732 19.7129 24.0964 22.096 22.0737 22.7657C16.8401 24.5139 11.1933 24.5137 5.95976 22.7652L5.95606 22.7639C4.94118 22.4204 4.17342 21.7073 3.8295 20.7785C3.48515 19.8485 3.60425 18.8061 4.1598 17.8828L5.49937 15.658C5.4997 15.6574 5.50003 15.6568 5.50037 15.6563C5.62057 15.4541 5.75675 15.1226 5.86288 14.7402C5.96939 14.3566 6.02332 14.002 6.02332 13.7667V10.395ZM14.0233 4.39502C10.7139 4.39502 8.02332 7.08564 8.02332 10.395V13.7667C8.02332 14.243 7.92558 14.7868 7.79001 15.2752C7.65434 15.7639 7.45849 16.2775 7.21727 16.6821L7.21503 16.6858L5.8735 18.9139C5.87345 18.914 5.87355 18.9138 5.8735 18.9139C5.60087 19.3672 5.59151 19.7774 5.70505 20.084C5.81892 20.3915 6.09482 20.6989 6.59549 20.8689C11.4176 22.4795 16.6194 22.4793 21.4414 20.8682L21.4444 20.8672C22.2437 20.603 22.6028 19.6509 22.1614 18.9138C22.1612 18.9135 22.161 18.9132 22.1608 18.9128L20.8151 16.6778L20.8105 16.6697C20.5793 16.2691 20.388 15.7596 20.2543 15.2722C20.121 14.7859 20.0233 14.2427 20.0233 13.7667V10.395C20.0233 7.0973 17.321 4.39502 14.0233 4.39502Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.934 3.36843C11.4182 2.13272 12.6205 1.26331 14.0233 1.26331C15.4262 1.26331 16.6285 2.13273 17.1127 3.36843C17.2514 3.72234 17.1775 4.12428 16.9219 4.40566C16.6663 4.68703 16.2733 4.79918 15.9078 4.69506C14.676 4.34424 13.3707 4.34424 12.1389 4.69506C11.7733 4.79918 11.3803 4.68703 11.1248 4.40566C10.8692 4.12428 10.7953 3.72234 10.934 3.36843Z" fill="black"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1358 26.1242L10.1347 26.1232C9.10593 25.0928 8.52663 23.6972 8.52335 22.2412L10.5233 22.2367C10.5254 23.1644 10.8945 24.0536 11.55 24.71C12.18 25.34 13.0667 25.7367 14.0233 25.7367C15.9483 25.7367 17.5233 24.1617 17.5233 22.2367H19.5233C19.5233 25.2663 17.0529 27.7367 14.0233 27.7367C12.5134 27.7367 11.1244 27.1128 10.1358 26.1242ZM12.5234 22.2367H10.5233L12.5233 22.2322C12.5233 22.2337 12.5233 22.2352 12.5234 22.2367Z" fill="black"/>

    </svg>
)

export default IconMuteUnfilled;
