import {SvgProps} from "../index";

export const IconPhoneDecline: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 46 46"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <circle cx="23.4158" cy="23.4646" r="22.5" fill="#EA1B4B"/>
        <path d="M23.4053 18.7754C18.9855 18.7853 14.8822 19.6949 12.8354 21.7417C11.9158 22.6613 11.4412 23.7786 11.5006 25.1431C11.5401 25.944 11.7972 26.646 12.2718 27.1305C12.6475 27.4963 13.1617 27.7139 13.7648 27.615L17.4431 26.9921C18.0166 26.9031 18.4121 26.7251 18.6691 26.468C18.9954 26.1417 19.0943 25.6671 19.0943 25.0244L19.1042 24.006C19.1042 23.8478 19.1734 23.7291 19.2624 23.6302C19.3712 23.5116 19.5195 23.4523 19.6381 23.4325C20.3204 23.2743 21.7146 23.1161 23.4152 23.1161C25.106 23.1161 26.5002 23.2347 27.1824 23.4325C27.2912 23.4622 27.4395 23.5215 27.5483 23.6302C27.6373 23.7291 27.6966 23.8478 27.7065 23.9961L27.7164 25.0244C27.7263 25.6671 27.8251 26.1417 28.1514 26.468C28.4085 26.7251 28.804 26.9031 29.3775 26.9921L33.0162 27.6051C33.6391 27.7139 34.1632 27.4865 34.5587 27.0909C35.0333 26.6361 35.3002 25.9341 35.32 25.1233C35.3596 23.7687 34.8454 22.6514 33.9357 21.7417C31.889 19.6949 27.835 18.7754 23.4053 18.7754Z" fill="#FEFEFE"/>
    </svg>
)

export default IconPhoneDecline;
