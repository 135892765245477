import {SvgProps} from "../../index";

export const IconBLFCallswitchAvailable: React.FC<SvgProps> = ({
                                                         width = 50,
                                                         height = 51,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 50 51"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <circle cx="25" cy="25.1841" r="25" fill="url(#paint0_linear_367_10329)"/>
        <path d="M20.319 29.7946C23.5599 33.0465 27.5039 35.5513 30.7228 35.5513C32.162 35.5513 33.4254 35.046 34.4361 33.9254C35.0294 33.2662 35.3919 32.5082 35.3919 31.7501C35.3919 31.1679 35.1612 30.5966 34.6009 30.2011L31.2282 27.8061C30.6898 27.4326 30.2504 27.2568 29.8549 27.2568C29.3386 27.2568 28.8771 27.5425 28.3718 28.0478L27.5808 28.8388C27.449 28.9597 27.2951 29.0146 27.1523 29.0146C26.9765 29.0146 26.8118 28.9487 26.7019 28.8828C26.0098 28.5092 24.8233 27.4985 23.7247 26.3999C22.6261 25.3013 21.6043 24.1258 21.2418 23.4227C21.1759 23.3018 21.121 23.148 21.121 22.9722C21.121 22.8404 21.1649 22.6866 21.2857 22.5548L22.0767 21.7528C22.5711 21.2364 22.8677 20.786 22.8677 20.2697C22.8677 19.8632 22.692 19.4237 22.3184 18.8964L19.9345 15.5566C19.528 14.9853 18.9567 14.7437 18.3305 14.7437C17.5834 14.7437 16.8364 15.0732 16.1882 15.6994C15.0896 16.7321 14.6062 18.0175 14.6062 19.4347C14.6062 22.6536 17.0781 26.5537 20.319 29.7946Z" fill="white"/>
        <circle cx="31.8748" cy="18.3092" r="3.74992" stroke="white" strokeWidth="1.24997" strokeDasharray="1.25 1.25"/>
        <defs>
            <linearGradient id="paint0_linear_367_10329" x1="35.3369" y1="50.184" x2="8.4854" y2="-47.3102" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00BB4B"/>
                <stop offset="1" stopColor="#FFFCBE"/>
            </linearGradient>
        </defs>
    </svg>
)

export default IconBLFCallswitchAvailable;
