import {SvgProps} from "../index";

export const IconDashList: React.FC<SvgProps> = ({
                                                     width = 28,
                                                     height = 28,
                                                     ariaHidden,
                                                     title,
                                                     description
                                                 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.104 7.25552C3.104 6.67562 3.5741 6.20552 4.154 6.20552H4.66212C5.24201 6.20552 5.71212 6.67562 5.71212 7.25552C5.71212 7.83542 5.24201 8.30552 4.66212 8.30552H4.154C3.5741 8.30552 3.104 7.83542 3.104 7.25552ZM10.1472 7.25552C10.1472 6.67562 10.6173 6.20552 11.1972 6.20552H24.9054C25.4853 6.20552 25.9554 6.67562 25.9554 7.25552C25.9554 7.83542 25.4853 8.30552 24.9054 8.30552H11.1972C10.6173 8.30552 10.1472 7.83542 10.1472 7.25552ZM3.104 14.0001C3.104 13.4202 3.5741 12.9501 4.154 12.9501H4.66212C5.24201 12.9501 5.71212 13.4202 5.71212 14.0001C5.71212 14.58 5.24201 15.0501 4.66212 15.0501H4.154C3.5741 15.0501 3.104 14.58 3.104 14.0001ZM10.1472 14.0001C10.1472 13.4202 10.6173 12.9501 11.1972 12.9501H24.9054C25.4853 12.9501 25.9554 13.4202 25.9554 14.0001C25.9554 14.58 25.4853 15.0501 24.9054 15.0501H11.1972C10.6173 15.0501 10.1472 14.58 10.1472 14.0001ZM3.104 20.7446C3.104 20.1647 3.5741 19.6946 4.154 19.6946H4.66212C5.24201 19.6946 5.71212 20.1647 5.71212 20.7446C5.71212 21.3245 5.24201 21.7946 4.66212 21.7946H4.154C3.5741 21.7946 3.104 21.3245 3.104 20.7446ZM24.9054 21.7946L11.1972 21.7946C10.6173 21.7946 10.1472 21.3245 10.1472 20.7446C10.1472 20.1647 10.6173 19.6946 11.1972 19.6946L24.9054 19.6946C25.4853 19.6946 25.9554 20.1647 25.9554 20.7446C25.9554 21.3245 25.4853 21.7946 24.9054 21.7946Z"
              fill="black"
        />
    </svg>
)

export default IconDashList;
