import {SvgProps} from "../index";

export const IconSmallEmoji: React.FC<SvgProps> = ({
                                                    width = 26,
                                                    height = 26,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 26 26"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M20.9132 12.9999C20.9132 16.9916 17.6773 20.2276 13.6856 20.2276C9.69382 20.2276 6.45789 16.9916 6.45789 12.9999C6.45789 9.00816 9.69382 5.77222 13.6856 5.77222C17.6773 5.77222 20.9132 9.00816 20.9132 12.9999Z"
            stroke="#868895" strokeWidth="1.5" strokeLinecap="round"/>
        <ellipse cx="11.65" cy="10.7201" rx="1.01819" ry="1.01819" fill="#868895"/>
        <ellipse cx="15.7211" cy="10.7201" rx="1.01819" ry="1.01819" fill="#868895"/>
        <path
            d="M16.249 14.6526C15.9892 15.1026 15.6156 15.4762 15.1656 15.736C14.7156 15.9958 14.2052 16.1326 13.6856 16.1326C13.166 16.1326 12.6555 15.9958 12.2056 15.736C11.7556 15.4762 11.3819 15.1026 11.1221 14.6526"
            stroke="#868895" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
)

export default IconSmallEmoji;
