import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';
import { SessionState } from 'sip.js';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CallFeedbackTag,
    CdrForm,
    ICall,
    ICallState,
    IDevice,
    IHuntGroup,
    IMailboxMessage,
    InterTenantClient,
    InterTenantUser,
    IPhonebookContact,
    ITelecommunicationsIdentificationDisplay,
    IUser,
    IYeOldeCall,
    LastCalled,
    MailboxDisplay,
    RoomMember,
    TransferringCall
} from '../../../types';
import { stopRingtone } from '../../../helpers';
import {
    DeACKRoomRinger,
    getCallerIds,
    getCallHistory,
    getCallHistoryFiller,
    getCallState,
    getParkingSlots
} from './thunks';
// import { getSpecificUserById } from '../userSlice';
import { authApi } from '../../services/authApi';
import { RoomInvite } from '../../services/sipApi';

export const voicemailAdapter = createEntityAdapter<IMailboxMessage>({
    selectId: message => message.uuid,
    sortComparer: (a, b) => moment(b.message_date).unix() - moment(a.message_date).unix()
});

const initialState: ICallState = {
    callFeedback: {
        enabled: false,
        show: false,
        forms: []
    },
    callHistory: [],
    callHistoryFillerLoading: 'idle',
    callHistoryLoading: 'idle',
    callHistoryWeek: 1,
    call_recording: false,
    callerIds: [],
    callerIdsLoading: 'idle',
    calls: [],
    conferenceInvitations: [],
    feedback_tags: [],
    global_call_recording: false,
    inter_tenant_clients: {},
    last_called: undefined,
    mailbox_gotten: {},
    mailbox_messages: {},
    mailboxes: [],
    parkingSlotsLoading: 'idle',
    usersForGroupCall: []
};

export const callsSlice = createSlice({
    name: 'calls',
    initialState,
    reducers: {
        setActiveMeeting: (state, { payload }: PayloadAction<{ id?: string }>) => ({
            ...state,
            activeMeetingId: payload.id
        }),
        newCall: (state, { payload }: PayloadAction<ICall>) => ({
            ...state,
            calls: [
                ...state?.calls,
                {
                    ...payload,
                    startTime: moment().valueOf(),
                    createdOn: moment().format()
                }
            ]
        }),
        updateCall: (state, { payload }: PayloadAction<Partial<ICall>>) => {
            if (!payload.id) return state;
            const call = state.calls.find(stateCall => stateCall.id === payload.id);

            if (payload.state && payload.state !== SessionState.Initial) {
                stopRingtone(payload.id);
            }

            return {
                ...state,
                calls: call
                    ? state.calls.map(stateCall => {
                          if (stateCall.id === payload.id) {
                              return { ...stateCall, ...payload };
                          }
                          return stateCall;
                      })
                    : state.calls
            };
        },
        updateCallHistoryItem: (state, { payload }: PayloadAction<Partial<IYeOldeCall>>) => {
            if (!payload.uuid) return state;
            const hist = state.callHistory.find(stateHistory => stateHistory.uuid === payload.uuid);

            return {
                ...state,
                callHistory: hist
                    ? state.callHistory.map(stateHistory => {
                          if (stateHistory.uuid === payload.uuid) {
                              return { ...stateHistory, ...payload };
                          }
                          return stateHistory;
                      })
                    : state.callHistory
            };
        },
        updateRoomCall: (state, { payload }: PayloadAction<Partial<ICall>>) => {
            if (!payload.roomId) return state;
            const call = state.calls.find(stateCall => stateCall.roomId === payload.roomId);

            if (payload.state && payload.state !== SessionState.Initial && call) {
                stopRingtone(call.id);
            }

            return {
                ...state,
                calls: call
                    ? state.calls.map(stateCall => {
                          if (stateCall.roomId === payload.roomId) {
                              return { ...stateCall, ...payload };
                          }
                          return stateCall;
                      })
                    : state.calls
            };
        },
        setGroup: (state, { payload }: PayloadAction<ICall>) => {
            stopRingtone(payload.id);

            return {
                ...state,
                calls: state.calls.map(stateCall => {
                    if (stateCall.roomId === payload.roomId) {
                        return {
                            ...stateCall,
                            ...payload,
                            id: uuidv4()
                        };
                    }
                    return stateCall;
                })
            };
        },
        groupMemberJoined: (
            state,
            { payload }: PayloadAction<{ roomId: string; newMember: RoomMember }>
        ) => ({
            ...state,
            calls: state.calls.map(c => {
                if (c.roomId === payload.roomId) {
                    return {
                        ...c,
                        roomMembers: [...(c.roomMembers || []), payload.newMember]
                    };
                }
                return c;
            })
        }),
        groupMemberLeft: (
            state,
            { payload }: PayloadAction<{ roomId: string; userUuid: string }>
        ) => ({
            ...state,
            calls: state.calls.map(c => {
                if (c.roomId === payload.roomId) {
                    return {
                        ...c,
                        roomMembers: c.roomMembers?.map(rm => {
                            if (rm.id === payload.userUuid) {
                                return {
                                    ...rm,
                                    active: false
                                };
                            }

                            return rm;
                        })
                    };
                }
                return c;
            })
        }),
        deactivateGroupRinger: (state, { payload }: PayloadAction<DeACKRoomRinger>) => ({
            ...state,
            calls: state.calls.map(c => {
                if (c.roomId !== payload.roomId || !Array.isArray(c.roomRingingMembers)) return c;

                return {
                    ...c,
                    roomRingingMembers: c.roomRingingMembers.map(rm => {
                        if (
                            (payload.callId && rm.call_id !== payload.callId) ||
                            (payload.ref && rm.ref !== payload.ref)
                        )
                            return rm;

                        return {
                            ...rm,
                            active: false
                        };
                    })
                };
            })
        }),
        removeGroupMember: (
            state,
            { payload }: PayloadAction<{ roomId: string; userUuid: string }>
        ) => ({
            ...state,
            calls: state.calls.map(c => {
                if (c.roomId === payload.roomId) {
                    return {
                        ...c,
                        roomMembers: c.roomMembers?.filter(rm => rm.id !== payload.userUuid)
                    };
                }
                return c;
            })
        }),
        updateCallAudio: (
            state,
            { payload }: PayloadAction<{ id: string; audioTrack: MediaStreamTrack }>
        ) => ({
            ...state,
            calls: state.calls.map(stateCall => {
                if (stateCall.id === payload.id) {
                    return { ...stateCall, audioTrack: payload.audioTrack };
                }
                return stateCall;
            })
        }),
        updateCallGroupMember: (
            state,
            {
                payload
            }: PayloadAction<{
                callId: string;
                memberId: string;
                changes: Record<string, any>;
            }>
        ) => ({
            ...state,
            calls: state.calls.map(stateCall => {
                if (stateCall.id === payload.callId) {
                    return {
                        ...stateCall,
                        roomMembers: stateCall.roomMembers?.map(rm => {
                            if (rm.id === payload.memberId) {
                                return {
                                    ...rm,
                                    ...payload.changes
                                };
                            }
                            return rm;
                        })
                    };
                }
                return stateCall;
            })
        }),
        updateCallVideo: (
            state,
            { payload }: PayloadAction<{ id: string; videoTrack: MediaStreamTrack }>
        ) => ({
            ...state,
            calls: state.calls.map(stateCall => {
                if (stateCall.id === payload.id) {
                    return {
                        ...stateCall,
                        videoTracks: [...(stateCall.videoTracks || []), payload.videoTrack]
                    };
                }
                return stateCall;
            })
        }),
        callTerminating: (state, { payload }: PayloadAction<{ id: string }>) => {
            const call = state.calls.find(allCall => allCall.id === payload.id);
            stopRingtone(payload.id);

            if (
                !call ||
                (call && call.state && call.state === SessionState.Terminating) ||
                call.isKeepAlive
            ) {
                return state;
            }

            return {
                ...state,
                calls: call
                    ? state.calls.map(stateCall => {
                          if (stateCall.id === payload.id) {
                              return {
                                  ...stateCall,
                                  state: SessionState.Terminating,
                                  socket: undefined
                              };
                          }
                          return stateCall;
                      })
                    : state.calls
            };
        },
        callTerminated: (state, { payload }: PayloadAction<{ id: string }>) => {
            const call = state.calls.find(stateCall => stateCall.id === payload.id);
            stopRingtone(payload.id);

            if (!call || (call && call.state && call.state === SessionState.Terminated)) {
                return state;
            }

            if (call.isKeepAlive) {
                return {
                    ...state,
                    calls: call
                        ? state.calls.map(stateCall => {
                              if (stateCall.id === payload.id) {
                                  return {
                                      ...stateCall,
                                      isKeepAlive: false
                                  };
                              }
                              return stateCall;
                          })
                        : state.calls
                };
            }

            const calcCallDuration = () => {
                if (!call.answeredTime) return 0;

                // This is the timeout value used to call this fn - update if it changes
                const terminateTimeoutMs = 1500;

                // call.answeredTime is set when the call is being answered
                // and call terminatedTime is a result of subtracting time of calling this fn minus the timeout val used to call it
                return Math.floor(
                    moment
                        .duration(moment().valueOf() - call.answeredTime)
                        .subtract(terminateTimeoutMs)
                        .asSeconds()
                );
            };

            const callType = () => {
                switch (true) {
                    case !!call.roomId:
                        return 'conference';
                    case !!call.callee && call.callee.length <= 5:
                        return 'internal';
                    case call.isOutbound:
                        return 'outbound';
                    default:
                        return 'inbound';
                }
            };

            const newCall: IYeOldeCall = {
                answered: call.answered || false,
                call_duration: calcCallDuration(),
                call_start_time: call.startTime || 0,
                call_type: callType(),
                created_on: call.createdOn || 'n/a',
                from: !call.isOutbound
                    ? {
                          name: call.callee || '',
                          nickname: call.displayName,
                          number: call.callee || '',
                          uuid: call.interTenant || call.id,
                          type: call.interTenant && 'inter_tenant'
                      }
                    : undefined,
                to: call.isOutbound
                    ? {
                          name: call.callee || '',
                          nickname: call.displayName,
                          number: call.callee || '',
                          uuid: call.interTenant || call.id,
                          type: call.interTenant && 'inter_tenant'
                      }
                    : undefined,
                room_members: call.roomMembers || undefined,
                uuid: call.id,
                getCdr: call.getCdr ? call.cdr : undefined
            };

            return {
                ...state,
                calls: state.calls.filter(stateCall => stateCall.id !== payload.id),
                callHistory: [newCall, ...state.callHistory],
                fullscreenCall: undefined
            };
        },
        setCallToParking: (state, { payload }: PayloadAction<{ id: string }>) => {
            const call = state.calls.find(allCall => allCall.id === payload.id);

            return {
                ...state,
                calls: call
                    ? state.calls.map(stateCall => {
                          if (stateCall.id === payload.id) {
                              return {
                                  ...stateCall,
                                  isParking: true
                              };
                          }
                          return stateCall;
                      })
                    : state.calls
            };
        },
        setBeingTransferred: (
            state,
            { payload }: PayloadAction<{ callId: string; beingTransferred: boolean }>
        ) => ({
            ...state,
            calls: state.calls.map(call => {
                if (call.id === payload.callId) {
                    return {
                        ...call,
                        beingTransferred: payload.beingTransferred
                    };
                }

                return call;
            })
        }),
        setFullscreenCall: (
            state,
            {
                payload
            }: PayloadAction<
                | {
                      callId: string;
                      time: number;
                      user: IUser;
                  }
                | undefined
            >
        ) => ({
            ...state,
            fullscreenCall: payload
                ? {
                      id: payload.callId,
                      time: payload.time,
                      user: payload.user
                  }
                : undefined
        }),
        showShortcodePage: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            showShortcodePage: payload
        }),
        updateDeviceList: (state, { payload }: PayloadAction<IDevice[]>) => ({
            ...state,
            devices: payload
        }),
        addSingleFeedbackForm: (state, { payload }: PayloadAction<CdrForm>) => ({
            ...state,
            callFeedback: {
                ...state.callFeedback,
                forms: [...state.callFeedback.forms, payload]
            }
        }),
        deleteSingleFeedbackForm: (state, { payload: inviteId }: PayloadAction<string>) => ({
            ...state,
            callFeedback: {
                ...state.callFeedback,
                forms: state.callFeedback.forms.filter(form => form.invite_uuid !== inviteId)
            }
        }),
        deleteAllFeedbackForms: state => ({
            ...state,
            callFeedback: {
                ...state.callFeedback,
                forms: []
            }
        }),
        setShowFeedbackForm: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            callFeedback: {
                ...state.callFeedback,
                show: payload
            }
        }),
        addUserForGroupCall: (
            state,
            { payload }: PayloadAction<IUser | IPhonebookContact | IHuntGroup>
        ) => ({
            ...state,
            usersForGroupCall: [...state.usersForGroupCall, payload]
        }),
        removeUserForGroupCall: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            usersForGroupCall: state.usersForGroupCall.filter(u => u.uuid !== payload)
        }),
        clearUserForGroupCall: state => ({
            ...state,
            usersForGroupCall: []
        }),
        addConferenceInvitation: (state, { payload }: PayloadAction<RoomInvite>) => ({
            ...state,
            conferenceInvitations: [...state.conferenceInvitations, payload]
        }),
        removeConferenceInvitation: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            conferenceInvitations: state.conferenceInvitations.filter(i => i.id !== payload)
        }),
        updateCallRecording: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            call_recording: payload
        }),
        setFeedbackTags: (state, { payload }: PayloadAction<CallFeedbackTag[]>) => ({
            ...state,
            feedback_tags: payload
        }),
        setTransferringCall: (state, { payload }: PayloadAction<TransferringCall | undefined>) => ({
            ...state,
            transferring_call: payload
        }),
        setCallHistoryWeek: (state, { payload }: PayloadAction<number>) => ({
            ...state,
            callHistoryWeek: payload
        }),
        setVoicemailGotten: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            mailbox_gotten: {
                ...state.mailbox_gotten,
                [payload]: true
            }
        }),
        addManyVoicemails: (
            state,
            {
                payload
            }: PayloadAction<{
                mailbox_uuid: string;
                voicemails: IMailboxMessage[];
            }>
        ) => {
            const addr = state.mailbox_messages[payload.mailbox_uuid];

            if (!addr) {
                const newBox = voicemailAdapter.getInitialState();
                voicemailAdapter.addMany(newBox, payload.voicemails);

                return {
                    ...state,
                    mailbox_messages: {
                        ...state.mailbox_messages,
                        [payload.mailbox_uuid]: newBox
                    }
                };
            }

            voicemailAdapter.addMany(addr, payload.voicemails);

            return state;
        },
        removeOneVoicemail: (
            state,
            { payload }: PayloadAction<{ mailbox_uuid: string; voicemail: string }>
        ) => {
            const addr = state.mailbox_messages[payload.mailbox_uuid];
            if (!addr) return;
            voicemailAdapter.removeOne(addr, payload.voicemail);
        },
        addOneVoicemail: (
            state,
            { payload }: PayloadAction<{ mailbox_uuid: string; voicemail: IMailboxMessage }>
        ) => {
            const addr = state.mailbox_messages[payload.mailbox_uuid];

            if (!addr) {
                const newBox = voicemailAdapter.getInitialState();
                voicemailAdapter.addOne(newBox, payload.voicemail);

                return {
                    ...state,
                    mailbox_messages: {
                        ...state.mailbox_messages,
                        [payload.mailbox_uuid]: newBox
                    }
                };
            }

            voicemailAdapter.addOne(addr, payload.voicemail);

            return state;
        },
        updateOneVoicemail: (
            state,
            {
                payload
            }: PayloadAction<{
                mailbox_uuid: string;
                id: string;
                changes: Partial<IMailboxMessage>;
            }>
        ) => {
            const addr = state.mailbox_messages[payload.mailbox_uuid];
            if (!addr) return;
            voicemailAdapter.updateOne(addr, payload);
        },
        addVoicemailBoxes: (state, { payload }: PayloadAction<MailboxDisplay[]>) => {
            const newBoxes = Object.fromEntries(
                payload.map(m => [m.uuid, voicemailAdapter.getInitialState()])
            );

            const mailboxes = [...payload].sort((a, b) => {
                if (a.is_primary && !b.is_primary) {
                    return -1;
                }
                if (!a.is_primary && b.is_primary) {
                    return 1;
                }

                return a.name.localeCompare(b.name);
            });

            return {
                ...state,
                mailbox_messages: {
                    ...state.mailbox_messages,
                    ...newBoxes
                },
                mailboxes
            };
        },
        addInterTenantClient: (state, { payload }: PayloadAction<InterTenantClient>) => ({
            ...state,
            inter_tenant_clients: {
                ...state.inter_tenant_clients,
                [payload.uuid]: payload
            }
        }),
        addUsersToTenant: (
            state,
            { payload }: PayloadAction<{ tenantUuid: string; users: InterTenantUser[] }>
        ) => {
            const tenant = state.inter_tenant_clients[payload.tenantUuid];

            if (!tenant) return state;

            return {
                ...state,
                inter_tenant_clients: {
                    ...state.inter_tenant_clients,
                    [tenant.uuid]: {
                        ...tenant,
                        users: payload.users
                    }
                }
            };
        },
        setLastCalledNumber: (state, { payload }: PayloadAction<LastCalled | undefined>) => ({
            ...state,
            last_called: payload
        })
    },
    extraReducers: builder => {
        builder.addCase(getCallHistory.pending, state => ({
            ...state,
            callHistoryLoading: 'pending'
        }));
        builder.addCase(getCallHistory.fulfilled, (state, { payload }) => ({
            ...state,
            callHistoryLoading: 'succeeded',
            callHistory: [...state.callHistory, ...payload]
                .sort((left, right) => right.call_start_time - left.call_start_time)
                .reduce(
                    (allCalls: IYeOldeCall[], call) =>
                        allCalls.some(({ uuid }) => uuid === call.uuid)
                            ? allCalls
                            : [...allCalls, call],
                    []
                )
                .filter(call => call.call_type)
        }));
        builder.addCase(getCallHistoryFiller.pending, state => ({
            ...state,
            callHistoryFillerLoading: 'pending'
        }));
        builder.addCase(getCallHistoryFiller.fulfilled, (state, { payload }) => {
            if (payload.length < 1) {
                return {
                    ...state,
                    callHistoryFillerLoading: 'succeeded'
                };
            }

            const checkH = state.callHistory[0];

            if (!checkH) {
                return {
                    ...state,
                    callHistoryFillerLoading: 'succeeded'
                };
            }

            const dta = payload.filter(
                c => c.uuid !== checkH.uuid && c.call_start_time > checkH.call_start_time
            );

            return {
                ...state,
                callHistoryFillerLoading: 'succeeded',
                callHistory: [...dta, ...state.callHistory]
            };
        });
        builder.addCase(getCallerIds.pending, state => ({
            ...state,
            callerIdsLoading: 'pending'
        }));
        builder.addCase(getCallerIds.fulfilled, (state, { payload }) => {
            const sortCallerIds = (result: ITelecommunicationsIdentificationDisplay[]) => {
                if (!result) return [];

                return [...result]
                    .sort((a, b) => b.cli_name?.localeCompare(a.cli_name))
                    .reduce(
                        (allCallerIDs: ITelecommunicationsIdentificationDisplay[], callerId) =>
                            /^[A-Z]/i.test(callerId.cli_name)
                                ? [callerId, ...allCallerIDs]
                                : [...allCallerIDs, callerId],
                        []
                    );
            };

            return {
                ...state,
                callerIdsLoading: 'succeeded',
                callerIds: payload.result ? sortCallerIds(payload.result) : []
            };
        });
        builder.addCase(getParkingSlots.pending, state => ({
            ...state,
            parkingSlotsLoading: 'pending'
        }));
        builder.addCase(getParkingSlots.fulfilled, (state, { payload }) => ({
            ...state,
            parkingSlotsLoading: 'succeeded',
            parkingSlots: payload.result
        }));
        builder.addMatcher(authApi.endpoints.getUser.matchFulfilled, (_, { payload }) =>
            getCallState(payload.user)
        );
    }
});

export const sip = callsSlice.reducer;
