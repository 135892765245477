import {SvgProps} from "../index";

export const IconCopy: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="-3 -3 31 31"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.13492 10.1008C4.77192 10.4638 4.52769 11.1074 4.52769 12.3056L4.5277 17.7874C4.5277 18.9855 4.77193 19.6291 5.13492 19.9921C5.49792 20.3551 6.14153 20.5994 7.33971 20.5994H10.3929C11.591 20.5994 12.2346 20.3551 12.5976 19.9921C12.9606 19.6291 13.2049 18.9855 13.2049 17.7874L13.2049 12.3056C13.2049 11.1074 12.9606 10.4638 12.5976 10.1008C12.2346 9.73781 11.591 9.49358 10.3928 9.49358H7.3397C6.14153 9.49358 5.49792 9.73781 5.13492 10.1008ZM4.07426 9.04015C4.85619 8.25821 5.99358 7.99358 7.3397 7.99358H10.3928C11.739 7.99358 12.8764 8.25821 13.6583 9.04015C14.4402 9.82208 14.7049 10.9595 14.7049 12.3056L14.7049 17.7874C14.7049 19.1335 14.4402 20.2709 13.6583 21.0528C12.8764 21.8347 11.739 22.0994 10.3929 22.0994H7.33971C5.99359 22.0994 4.8562 21.8347 4.07426 21.0528C3.29233 20.2709 3.0277 19.1335 3.0277 17.7874L3.02769 12.3056C3.02769 10.9595 3.29232 9.82208 4.07426 9.04015Z"
              fill="#292D32"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M11.5997 3.84264C11.2439 4.09187 10.984 4.498 10.8647 5.23305C10.7983 5.64191 10.413 5.91952 10.0041 5.85312C9.59528 5.78671 9.31766 5.40144 9.38407 4.99258C9.54766 3.98528 9.95929 3.16032 10.7392 2.61405C11.4902 2.08797 12.472 1.90063 13.6072 1.90063H16.6603C18.0064 1.90063 19.1438 2.16526 19.9257 2.9472C20.7077 3.72913 20.9723 4.86652 20.9723 6.21264L20.9723 11.6944C20.9723 12.7003 20.8253 13.5815 20.4294 14.2903C20.0169 15.0287 19.3759 15.5064 18.5491 15.7628C18.1535 15.8855 17.7333 15.6642 17.6106 15.2686C17.4879 14.873 17.7092 14.4528 18.1048 14.3301C18.6112 14.1731 18.9179 13.9203 19.1198 13.5588C19.3383 13.1676 19.4723 12.5794 19.4723 11.6944L19.4723 6.21264C19.4723 5.01447 19.2281 4.37086 18.8651 4.00786C18.5021 3.64486 17.8585 3.40063 16.6603 3.40063H13.6072C12.6025 3.40063 11.9844 3.57321 11.5997 3.84264Z"
              fill="#292D32"/>
    </svg>
)

export default IconCopy;