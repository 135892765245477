import React, { lazy, ReactNode, Suspense, useEffect, useMemo, useState } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { logout, updateLoginStep } from '../../redux/slices';
import { AuthContainer } from '../../components';
import { usePolyglot } from '../../context/Polyglot';
import styles from './Auth.module.scss';
import LoadingSpinner from '../../components/loading/LoadingSpinner';
import SchemaChange from '../settings/SettingsPages/Account/atoms/SchemaChange';
import { useAppContext } from '../../context/AppContext/AppContext';
import { isPreview } from '../../helpers';
import UserAgentChecker from '../../components/buttons/UserAgentChecker';

const Users = lazy(() => import('./users/Users'));
const Login = lazy(() => import('./login/Login/Login'));
const Accounts = lazy(() => import('./login/Accounts'));
const PendingApproval = lazy(() => import('./login/PendingApproval'));
const ActivateDetails = lazy(() => import('./login/ActivateDetails'));
const CompanyCode = lazy(() => import('./login/CompanyCode'));
const VerificationCode = lazy(() => import('./login/VerificationCode'));
const Welcome = lazy(() => import('./login/Welcome'));

interface AuthProps {
    setIsLoaded: (val: boolean) => void;
}

export interface CompanyDetailsProps {
    company_code: string;
    company_name: string;
}

export const getCookie = (key: string) =>
    document.cookie.split('; ').reduce((total, currentCookie) => {
        const item = currentCookie.split('=');
        const storedKey = item[0];
        const storedValue = item[1];

        return key === storedKey ? decodeURIComponent(storedValue) : total;
    }, '');

const loginStepSelector = createSelector(
    (state: RootState) => state.auth.loginStep,
    loginStep => {
        let step = loginStep;

        if (!step) {
            step = 'welcome';

            if (getCookie('token')) {
                step = '';
            }
        }

        return step;
    }
);

export const Auth: React.FC<AuthProps> = ({ setIsLoaded }) => {
    const loginStep = useTypedSelector(loginStepSelector);

    const [loginMobileNumber, setLoginMobileNumber] = useState<string>('');
    const [companyDetails, setCompanyDetails] = useState<CompanyDetailsProps>();

    const { appLogoWhite, appLogoColor, appLogoAlt, darkMode, isPulseHd, isYay } =
        useAppContext();

    const isLogin =
        loginStep === 'login' ||
        (loginStep === 'welcome' && window?.location.pathname.includes('auth-azure'));

    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const loginComponent = useMemo((): ReactNode => {
        if (isLogin) {
            return <Login />;
        }

        switch (loginStep) {
            case 'welcome':
                return <Welcome setLoginMobileNumber={setLoginMobileNumber} />;

            case 'verification-code':
                return <VerificationCode loginMobileNumber={loginMobileNumber} />;

            case 'company-code':
                if (!isPulseHd) {
                    return <CompanyCode setCompanyDetails={setCompanyDetails} />;
                }
                return null;

            case 'activate-details':
                if (!isPulseHd && companyDetails) {
                    return <ActivateDetails companyDetails={companyDetails} />;
                }
                return null;

            case 'pending-approval':
                return <PendingApproval />;

            case 'accounts':
                return <Accounts />;

            case 'users':
                return <Users />;

            default:
                return null;
        }
    }, [loginStep]);

    return (
        <AuthContainer>
            <img
                className={styles.logo}
                src={isYay || darkMode ? appLogoWhite : appLogoColor}
                alt={appLogoAlt}
            />
            {loginStep !== 'welcome' && (
                <button
                    className={styles.startOverButton}
                    onClick={() => {
                        if (document.cookie.includes('token')) {
                            dispatch(logout());
                        }

                        dispatch(updateLoginStep('welcome'));
                    }}
                >
                    <p style={{ color: isYay || darkMode ? 'white' : 'black' }}>
                        {t('actions.start_again')}
                    </p>
                </button>
            )}
            {loginStep ? (
                <div className={styles.container}>
                    <Suspense fallback={<LoadingSpinner color='mamba' />}>
                        {loginComponent}
                    </Suspense>
                    <UserAgentChecker />
                </div>
            ) : null}
            {isPreview ? (
                <div className={styles.schemaChange}>
                    <SchemaChange />
                </div>
            ) : null}
        </AuthContainer>
    );
};

export default Auth;
