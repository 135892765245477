import {SvgProps} from "../index";

export const IconContact: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.9996 23.025C18.984 23.025 23.0246 18.9843 23.0246 14C23.0246 9.01561 18.984 4.97498 13.9996 4.97498C9.01524 4.97498 4.97461 9.01561 4.97461 14C4.97461 18.9843 9.01524 23.025 13.9996 23.025ZM16.0366 18.8373H12.4823C12.2812 18.8373 12.1098 18.7721 11.9682 18.6416C11.8266 18.5111 11.7559 18.3452 11.7559 18.1439C11.7559 17.9501 11.8266 17.786 11.9682 17.6518C12.1098 17.5176 12.2812 17.4505 12.4823 17.4505H13.4659V13.7599H12.65C12.4488 13.7599 12.2774 13.6928 12.1359 13.5586C11.9943 13.4244 11.9235 13.2566 11.9235 13.0553C11.9235 12.8615 11.9943 12.6975 12.1359 12.5633C12.2774 12.4291 12.4488 12.362 12.65 12.362H14.2818C14.5277 12.362 14.7177 12.444 14.8518 12.608C14.9859 12.772 15.053 12.992 15.053 13.2678V17.4505H16.0366C16.2377 17.4505 16.4072 17.5176 16.5451 17.6518C16.6829 17.786 16.7519 17.9501 16.7519 18.1439C16.7519 18.3452 16.6829 18.5111 16.5451 18.6416C16.4072 18.7721 16.2377 18.8373 16.0366 18.8373ZM14.7847 10.6285C14.5463 10.8745 14.252 10.9976 13.9018 10.9976C13.5516 10.9976 13.2554 10.8745 13.0133 10.6285C12.7711 10.3824 12.65 10.0842 12.65 9.73379C12.65 9.38337 12.7711 9.08514 13.0133 8.83909C13.2554 8.59305 13.5516 8.47003 13.9018 8.47003C14.252 8.47003 14.5463 8.59305 14.7847 8.83909C15.0232 9.08514 15.1424 9.38337 15.1424 9.73379C15.1424 10.0842 15.0232 10.3824 14.7847 10.6285Z"
              fill="black"
        />
    </svg>
)

export default IconContact;
