import {SvgProps} from "../index";

export const IconMuteFilled: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M14.4715 4.69177C13.1708 4.69177 12.2129 5.58816 11.8526 6.74818C11.4044 6.90637 11.0002 7.11728 10.6574 7.38971L22.6356 19.3854C22.8465 19.1921 22.9519 18.9109 22.9519 18.5769C22.9519 17.6454 22.1083 16.8457 21.3174 16.0899C20.7022 15.4835 20.5528 14.218 20.4386 13.0492C20.3067 9.83279 19.3576 7.56547 17.0903 6.74818C16.7212 5.58816 15.7633 4.69177 14.4715 4.69177ZM22.2577 22.5316C22.5301 22.8128 22.9871 22.8128 23.2507 22.5316C23.5144 22.2591 23.5232 21.8197 23.2507 21.5473L7.27402 5.57937C7.00159 5.30694 6.5534 5.30694 6.28097 5.57937C6.00854 5.84301 6.00854 6.30878 6.28097 6.57242L22.2577 22.5316ZM7.441 19.8073H17.9779L8.78557 10.6061C8.6186 11.3356 8.52193 12.1441 8.49557 13.0492C8.38132 14.218 8.24071 15.4835 7.61676 16.0899C6.82583 16.8457 5.98218 17.6454 5.98218 18.5769C5.98218 19.3151 6.53583 19.8073 7.441 19.8073ZM14.4715 23.4279C16.0269 23.4279 17.1606 22.3119 17.2749 21.0376H11.6593C11.7735 22.3119 12.916 23.4279 14.4715 23.4279Z" fill="#020202"/>
    </svg>
)

export default IconMuteFilled;
