import {SvgProps} from "../index";

export const IconArrowLeft: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.4666 21.2061C18.0349 21.6377 17.3351 21.6377 16.9034 21.2061L11.0086 15.3112C10.5769 14.8796 10.5769 14.1797 11.0086 13.7481L16.9034 7.85322C17.3351 7.42158 18.0349 7.42158 18.4666 7.85322C18.8982 8.28486 18.8982 8.98469 18.4666 9.41633L13.3532 14.5297L18.4666 19.643C18.8982 20.0746 18.8982 20.7744 18.4666 21.2061Z"
              fill="black"
        />
    </svg>
)

export default IconArrowLeft;