import {SvgProps} from "../index";

export const IconRowView: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.31179 7.3017C7.50637 7.3017 6.85345 7.95462 6.85345 8.76004C6.85345 9.56545 7.50637 10.2184 8.31179 10.2184H8.32443C9.12984 10.2184 9.78276 9.56545 9.78276 8.76004C9.78276 7.95462 9.12984 7.3017 8.32443 7.3017H8.31179ZM12.3307 7.88504C11.8935 7.88504 11.5391 8.27679 11.5391 8.76004C11.5391 9.24329 11.8935 9.63504 12.3307 9.63504L20.5537 9.63504C20.9909 9.63504 21.3453 9.24329 21.3453 8.76004C21.3453 8.27679 20.9909 7.88504 20.5537 7.88504L12.3307 7.88504ZM12.3307 13.7184C11.8935 13.7184 11.5391 14.1101 11.5391 14.5934C11.5391 15.0766 11.8935 15.4684 12.3307 15.4684L20.5537 15.4684C20.9909 15.4684 21.3453 15.0766 21.3453 14.5934C21.3453 14.1101 20.9909 13.7184 20.5537 13.7184L12.3307 13.7184ZM11.5391 20.4267C11.5391 19.9435 11.8935 19.5517 12.3307 19.5517L20.5537 19.5517C20.9909 19.5517 21.3453 19.9435 21.3453 20.4267C21.3453 20.91 20.9909 21.3017 20.5537 21.3017L12.3307 21.3017C11.8935 21.3017 11.5391 20.91 11.5391 20.4267ZM6.85345 14.5934C6.85345 13.788 7.50637 13.135 8.31179 13.135H8.32443C9.12984 13.135 9.78276 13.788 9.78276 14.5934C9.78276 15.3988 9.12984 16.0517 8.32443 16.0517H8.31179C7.50637 16.0517 6.85345 15.3988 6.85345 14.5934ZM8.31179 18.9684C7.50637 18.9684 6.85345 19.6213 6.85345 20.4267C6.85345 21.2321 7.50637 21.885 8.31179 21.885H8.32443C9.12984 21.885 9.78276 21.2321 9.78276 20.4267C9.78276 19.6213 9.12984 18.9684 8.32443 18.9684H8.31179Z"
              fill="black"/>
    </svg>
)

export default IconRowView;
