import {SvgProps} from "../index";

export const IconDownload: React.FC<SvgProps> = ({
                                                     width = 28,
                                                     height = 28,
                                                     ariaHidden,
                                                     title,
                                                     description
                                                 }) => (
    <svg
        width={width}
        height={height}
        viewBox="-0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.843 7.89017C15.2572 7.89017 15.593 8.22595 15.593 8.64017L15.593 15.4961L17.4637 13.6254C17.7566 13.3325 18.2314 13.3325 18.5243 13.6254C18.8172 13.9183 18.8172 14.3932 18.5243 14.6861L15.3734 17.837C15.2327 17.9777 15.0419 18.0567 14.843 18.0567C14.6441 18.0567 14.4534 17.9777 14.3127 17.837L11.1617 14.6861C10.8688 14.3932 10.8688 13.9183 11.1617 13.6254C11.4546 13.3325 11.9295 13.3325 12.2224 13.6254L14.093 15.496L14.093 8.64017C14.093 8.22595 14.4288 7.89017 14.843 7.89017ZM7.6333 16.5567C8.04751 16.5567 8.3833 16.8925 8.3833 17.3067V18.7809C8.3833 19.4712 8.94294 20.0309 9.6333 20.0309H20.0529C20.7432 20.0309 21.3029 19.4712 21.3029 18.7809V17.3067C21.3029 16.8925 21.6386 16.5567 22.0529 16.5567C22.4671 16.5567 22.8029 16.8925 22.8029 17.3067V18.7809C22.8029 20.2997 21.5716 21.5309 20.0529 21.5309H9.6333C8.11452 21.5309 6.8833 20.2997 6.8833 18.7809V17.3067C6.8833 16.8925 7.21909 16.5567 7.6333 16.5567Z"
              fill="black"/>

    </svg>
)

export default IconDownload;