import {SvgProps} from "../index";

export const IconPin: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M9.63197 20.9668H11.6974C12.5324 20.9668 12.963 20.5361 12.963 19.7012V7.60742C12.963 6.75488 12.5324 6.35059 11.6974 6.3418H9.63197C8.79701 6.3418 8.36634 6.77246 8.36634 7.60742V19.7012C8.35755 20.5361 8.78822 20.9668 9.63197 20.9668ZM16.3117 20.9668H18.3683C19.2033 20.9668 19.6339 20.5361 19.6339 19.7012V7.60742C19.6339 6.75488 19.2033 6.3418 18.3683 6.3418H16.3117C15.4679 6.3418 15.046 6.77246 15.046 7.60742V19.7012C15.046 20.5361 15.4679 20.9668 16.3117 20.9668Z" fill="#1C1C1E"/>
    </svg>
)

export default IconPin;
