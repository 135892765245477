import {SvgProps} from "../../index";

export const IconBLFAux: React.FC<SvgProps> = ({
                                                   width = 10,
                                                   height = 10,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 10"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M10.4258 5C10.4258 7.76142 8.18721 10 5.42578 10C2.66436 10 0.425781 7.76142 0.425781 5C0.425781 2.23858 2.66436 0 5.42578 0C8.18721 0 10.4258 2.23858 10.4258 5Z"
            fill="#E11F1D"
        />
        <path
            d="M6.39927 6.15843H4.45327L4.13127 7.08943H3.10227L4.85927 2.19643H6.00027L7.75727 7.08943H6.72127L6.39927 6.15843ZM6.13327 5.37443L5.42627 3.33043L4.71927 5.37443H6.13327Z"
            fill="white"
        />
    </svg>
)

export default IconBLFAux;