import {SvgProps} from "../index";

export const IconUncheckedBox: React.FC<SvgProps> = ({
                                                       width = 11,
                                                       height = 11,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 11"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <rect x="1.28125" y="1" width="9" height="9" rx="2.5" stroke="#817B82"/>
    </svg>
)

export default IconUncheckedBox;