import {SvgProps} from "../index";

export const IconArrowUp: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.30859 18.2585C7.87695 17.8269 7.87695 17.1271 8.30859 16.6954L14.2035 10.8006C14.6351 10.3689 15.3349 10.3689 15.7666 10.8006L21.6615 16.6954C22.0931 17.1271 22.0931 17.8269 21.6615 18.2585C21.2298 18.6902 20.53 18.6902 20.0983 18.2585L14.985 13.1452L9.87171 18.2585C9.44007 18.6902 8.74024 18.6902 8.30859 18.2585Z"
              fill="black"
        />
    </svg>
)

export default IconArrowUp;
