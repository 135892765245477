import {SvgProps} from "../index";

export const IconMail: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M7.50195 21.7302H21.6787C23.375 21.7302 24.3594 20.7458 24.3594 18.8649V9.15302C24.3594 7.27216 23.3662 6.28778 21.4678 6.28778H7.29102C5.59473 6.28778 4.61035 7.26337 4.61035 9.15302V18.8649C4.61035 20.7458 5.60352 21.7302 7.50195 21.7302ZM13.4961 14.3122L7.09766 7.98407C7.2207 7.9577 7.36133 7.94012 7.51074 7.94012H21.459C21.6172 7.94012 21.7578 7.9577 21.8896 7.99286L15.5 14.3122C15.1309 14.6725 14.8232 14.8307 14.498 14.8307C14.1729 14.8307 13.8652 14.6638 13.4961 14.3122ZM6.25391 9.27606L11.0352 13.9782L6.25391 18.7067V9.27606ZM17.9609 13.9782L22.7158 9.30243V18.6891L17.9609 13.9782ZM7.51074 20.069C7.35254 20.069 7.20312 20.0515 7.07129 20.0251L12.1074 15.0329L12.582 15.5075C13.2236 16.1315 13.8389 16.3952 14.498 16.3952C15.1484 16.3952 15.7725 16.1315 16.4053 15.5075L16.8887 15.0329L21.916 20.0163C21.7754 20.0515 21.626 20.069 21.459 20.069H7.51074Z"
            fill="black"
        />
    </svg>
)

export default IconMail;