import {SvgProps} from "../../index";

export const IconBLFUnavailable: React.FC<SvgProps> = ({
                                                         width = 10,
                                                         height = 10,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 10"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M10.5264 5C10.5264 7.76142 8.28779 10 5.52637 10C2.76494 10 0.526367 7.76142 0.526367 5C0.526367 2.23858 2.76494 0 5.52637 0C8.28779 0 10.5264 2.23858 10.5264 5Z"
            fill="#EA1B4B"
        />
        <rect
            x="2.92236" y="4.19824" width="5.20801" height="1.60254"
            fill="#FEFEFE"
        />
    </svg>
)

export default IconBLFUnavailable;
