import {SvgProps} from "../index";

export const IconPlus: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.4744 6.71837C14.9576 6.71837 15.3494 7.11012 15.3494 7.59337V13.7184H21.4744C21.9576 13.7184 22.3494 14.1101 22.3494 14.5934C22.3494 15.0766 21.9576 15.4684 21.4744 15.4684H15.3494V21.5934C15.3494 22.0766 14.9576 22.4684 14.4744 22.4684C13.9911 22.4684 13.5994 22.0766 13.5994 21.5934V15.4684H7.47437C6.99112 15.4684 6.59937 15.0766 6.59937 14.5934C6.59937 14.1101 6.99112 13.7184 7.47437 13.7184H13.5994V7.59337C13.5994 7.11012 13.9911 6.71837 14.4744 6.71837Z"
              fill="#0D112B"
        />
    </svg>
)

export default IconPlus;
