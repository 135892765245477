import {SvgProps} from "../index";

export const IconCamera: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.01953 21.8809H21.9629C23.8613 21.8809 24.8633 20.8965 24.8633 19.0156V9.78711C24.8633 7.90625 23.8613 6.92188 21.9629 6.92188H19.625C18.9395 6.92188 18.7197 6.80762 18.2891 6.35938L17.5947 5.6123C17.1377 5.12891 16.6719 4.8916 15.7754 4.8916H12.1631C11.2666 4.8916 10.8008 5.12891 10.3438 5.6123L9.64941 6.35938C9.22754 6.79883 8.99902 6.92188 8.31348 6.92188H6.01953C4.12109 6.92188 3.12793 7.90625 3.12793 9.78711V19.0156C3.12793 20.8965 4.12109 21.8809 6.01953 21.8809ZM13.9912 19.1211C11.3633 19.1211 9.25391 17.0117 9.25391 14.375C9.25391 11.7295 11.3633 9.62012 13.9912 9.62012C16.6279 9.62012 18.7373 11.7295 18.7373 14.375C18.7373 17.0117 16.6279 19.1211 13.9912 19.1211ZM19.2998 11.1406C19.2998 10.5166 19.8359 9.97168 20.4775 9.97168C21.1104 9.97168 21.6465 10.5166 21.6465 11.1406C21.6465 11.791 21.1104 12.3008 20.4775 12.3096C19.8359 12.3096 19.2998 11.7998 19.2998 11.1406ZM13.9912 17.5303C15.7402 17.5303 17.1465 16.124 17.1465 14.375C17.1465 12.6172 15.7402 11.2109 13.9912 11.2109C12.251 11.2109 10.8447 12.6172 10.8447 14.375C10.8447 16.124 12.2598 17.5303 13.9912 17.5303Z"
            fill="black"
        />

    </svg>
)

export default IconCamera;