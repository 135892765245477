import {SvgProps} from "../index";

export const IconPin: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M6.67871 18.9014C6.67871 20.1934 7.46973 20.9668 8.79688 20.9668H19.1855C20.5215 20.9668 21.3125 20.1934 21.3125 18.9014V8.40723C21.3125 7.11523 20.5215 6.3418 19.1855 6.3418H8.79688C7.46973 6.3418 6.67871 7.11523 6.67871 8.40723V18.9014ZM8.42773 18.3916V8.91699C8.42773 8.38965 8.73535 8.07324 9.2627 8.07324H18.7549C19.2822 8.07324 19.5898 8.38965 19.5898 8.91699V18.3916C19.5898 18.9189 19.2822 19.2354 18.7549 19.2354H9.2627C8.73535 19.2354 8.42773 18.9189 8.42773 18.3916Z" fill="#1C1C1E"/>
    </svg>
)

export default IconPin;
