import {SvgProps} from "../index";

export const IconContact: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M5.71188 16.5547C5.02634 16.5547 4.84177 17.0557 5.24606 17.6094L7.35544 20.5625C7.67184 21.0195 8.16403 21.0108 8.48044 20.5625L10.5898 17.6006C10.9853 17.0557 10.792 16.5547 10.124 16.5547H8.82321V10.0772C8.82321 8.61817 9.71091 7.68653 10.9502 7.68653C12.1894 7.68653 13.0947 8.60059 13.0947 10.0772V16.0977C13.0947 18.708 14.7558 20.4395 17.0498 20.4395C19.3877 20.4395 20.9873 18.7168 20.9873 16.0977V9.67286H22.2968C22.9648 9.67286 23.1494 9.17188 22.7627 8.62696L20.6533 5.65626C20.3369 5.21681 19.8447 5.20802 19.5195 5.65626L17.4101 8.61817C17.0146 9.17188 17.1992 9.67286 17.8759 9.67286H19.1767V16.2295C19.1767 17.6885 18.289 18.6201 17.0498 18.6201C15.8105 18.6201 14.9052 17.7061 14.9052 16.2295V10.209C14.9052 7.59864 13.2441 5.87598 10.9502 5.87598C8.61227 5.87598 7.01266 7.58985 7.01266 10.209V16.5547H5.71188Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconContact;
