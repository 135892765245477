import StyledSkeleton from "../StyledSkeleton";
import {usePolyglot} from "../../../context/Polyglot";


export const SingleRowContactSkeleton: React.FC<{phonebookLoader?: boolean, text?: string}> = ({phonebookLoader, text}) => {
    const icon = {
        width: 32,
        height: 32
    }

    const {t} = usePolyglot();

    return (
        <div style={{position: 'relative', minHeight: 55}}>
            <StyledSkeleton data-skeleton-name="Icon" style={{top: 10, left: 26, ...icon}}/>
            <StyledSkeleton data-skeleton-name="Name"
                            style={{top: 15, left: 68, width: 200, height: 20}}/>
            {phonebookLoader ? (
                <p
                    style={{
                        position: 'absolute',
                        top: 17.04638671875,
                        left: 74,
                        width: 'max-content',
                        fontSize: 14,
                    }}
                >
                    {text || t("phrases.loading_phonebooks")}
                </p>
            ) : null}
        </div>
    )
}