import {SvgProps} from "../index";

export const IconSavedMeeting: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M7.25708 14.3466C8.75415 14.3466 10.012 13.0204 10.012 11.3046C10.012 9.62982 8.74048 8.3515 7.25708 8.3515C5.77368 8.3515 4.49536 9.65033 4.5022 11.3183C4.5022 13.0204 5.76685 14.3466 7.25708 14.3466ZM14.9954 14.3466C16.4856 14.3466 17.7502 13.0204 17.7502 11.3046C17.7502 9.62982 16.4788 8.3515 14.9954 8.3515C13.5051 8.3515 12.2336 9.65033 12.2405 11.3183C12.2405 13.0204 13.4983 14.3466 14.9954 14.3466ZM22.72 14.3466C24.2102 14.3466 25.4749 13.0204 25.4749 11.3046C25.4749 9.62982 24.2034 8.3515 22.72 8.3515C21.2366 8.3515 19.9583 9.65033 19.9651 11.3183C19.9651 13.0204 21.2297 14.3466 22.72 14.3466ZM2.99146 20.704H11.5159C12.6438 20.704 13.0334 20.3622 13.0334 19.7402C13.0334 17.9765 10.7981 15.5566 7.25708 15.5566C3.70923 15.5566 1.48071 17.9765 1.48071 19.7402C1.48071 20.3622 1.87036 20.704 2.99146 20.704ZM14.9885 15.5566C13.8469 15.5566 12.842 15.8163 12.0081 16.2197C13.2043 17.1562 13.97 18.4072 13.97 19.7402C13.97 20.1161 13.8879 20.4374 13.7239 20.704H19.2542C20.3752 20.704 20.7649 20.3622 20.7649 19.7402C20.7649 17.9765 18.5364 15.5566 14.9885 15.5566ZM22.72 15.5566C21.5784 15.5566 20.5735 15.8163 19.7395 16.2197C20.9358 17.1494 21.7014 18.4072 21.7014 19.7402C21.7014 20.1161 21.6194 20.4374 21.4622 20.704H26.9788C28.0999 20.704 28.4895 20.3622 28.4895 19.7402C28.4895 17.9765 26.261 15.5566 22.72 15.5566Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconSavedMeeting;