import {SvgProps} from "../../index";

export const IconBgChat: React.FC<SvgProps> = ({
                                                      width = 57,
                                                      height = 50,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 57 50"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <rect x="0.208984" width="56.1456" height="49.9072" rx="5.45698" fill="#EEE1FD"/>
        <path d="M23.1132 23.4117C23.959 23.4117 25.9764 22.5261 27.2382 21.6337C27.3699 21.5361 27.4808 21.5012 27.5918 21.5012C27.6819 21.5082 27.7651 21.5151 27.8413 21.5151C32.8399 21.5082 36.4172 18.768 36.4172 15.0029C36.4172 11.3981 32.833 8.49756 28.3682 8.49756C23.9035 8.49756 20.3262 11.3981 20.3262 15.0029C20.3262 17.248 21.6711 19.2561 23.9451 20.4902C24.0699 20.5669 24.1046 20.6715 24.0422 20.797C23.6401 21.4733 22.9676 22.2263 22.6903 22.5819C22.3783 22.9794 22.5585 23.4117 23.1132 23.4117Z" fill="#020202"/>
        <path d="M21.6417 39.0275C23.1126 39.0275 24.186 38.1871 24.3778 36.9218V36.8944H23.3638L23.3547 36.9127C23.1674 37.6572 22.5142 38.1231 21.6417 38.1231C20.4541 38.1231 19.7141 37.1411 19.7141 35.5789V35.5697C19.7141 34.003 20.4541 33.0209 21.6372 33.0209C22.5051 33.0209 23.1674 33.5462 23.3592 34.3547V34.3684H24.3733L24.3778 34.3456C24.2043 33.0346 23.0897 32.1165 21.6372 32.1165C19.8055 32.1165 18.6681 33.4411 18.6681 35.5697V35.5789C18.6681 37.7029 19.8101 39.0275 21.6417 39.0275ZM25.3388 38.8677H26.33V35.958C26.33 35.1586 26.7822 34.6607 27.5085 34.6607C28.2347 34.6607 28.5773 35.0673 28.5773 35.8895V38.8677H29.564V35.6565C29.564 34.4735 28.9519 33.8111 27.8419 33.8111C27.1202 33.8111 26.6451 34.1309 26.4031 34.6607H26.33V31.9612H25.3388V38.8677ZM31.9911 38.9499C32.6489 38.9499 33.1696 38.6667 33.462 38.1642H33.5396V38.8677H34.5217V35.4738C34.5217 34.4324 33.8182 33.8111 32.5712 33.8111C31.443 33.8111 30.6619 34.3547 30.5431 35.1678L30.5386 35.1997H31.4932L31.4978 35.1815C31.6166 34.8298 31.9774 34.6288 32.5256 34.6288C33.197 34.6288 33.5396 34.9302 33.5396 35.4738V35.9123L32.1967 35.99C31.0136 36.0631 30.3467 36.5792 30.3467 37.4654V37.4745C30.3467 38.3744 31.0456 38.9499 31.9911 38.9499ZM31.3334 37.4334V37.4243C31.3334 36.9766 31.644 36.73 32.3291 36.6888L33.5396 36.6112V37.036C33.5396 37.6755 32.996 38.1597 32.2561 38.1597C31.7216 38.1597 31.3334 37.8902 31.3334 37.4334ZM37.4742 38.8997C37.666 38.8997 37.8487 38.8768 38.0086 38.8494V38.0592C37.8715 38.0729 37.7848 38.0774 37.634 38.0774C37.1453 38.0774 36.9443 37.8582 36.9443 37.3238V34.6882H38.0086V33.9071H36.9443V32.6555H35.9348V33.9071H35.1583V34.6882H35.9348V37.5613C35.9348 38.516 36.3825 38.8997 37.4742 38.8997Z" fill="#424242"/>
    </svg>
        )

        export default IconBgChat;
