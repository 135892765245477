import {SvgProps} from "../index";

export const IconDoubleTick: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M8.46171 18.3447L4.73706 14.6311L6.51852 12.8607L8.46171 14.8023L12.9556 10.3447L14.7371 12.1047L8.46171 18.3447Z"
            fill="black"
        />
        <path
            d="M18.4617 18.3447L14.7371 14.6311L16.5185 12.8607L18.4617 14.8023L22.9556 10.3447L24.7371 12.1047L18.4617 18.3447Z"
            fill="black"
        />
    </svg>
)

export default IconDoubleTick;
