import {SvgProps} from "../index";

export const IconPin: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.31885 6.75729C6.31885 6.20501 6.71631 5.75729 7.20661 5.75729H8.09438C8.58467 5.75729 8.98214 6.20501 8.98214 6.75729V13.2427C8.98214 13.795 8.58467 14.2427 8.09438 14.2427H7.20661C6.71631 14.2427 6.31885 13.795 6.31885 13.2427V6.75729Z"
            fill="black"
        />
        <path
            d="M11.018 6.75729C11.018 6.20501 11.4155 5.75729 11.9058 5.75729H12.7935C13.2838 5.75729 13.6813 6.20501 13.6813 6.75729V13.2427C13.6813 13.795 13.2838 14.2427 12.7935 14.2427H11.9058C11.4155 14.2427 11.018 13.795 11.018 13.2427V6.75729Z"
            fill="black"
        />
    </svg>
)

export default IconPin;
