import {SvgProps} from "../index";

export const IconArrowRight: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM17.5771 17.5127C17.9551 17.5127 18.2363 17.2402 18.2363 16.8623V10.4375C18.2363 10.0596 17.9551 9.78711 17.5771 9.78711C17.2168 9.78711 16.9355 10.0596 16.9355 10.4375V16.8623C16.9355 17.2402 17.2168 17.5127 17.5771 17.5127ZM8.55078 13.6543C8.55078 14.0586 8.83203 14.3223 9.21875 14.3223H12.8838L14.3867 14.2168L12.8047 15.623L12.251 16.1416C12.1191 16.2646 12.0488 16.4229 12.0488 16.625C12.0488 16.9941 12.3125 17.2578 12.6992 17.2578C12.8574 17.2578 13.0508 17.1699 13.1826 17.0469L16.1006 14.1553C16.2412 14.0146 16.3203 13.8477 16.3203 13.6543C16.3203 13.4697 16.2324 13.3027 16.1006 13.1621L13.1738 10.2529C13.0332 10.1035 12.8662 10.042 12.6992 10.042C12.3301 10.042 12.0488 10.3057 12.0488 10.6748C12.0488 10.8594 12.1191 11.0264 12.251 11.1582L12.8047 11.7031L14.3867 13.1006L12.8838 13.0039H9.21875C8.83203 13.0039 8.55078 13.2764 8.55078 13.6543Z" fill="#1C1C1E"/>    </svg>
)

export default IconArrowRight;
