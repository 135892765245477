import StyledSkeleton from "../StyledSkeleton";


export const GridContactsSkeleton = () => (
        <div style={{ position: 'relative' }}>
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 10, left: 22.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 73.653076171875, left: 21.84814453125, width: 55.353515625, height: 11.435791015625 }} />

            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 10, left: 109.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 73.653076171875, left: 108.84814453125, width: 55.353515625, height: 11.435791015625 }} />

            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 10, left: 196.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 73.653076171875, left: 195.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 10, left: 283.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 73.653076171875, left: 282.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 101.669921875, left: 22.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 165.322998046875, left: 21.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 101.669921875, left: 109.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 165.322998046875, left: 108.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 101.669921875, left: 196.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 165.322998046875, left: 195.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 101.669921875, left: 283.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 165.322998046875, left: 282.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 193.340087890625, left: 22.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 256.9931640625, left: 21.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 193.340087890625, left: 109.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 256.9931640625, left: 108.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 193.340087890625, left: 196.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 256.9931640625, left: 195.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 193.340087890625, left: 283.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 256.9931640625, left: 282.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 285.010009765625, left: 22.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 348.6630859375, left: 21.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 285.010009765625, left: 109.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 348.6630859375, left: 108.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 285.010009765625, left: 196.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 348.6630859375, left: 195.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 285.010009765625, left: 283.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 348.6630859375, left: 282.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 376.679931640625, left: 22.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 440.3330078125, left: 21.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 376.679931640625, left: 109.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 440.3330078125, left: 108.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 376.679931640625, left: 196.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 440.3330078125, left: 195.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 376.679931640625, left: 283.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 440.3330078125, left: 282.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 468.35009765625, left: 22.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 532.003173828125, left: 21.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 468.35009765625, left: 109.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 532.003173828125, left: 108.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 468.35009765625, left: 196.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 532.003173828125, left: 195.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 468.35009765625, left: 283.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 532.003173828125, left: 282.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 560.02001953125, left: 22.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 623.673095703125, left: 21.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 560.02001953125, left: 109.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 623.673095703125, left: 108.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 560.02001953125, left: 196.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 623.673095703125, left: 195.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 560.02001953125, left: 283.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 623.673095703125, left: 282.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 651.68994140625, left: 22.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 715.343017578125, left: 21.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 651.68994140625, left: 109.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 715.343017578125, left: 108.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 651.68994140625, left: 196.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 715.343017578125, left: 195.84814453125, width: 55.353515625, height: 11.435791015625 }} />
            <StyledSkeleton data-skeleton-name="User Icon" style={{ top: 651.68994140625, left: 283.1650390625, width: 54.669921875, height: 54.669921875 }} />
            <StyledSkeleton data-skeleton-name="User Name" style={{ top: 715.343017578125, left: 282.84814453125, width: 55.353515625, height: 11.435791015625 }} />
        </div>
    )