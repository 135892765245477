import * as Sentry from '@sentry/react';
import store, {RootState} from "../redux/store";

export class Ringtone {
    private context: AudioContext;

    private readonly freq1: number;

    private readonly freq2: number;

    private readonly outbound: boolean;

    private readonly outputDeviceId: string | undefined;

    private osc1: OscillatorNode | undefined;

    private osc2: OscillatorNode | undefined;

    private gainNode: GainNode | undefined;

    private filter: BiquadFilterNode | undefined;

    private ringerLFOBuffer: AudioBuffer | undefined;

    private ringerLFOSource: AudioBufferSourceNode | null | undefined;

    private volume: number;

    private status: 0 | 1;

    constructor({outbound, outputDeviceId, volume}: { outbound: boolean, outputDeviceId?: string, volume?: number }) {
        this.context = new AudioContext();
        this.status = 0;
        this.freq1 = outbound ? 400 : 440;
        this.freq2 = outbound ? 450 : 480;
        this.outbound = outbound;
        this.outputDeviceId = outputDeviceId;
        this.volume = volume ?? 100;
    }

    private setup() {
        if ((this.context as any).setSinkId) {
            let newSink;

            switch (true) {
                case !!this.outputDeviceId:
                    newSink = this.outputDeviceId || ''
                    break;
                case this.outbound:
                    newSink = (store.getState() as RootState)?.user?.settings?.phone.settings.outputDeviceIdCall;
                    break;
                default:
                    newSink = (store.getState() as RootState)?.user?.settings?.phone.settings.outputDeviceIdRingtone;
            }

            if (newSink !== 'default') {
                (this.context as any).setSinkId(newSink ?? '')
                    .catch((e) => {
                        Sentry.captureMessage('Error with setSinkId in ringtone', {
                            extra: e
                        })
                    })
            }

        }

        this.osc1 = this.context.createOscillator();
        this.osc2 = this.context.createOscillator();
        this.osc1.frequency.value = this.freq1;
        this.osc2.frequency.value = this.freq2;

        this.gainNode = this.context.createGain();
        this.gainNode.gain.value = 0.25;

        this.filter = this.context.createBiquadFilter();
        this.filter.type = "lowpass";

        this.osc1.connect(this.gainNode);
        this.osc2.connect(this.gainNode);

        this.gainNode.connect(this.filter);
        this.filter.connect(this.context.destination);
    }

    private start() {
        this.setup();
        this.osc1?.start(0);
        this.osc2?.start(0);
        this.status = 1;
    }

    private stop() {
        this.osc1?.stop(0);
        this.osc2?.stop(0);
        this.status = 0;
    }

    private createRingerLFO() {
        const channels = 1;
        const {sampleRate} = this.context;
        const frameCount = sampleRate * (this.outbound ? 3 : 6);
        const myArrayBuffer = this.context.createBuffer(channels, frameCount, sampleRate);

        const bufferData = myArrayBuffer.getChannelData(0);

        if (this.outbound) {
            for (let i = 0; i < frameCount; i += 1) {
                if ((i / sampleRate > 0 && i / sampleRate < 0.4) || (i / sampleRate > 0.6 && i / sampleRate < 1.0)) {
                    bufferData[i] = this.volume / 400;
                }
            }
        } else {
            for (let i = 0; i < frameCount; i += 1) {
                if ((i / sampleRate > 0 && i / sampleRate < 2)) {
                    bufferData[i] = this.volume / 300;
                }
            }
        }

        this.ringerLFOBuffer = myArrayBuffer;
    }

    startRinging() {
        this.start();
        // set our gain node to 0, because the LFO is callibrated to this level

        if (this.gainNode) {
            this.gainNode.gain.value = 0;
        }
        this.status = 1;

        this.createRingerLFO();

        this.ringerLFOSource = this.context.createBufferSource();
        if (this.ringerLFOBuffer) {
            this.ringerLFOSource.buffer = this.ringerLFOBuffer;
        }
        this.ringerLFOSource.loop = true;
        // connect the ringerLFOSource to the gain Node audio param

        if (this.gainNode) {
            this.ringerLFOSource.connect(this.gainNode.gain);
        }
        this.ringerLFOSource.start(0);
    }

    stopRinging() {
        this.stop();
        this.ringerLFOSource?.stop(0);
    }

    getStatus(): 0 | 1 {
        return this.status;
    }
}