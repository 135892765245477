import {AppDispatch, AppThunk} from "../../store";
import {LineKey, ProvisionItem, provisionSlice} from "./slice";

export const setLineKeys =
    (lineKeys: LineKey[]): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.setLineKeys(lineKeys));

export const setAllLineKeys =
    (lineKeys: LineKey[]): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.setAllLineKeys(lineKeys));

export const updateOneLineKey =
    (lineKey: LineKey): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.updateOneLineKey(lineKey));

export const deleteOneLineKey =
    (position: number): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.deleteOneLineKey(position));

export const addOnePositionChanged =
    (position: number): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.addOnePositionChanged(position));

export const removeOnePositionChanged =
    (position: number): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.removeOnePositionChanged(position));

export const clearPositionChanged =
    (): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.clearPositionChanged());

export const resetLineKeys =
    (): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.resetLineKeys());

export const setProvisionItem =
    (item?: ProvisionItem): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.setProvisionItem(item));

export const setCurrentHardwarePhone =
    (macAddress: string): AppThunk =>
        (dispatch: AppDispatch) =>
            dispatch(provisionSlice.actions.setSelectedHardwarePhone(macAddress));
