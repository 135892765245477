import {SvgProps} from "../index";

export const IconGroup: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.27441 14.3466C7.77148 14.3466 9.0293 13.0204 9.0293 11.3046C9.0293 9.62982 7.75781 8.3515 6.27441 8.3515C4.79102 8.3515 3.5127 9.65033 3.51953 11.3183C3.51953 13.0204 4.78418 14.3466 6.27441 14.3466ZM14.0127 14.3466C15.5029 14.3466 16.7676 13.0204 16.7676 11.3046C16.7676 9.62982 15.4961 8.3515 14.0127 8.3515C12.5225 8.3515 11.251 9.65033 11.2578 11.3183C11.2578 13.0204 12.5156 14.3466 14.0127 14.3466ZM21.7373 14.3466C23.2275 14.3466 24.4922 13.0204 24.4922 11.3046C24.4922 9.62982 23.2207 8.3515 21.7373 8.3515C20.2539 8.3515 18.9756 9.65033 18.9824 11.3183C18.9824 13.0204 20.2471 14.3466 21.7373 14.3466ZM2.00879 20.704H10.5332C11.6611 20.704 12.0508 20.3622 12.0508 19.7402C12.0508 17.9765 9.81543 15.5566 6.27441 15.5566C2.72656 15.5566 0.498047 17.9765 0.498047 19.7402C0.498047 20.3622 0.887695 20.704 2.00879 20.704ZM14.0059 15.5566C12.8643 15.5566 11.8594 15.8163 11.0254 16.2197C12.2217 17.1562 12.9873 18.4072 12.9873 19.7402C12.9873 20.1161 12.9053 20.4374 12.7412 20.704H18.2715C19.3926 20.704 19.7822 20.3622 19.7822 19.7402C19.7822 17.9765 17.5537 15.5566 14.0059 15.5566ZM21.7373 15.5566C20.5957 15.5566 19.5908 15.8163 18.7568 16.2197C19.9531 17.1494 20.7188 18.4072 20.7188 19.7402C20.7188 20.1161 20.6367 20.4374 20.4795 20.704H25.9961C27.1172 20.704 27.5068 20.3622 27.5068 19.7402C27.5068 17.9765 25.2783 15.5566 21.7373 15.5566Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconGroup;
