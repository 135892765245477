import {SvgProps} from "../index";

export const IconSettingsAudioFilled: React.FC<SvgProps> = ({
                                                          width = 28,
                                                          height = 28,
                                                          ariaHidden,
                                                          title,
                                                          description
                                                      }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M21 19.5417C20.8375 19.5417 20.6782 19.4964 20.54 19.411C20.4018 19.3256 20.2901 19.2033 20.2174 19.058C20.1447 18.9127 20.1139 18.7499 20.1285 18.5881C20.1431 18.4263 20.2025 18.2717 20.3 18.1417C21.192 16.9451 21.6738 15.4925 21.6738 14C21.6738 12.5075 21.192 11.055 20.3 9.85835C20.1608 9.6727 20.101 9.43934 20.1338 9.2096C20.1666 8.97987 20.2894 8.77259 20.475 8.63335C20.6607 8.49411 20.894 8.43432 21.1237 8.46714C21.3535 8.49996 21.5608 8.6227 21.7 8.80835C23.9867 11.865 23.9867 16.135 21.7 19.1917C21.525 19.425 21.2683 19.5417 21 19.5417Z"
            fill="black"/>
        <path
            d="M23.1327 22.4583C22.9702 22.4583 22.8109 22.4131 22.6727 22.3276C22.5345 22.2422 22.4228 22.12 22.3501 21.9746C22.2774 21.8293 22.2467 21.6666 22.2612 21.5047C22.2758 21.3429 22.3352 21.1883 22.4327 21.0583C25.5477 16.905 25.5477 11.095 22.4327 6.94166C22.2935 6.756 22.2337 6.52264 22.2665 6.29291C22.2993 6.06318 22.4221 5.85589 22.6077 5.71666C22.7934 5.57742 23.0267 5.51763 23.2565 5.55045C23.4862 5.58327 23.6935 5.706 23.8327 5.89166C27.4144 10.6633 27.4144 17.3367 23.8327 22.1083C23.6694 22.3417 23.401 22.4583 23.1327 22.4583ZM16.3567 4.41232C15.05 3.68899 13.3817 3.87566 11.6784 4.93732L8.27171 7.07232C8.03837 7.21232 7.77004 7.29399 7.50171 7.29399H5.83337C3.01004 7.29399 1.45837 8.84566 1.45837 11.669V16.3357C1.45837 19.159 3.01004 20.7107 5.83337 20.7107H7.50171C7.77004 20.7107 8.03837 20.7923 8.27171 20.9323L11.6784 23.0673C12.705 23.709 13.7084 24.024 14.6417 24.024C15.2409 24.0293 15.8314 23.8806 16.3567 23.5923C17.6517 22.869 18.375 21.364 18.375 19.3573V8.64732C18.375 6.64066 17.6517 5.13566 16.3567 4.41232Z"
            fill="black"/>
    </svg>
)

export default IconSettingsAudioFilled;
