import {SvgProps} from "../index";

export const IconHamburger: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <rect x="6.48804" y="7.77899" width="16" height="2.5" rx="1.25" fill="#020202"/>
        <rect x="6.48804" y="13.279" width="16" height="2.5" rx="1.25" fill="#020202"/>
        <rect x="6.48804" y="18.779" width="16" height="2.5" rx="1.25" fill="#020202"/>
    </svg>
)

export default IconHamburger;
