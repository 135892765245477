import {SvgProps} from "../index";

export const IconTransfer: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M6.19238 13.9958C6.19238 17.3883 6.96582 20.737 8.77637 22.5124C9.62012 23.3385 10.6836 23.8307 12.0195 23.8131C13.3379 23.7956 14.2783 22.9518 14.0674 21.7126L13.5225 18.5133C13.3467 17.4411 12.8896 17.0456 11.7734 17.0456H11.2197C10.2617 17.0456 10.1035 16.0172 10.1035 13.9958C10.1035 11.9655 10.2617 10.9372 11.2197 10.9372H11.7734C12.8721 10.9372 13.3379 10.568 13.5225 9.46939L14.0674 6.27017C14.2783 5.03092 13.3379 4.19595 12.0195 4.17838C10.6836 4.15201 9.62012 4.6442 8.77637 5.47916C6.96582 7.25455 6.19238 10.6032 6.19238 13.9958ZM14.0938 14.7428H18.6553L20.3252 14.6549L18.6113 16.1667L17.7324 17.0631C17.5918 17.2038 17.5039 17.3971 17.5039 17.6169C17.5039 18.0299 17.8115 18.3288 18.2246 18.3288C18.4355 18.3288 18.6113 18.2497 18.752 18.109L22.2588 14.611C22.4609 14.4176 22.5576 14.1979 22.5576 13.9958C22.5576 13.7936 22.4609 13.5739 22.2588 13.3805L18.752 9.88248C18.6113 9.74185 18.4355 9.66275 18.2246 9.66275C17.8115 9.66275 17.5039 9.96158 17.5039 10.3747C17.5039 10.5856 17.5918 10.779 17.7324 10.9196L18.6113 11.8249L20.3252 13.3278L18.6553 13.2487H14.0938C13.6367 13.2487 13.3379 13.5915 13.3379 13.9958C13.3379 14.3913 13.6367 14.7428 14.0938 14.7428Z" fill="#1C1C1E"/>    </svg>
)

export default IconTransfer;
