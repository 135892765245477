import {SvgProps} from "../index";

export const IconCallMissed: React.FC<SvgProps> = ({
                                                  width = 28,
                                                  height = 28,
                                                  ariaHidden,
                                                  title,
                                                  description
                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 33 32"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M30.3718 21.665C29.3833 20.5041 25.578 16.8265 16.9247 16.5782H15.2731C12.2189 16.6153 9.31401 17.2225 6.64112 18.3001L6.80557 18.2421C5.03579 18.9544 3.52112 19.9576 2.2429 21.21L2.24735 21.2055C2.1069 21.3414 1.97801 21.4846 1.85801 21.6369L1.85179 21.645C0.878457 23.0008 0.280234 24.6918 0.240234 26.5225V26.5324C0.402012 27.8646 1.00379 29.0301 1.88912 29.8955L1.8909 29.8973C2.14246 30.2562 2.54957 30.4864 3.01001 30.4864C3.20201 30.4864 3.38512 30.4465 3.55135 30.3731L3.54246 30.3767C5.12468 29.9553 8.78868 28.9557 9.35846 28.6549C9.47757 28.5824 9.57979 28.5026 9.67401 28.4138L9.67312 28.4147C10.0216 27.919 10.2305 27.2991 10.2305 26.6303C10.2305 26.4391 10.2136 26.2524 10.1807 26.0712L10.1833 26.0902L9.96823 24.5885C9.90868 24.1291 9.92912 23.782 10.0473 23.6669C10.2207 23.5445 10.4251 23.4539 10.6456 23.4086L10.6562 23.4068H10.6953C12.298 22.9491 14.1389 22.6854 16.0393 22.6854C17.9398 22.6854 19.7807 22.9482 21.5282 23.4412L21.3842 23.4068H21.4233C21.6625 23.4602 21.8731 23.5581 22.058 23.6904L22.0527 23.6868C22.1709 23.8073 22.1913 24.1463 22.1318 24.6085L21.9167 26.1101C21.8571 26.5696 21.7585 28.1528 22.7416 28.6748C23.3113 28.9748 26.946 29.9572 28.5576 30.3967C28.7211 30.4619 28.9113 30.5 29.1096 30.5C29.5487 30.5 29.9451 30.3142 30.2287 30.0161L30.3273 29.9155C31.2278 29.0572 31.8331 27.8873 31.9762 26.575L31.978 26.5506C31.9398 24.7109 31.3433 23.0207 30.3558 21.6405L30.3727 21.665H30.3718Z"
            fill="black"
        />
        <path
            d="M5.03585 11.1489C5.03585 11.1606 5.03496 11.1751 5.03496 11.1896C5.03496 12.1158 5.77185 12.8671 6.6803 12.8671C6.69452 12.8671 6.70874 12.8671 6.72207 12.8662H6.7203H6.96118C6.97274 12.8662 6.98696 12.8671 7.00118 12.8671C7.90963 12.8671 8.64652 12.1158 8.64652 11.1896C8.64652 11.1751 8.64652 11.1606 8.64563 11.147V11.1489V7.96158L15.171 14.6144C15.3727 14.8174 15.6207 14.9732 15.8972 15.0639L15.9105 15.0675C16.1221 15.1599 16.3683 15.2134 16.627 15.2134C17.1372 15.2134 17.6003 15.0031 17.9363 14.6642L26.651 5.77933C26.9879 5.43586 27.1959 4.96189 27.1959 4.43717C27.1959 3.38955 26.363 2.53949 25.3345 2.53949C24.8207 2.53949 24.355 2.75155 24.0181 3.09502L16.5727 10.6858L11.2332 5.24193L11.2927 5.18302H14.5025C14.5141 5.18302 14.5283 5.18392 14.5425 5.18392C15.451 5.18392 16.1879 4.43264 16.1879 3.50646C16.1879 3.49196 16.1879 3.47746 16.187 3.46386V3.46567V3.22008C16.187 3.2083 16.1879 3.1938 16.1879 3.1793C16.1879 2.25311 15.451 1.50183 14.5425 1.50183C14.5283 1.50183 14.5141 1.50183 14.5007 1.50274H14.5025H6.71941C6.25274 1.50636 5.82963 1.69214 5.51407 1.99392L5.51496 1.99302C5.2163 2.28121 5.03052 2.68811 5.03052 3.13942C5.03052 3.16842 5.03141 3.19652 5.03318 3.22461V3.22099L5.03585 11.1489Z"
            fill="black"
        />
    </svg>
)

export default IconCallMissed;