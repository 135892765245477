import {SvgProps} from "../index";

export const IconSpeakerWaveUnfilled: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.22632 17.7614H8.71362C8.78394 17.7614 8.85425 17.779 8.90698 17.8317L12.7654 21.277C13.2224 21.6901 13.574 21.8835 14.0398 21.8835C14.6902 21.8835 15.1824 21.3913 15.1824 20.7409V7.32001C15.1824 6.66083 14.6902 6.13348 14.0222 6.13348C13.574 6.13348 13.2576 6.33563 12.7654 6.78387L8.90698 10.2028C8.85425 10.2556 8.78394 10.2731 8.71362 10.2731H6.22632C4.92554 10.2731 4.26636 10.9587 4.26636 12.3298V15.7048C4.26636 17.0847 4.93433 17.7614 6.22632 17.7614ZM21.7126 20.2751C22.1169 20.5212 22.6091 20.4333 22.8904 20.0202C24.033 18.403 24.7009 16.2321 24.7009 13.9997C24.7009 11.7673 24.0417 9.58759 22.8904 7.97919C22.6091 7.5661 22.1169 7.46942 21.7126 7.7243C21.2908 7.98798 21.2292 8.51532 21.5457 8.98114C22.4773 10.3522 23.0134 12.1364 23.0134 13.9997C23.0134 15.8542 22.4685 17.6384 21.5457 19.0182C21.238 19.4841 21.2908 20.0026 21.7126 20.2751ZM6.38452 16.1882C6.09448 16.1882 5.95386 16.0475 5.95386 15.7575V12.2858C5.95386 11.987 6.09448 11.8464 6.38452 11.8464H9.12671C9.36401 11.8464 9.53979 11.8024 9.74194 11.6179L13.24 8.42743C13.2839 8.39227 13.3279 8.36591 13.3806 8.36591C13.4509 8.36591 13.5037 8.40985 13.5037 8.48895V19.5192C13.5037 19.6071 13.4509 19.6511 13.3894 19.6511C13.3367 19.6511 13.2927 19.6335 13.24 19.5895L9.74194 16.4167C9.53979 16.2321 9.36401 16.1882 9.12671 16.1882H6.38452ZM18.1794 17.9196C18.5574 18.1657 19.0496 18.0778 19.322 17.6911C20.0339 16.7419 20.4558 15.3972 20.4558 13.9997C20.4558 12.6022 20.0339 11.2663 19.322 10.3083C19.0496 9.92157 18.5574 9.83368 18.1794 10.0886C17.7488 10.3698 17.6609 10.8884 18.0125 11.3981C18.4958 12.0925 18.7683 13.0241 18.7683 13.9997C18.7683 14.9753 18.4871 15.8981 18.0125 16.6013C17.6697 17.111 17.7488 17.6296 18.1794 17.9196Z"
            fill="black"
        />
    </svg>
)

export default IconSpeakerWaveUnfilled;
