import {SvgProps} from "../../index";

export const IconKDecline: React.FC<SvgProps> = ({
                                                    width = 50,
                                                    height = 50,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 50 50"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <circle cx="25" cy="25" r="25" fill="#EA1B4B"/>
        <path d="M24.8942 28.3839L29.2976 32.7874L32.7874 29.2976L28.3839 24.8942L32.7878 20.4903L29.298 17.0005L24.8942 21.4044L20.4898 17L17 20.4898L21.4044 24.8942L17.0005 29.298L20.4903 32.7878L24.8942 28.3839Z" fill="#FEFEFE"/>
    </svg>
)

export default IconKDecline;
