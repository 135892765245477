import {SvgProps} from "../index";

export const IconUnderline: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.8332 2.45C6.4131 2.45 6.8832 2.9201 6.8832 3.5V12.7559C6.8832 16.691 10.0648 19.8725 13.9999 19.8725C17.935 19.8725 21.1165 16.691 21.1165 12.7559V3.5C21.1165 2.9201 21.5866 2.45 22.1665 2.45C22.7464 2.45 23.2165 2.9201 23.2165 3.5V12.7559C23.2165 17.8508 19.0948 21.9725 13.9999 21.9725C8.90497 21.9725 4.7832 17.8508 4.7832 12.7559V3.5C4.7832 2.9201 5.2533 2.45 5.8332 2.45ZM4.7832 25.5892C4.7832 25.0093 5.2533 24.5392 5.8332 24.5392H22.1665C22.7464 24.5392 23.2165 25.0093 23.2165 25.5892C23.2165 26.1691 22.7464 26.6392 22.1665 26.6392H5.8332C5.2533 26.6392 4.7832 26.1691 4.7832 25.5892Z"
              fill="black"
        />
    </svg>
)

export default IconUnderline;
