import {SvgProps} from "../index";

export const IconSingleTick: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M8.70732 18.3415L4.98267 14.6279L6.76413 12.8575L8.70732 14.7991L13.2012 10.3415L14.9827 12.1015L8.70732 18.3415Z"
            fill="black"
        />
    </svg>
)

export default IconSingleTick;
