import {SvgProps} from "../index";

export const IconSettingsAccountFilled: React.FC<SvgProps> = ({
                                                                  width = 28,
                                                                  height = 28,
                                                                  ariaHidden,
                                                                  title,
                                                                  description
                                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14 2.33334C10.9433 2.33334 8.45829 4.81834 8.45829 7.87501C8.45829 10.8733 10.8033 13.3 13.86 13.405C13.9533 13.3933 14.0466 13.3933 14.1166 13.405H14.1983C15.6325 13.3572 16.992 12.7534 17.9891 11.7214C18.9863 10.6894 19.543 9.31006 19.5416 7.87501C19.5416 4.81834 17.0566 2.33334 14 2.33334ZM19.9266 16.5072C16.6716 14.3372 11.3633 14.3372 8.08495 16.5072C6.60329 17.4988 5.78662 18.8405 5.78662 20.2755C5.78662 21.7105 6.60329 23.0405 8.07329 24.0205C9.70662 25.1172 11.8533 25.6655 14 25.6655C16.1466 25.6655 18.2933 25.1172 19.9266 24.0205C21.3966 23.0288 22.2133 21.6988 22.2133 20.2522C22.2016 18.8172 21.3966 17.4872 19.9266 16.5072Z"
            fill="black"/>
    </svg>
)

export default IconSettingsAccountFilled;
