import {SvgProps} from "../index";

export const IconEar: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M7.93262 18.5439C7.93262 21.1015 9.49707 22.6835 11.9932 22.6835C13.8477 22.6835 15.2275 21.8222 16.0977 20.2314L16.8799 18.8163C18.5938 15.6523 21.7139 15.0283 21.7139 10.6513C21.7139 6.46771 18.9893 3.67279 14.8936 3.67279C10.7363 3.67279 7.93262 6.49408 7.93262 10.704V18.5439ZM10.4902 14.6943V10.4228C10.4902 7.94427 12.2041 6.27435 14.8848 6.27435C17.5215 6.27435 19.1826 7.9267 19.1826 10.4052C19.1826 10.7919 18.8662 11.1083 18.4883 11.1083C18.1016 11.1083 17.7852 10.7919 17.7852 10.4052C17.7852 8.75287 16.6953 7.67181 14.8848 7.67181C13.0215 7.67181 11.8877 8.76166 11.8877 10.4228V11.4423H14.2783C15.7373 11.4423 16.7129 12.33 16.7129 13.6572C16.7129 14.2197 16.4668 14.8964 16.0889 15.371L14.999 16.7597C14.8408 16.9531 14.6387 17.0585 14.4277 17.0585C14.0498 17.0585 13.7334 16.7597 13.7334 16.3818C13.7334 16.206 13.7949 16.0302 13.918 15.8808L14.9199 14.6152C15.1396 14.3515 15.2803 13.9999 15.2803 13.7187C15.2803 13.1913 14.8936 12.831 14.3047 12.831H11.8877V14.6943C11.8877 15.081 11.5801 15.3886 11.1934 15.3886C10.7979 15.3886 10.4902 15.081 10.4902 14.6943Z"
            fill="black"
        />
    </svg>
)

export default IconEar;
