import {SvgProps} from "../index";

export const IconAddContact: React.FC<SvgProps> = ({
                                                         width = 28,
                                                         height = 28,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.4751 13.9211C16.3999 13.9211 18.0171 12.216 18.0171 10.0099C18.0171 7.8566 16.3823 6.20425 14.4751 6.20425C12.5591 6.20425 10.9243 7.88297 10.9243 10.0275C10.9331 12.216 12.5503 13.9211 14.4751 13.9211ZM14.4663 15.4767C13.0337 15.4767 11.7681 15.8019 10.7222 16.3029C11.979 17.3664 12.7876 18.9484 12.7876 20.7062C12.7876 21.1808 12.7261 21.6554 12.603 22.0949H19.9507C21.3921 22.0949 21.8931 21.6554 21.8931 20.8468C21.8931 18.588 19.0278 15.4767 14.4663 15.4767ZM7.00439 25.2414C9.46533 25.2414 11.5396 23.1847 11.5396 20.7062C11.5396 18.2277 9.4917 16.1798 7.00439 16.1798C4.52588 16.1798 2.47803 18.2277 2.47803 20.7062C2.47803 23.1935 4.52588 25.2414 7.00439 25.2414ZM4.13916 20.7062C4.13037 20.3459 4.37646 20.1086 4.73682 20.1086H6.40674V18.4474C6.40674 18.0871 6.64404 17.841 7.00439 17.841C7.37354 17.841 7.61084 18.0871 7.61084 18.4474V20.1086H9.27197C9.63232 20.1086 9.87842 20.3459 9.87842 20.7062C9.87842 21.0753 9.63232 21.3127 9.27197 21.3127H7.61084V22.9826C7.61084 23.3429 7.37354 23.5802 7.00439 23.5802C6.64404 23.5802 6.40674 23.3429 6.40674 22.9826V21.3127H4.73682C4.38525 21.3127 4.13916 21.0753 4.13916 20.7062Z"
            fill="#020202"
        />
    </svg>
)

export default IconAddContact;