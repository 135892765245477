import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
    SmartDiallerApp,
    SmartDiallerRegistration,
    GlobalSettings,
    IPhonebookContact,
    ViewContactType,
    SmartDiallerError, HardwarePhone
} from '../../../types';

export const selectActivePage = (state: RootState) => state.app.active_page;

// export const selectPopupNotifications = (state: RootState) => state.app.notifications.popup;

export const selectDragAndDropData = (state: RootState) => state.app.drag_and_drop;

export const selectSettingsPage = (state: RootState) => state.app.settings_page;

export const selectToasts = (state: RootState) => state.app.toasts;

export const selectProvisioningOpen = (state: RootState) => state.app.provisioning_open;

export const selectFocusOnDepartment = (state: RootState): string | undefined => state.app.focus_on_department;

export const selectViewContact = (state: RootState): ViewContactType | undefined => state.app.viewContact;

export const selectAllUserStatus = (state: RootState): string[] => state.app.status_subscriptions;

export const selectEditPhonebookContact = (state: RootState): IPhonebookContact | undefined => state.app.editPhonebookContact;

export const selectSmartDiallerRegistrations = (state: RootState): SmartDiallerRegistration[] | undefined => state.app.follow_me_registration;

export const selectSmartDiallerApps = (state: RootState): SmartDiallerApp[] | undefined => state.app.follow_me_apps;

export const selectApiOnlyPhonebooks = (state: RootState): string[] => state.app.apiOnlyPhonebookUuids;

export const selectGlobalSettings = (state: RootState): GlobalSettings => state.app.global_settings;

// export const selectGlobalDnd = (state: RootState): boolean => state.app.global_settings.do_not_disturb;

// export const selectSyncPhoneDnd = (state: RootState): boolean => state.app.global_settings.sync_dnd_to_phones;

export const selectFavouriteContacts = (state: RootState): Record<string, boolean> => state.app.global_settings.favourite_contact;

export const selectFavouriteChats = (state: RootState): Record<string, boolean> => state.app.global_settings.pinned_chats;

export const selectAttendedTransferOpt = (state: RootState): boolean => state.app.global_settings.attended_transfer || false;

export const selectGlobalSettingUpdated = (state: RootState): boolean => state.app.global_settings_updated;

export const selectSelectedPhonebooks = (state: RootState): Record<string, boolean> => state.app.global_settings.selected_phonebooks;

export const selectMutedChats = (state: RootState): Record<string, boolean> => state.app.global_settings.muted_chats;

export const selectMarkUnreadChats = (state: RootState): Record<string, boolean> => state.app.global_settings.mark_chat_unread;

export const selectSmartDiallerError = (state: RootState): SmartDiallerError | undefined => state.app.smartDiallerError;

export const selectHasDevices = (state: RootState): boolean => state.app.has_devices || false;

export const selectActivePhonebooks = createSelector([
        selectSelectedPhonebooks
    ],
    (selectedPhonebooks: Record<string, boolean>): string[] => Object.entries(selectedPhonebooks).filter((x) => x[1]).map(([key]) => key)
);

export interface RegisteredHardware extends HardwarePhone {
    call_id: string;
    user_agent: string;
}

export const selectKeypadHardware = createSelector(
    [(state: RootState) => state.app.follow_me_registration, (state: RootState) => state.provision.hardwarePhones],
    (registrations: SmartDiallerRegistration[] | undefined, phones: HardwarePhone[] | undefined): {
        registeredHardware: RegisteredHardware[],
        unregisteredHardware: HardwarePhone[]
    } => {
        if (!registrations || !phones) {
            return {
                registeredHardware: [],
                unregisteredHardware: phones || []
            }
        }

        const registeredHardware: RegisteredHardware[] = []
        const unregisteredHardware: HardwarePhone[] = []

        const registrationMap: Record<string, SmartDiallerRegistration> = Object.fromEntries(registrations.map(r => {
            const mac_address = r.user_agent.split(' ').pop()?.toLowerCase().replace(/:/g, '');

            return [mac_address, r]
        }))

        phones.forEach(p => {
            const register = registrationMap[p.mac_address.toLowerCase()];

            if (register) {
                registeredHardware.push({
                    ...p,
                    call_id: register.call_id,
                    user_agent: register.user_agent
                })
            } else {
                unregisteredHardware.push(p)
            }
        })

        return {
            registeredHardware,
            unregisteredHardware
        }
    }
)
