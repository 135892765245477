import {SvgProps} from "../index";

export const IconSettingsAudio: React.FC<SvgProps> = ({
                                                         width = 28,
                                                         height = 28,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M2.33337 11.6667V16.3333C2.33337 18.6667 3.50004 19.8333 5.83337 19.8333H7.50171C7.93337 19.8333 8.36504 19.9617 8.73837 20.1833L12.145 22.3183C15.085 24.1617 17.5 22.82 17.5 19.355V8.64499C17.5 5.16832 15.085 3.83832 12.145 5.68165L8.73837 7.81665C8.36504 8.03832 7.93337 8.16665 7.50171 8.16665H5.83337C3.50004 8.16665 2.33337 9.33332 2.33337 11.6667Z"
            stroke="#0D112B" strokeWidth="1.5"/>
        <path
            d="M21 9.33332C22.0109 10.6791 22.5575 12.3168 22.5575 14C22.5575 15.6832 22.0109 17.3209 21 18.6667M23.135 6.41666C24.7764 8.60415 25.6637 11.2652 25.6637 14C25.6637 16.7348 24.7764 19.3958 23.135 21.5833"
            stroke="#0D112B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default IconSettingsAudio;
