import {SvgProps} from "../index";

export const IconAttachmentPlay: React.FC<SvgProps> = ({
                                                           width = 28,
                                                           height = 28,
                                                           ariaHidden,
                                                           title,
                                                           description
                                                       }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">Attachment File</title>}
        {description && <desc id="desc">Attachment File Icon</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.11563 3.93398C5.63759 2.41202 7.92615 1.81848 10.9168 1.81848H17.9168C20.9074 1.81848 23.196 2.41202 24.718 3.93398C26.2399 5.45595 26.8335 7.74451 26.8335 10.7351V17.7351C26.8335 20.7258 26.2399 23.0143 24.718 24.5363C23.196 26.0583 20.9074 26.6518 17.9168 26.6518H10.9168C7.92615 26.6518 5.63759 26.0583 4.11563 24.5363C2.59366 23.0143 2.00012 20.7258 2.00012 17.7351V10.7351C2.00012 7.74451 2.59366 5.45595 4.11563 3.93398ZM5.17629 4.99464C4.07325 6.09768 3.50012 7.89245 3.50012 10.7351V17.7351C3.50012 20.5778 4.07325 22.3726 5.17629 23.4757C6.27932 24.5787 8.07409 25.1518 10.9168 25.1518H17.9168C20.7595 25.1518 22.5543 24.5787 23.6573 23.4757C24.7603 22.3726 25.3335 20.5778 25.3335 17.7351V10.7351C25.3335 7.89245 24.7603 6.09768 23.6573 4.99464C22.5543 3.89161 20.7595 3.31848 17.9168 3.31848H10.9168C8.07409 3.31848 6.27932 3.89161 5.17629 4.99464Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.4353 10.8983C12.1145 11.0827 11.7834 11.5544 11.7834 12.5085V15.9618C11.7834 16.9086 12.1136 17.382 12.4364 17.5682C12.7594 17.7546 13.3359 17.804 14.1586 17.3305C14.1584 17.3306 14.1588 17.3304 14.1586 17.3305L17.1459 15.6035C17.9688 15.1297 18.2139 14.6067 18.2139 14.2351C18.2139 13.8636 17.9688 13.3406 17.1459 12.8668L14.1592 11.1401C14.159 11.14 14.1594 11.1402 14.1592 11.1401C13.3346 10.6655 12.7573 10.7132 12.4353 10.8983ZM11.6879 9.5978C12.6345 9.05377 13.8076 9.2068 14.9077 9.84018L14.9088 9.84084L16.4022 10.7042L17.8943 11.5668C17.8941 11.5667 17.8946 11.567 17.8943 11.5668C18.996 12.2014 19.7139 13.1427 19.7139 14.2351C19.7139 15.3276 18.9967 16.2686 17.8949 16.9031C17.8947 16.9032 17.8951 16.903 17.8949 16.9031L14.9077 18.6301C13.8057 19.2646 12.6324 19.4131 11.6867 18.8675C10.7408 18.3216 10.2834 17.2317 10.2834 15.9618V12.5085C10.2834 11.2342 10.7399 10.1426 11.6879 9.5978Z"
              fill="black"/>

    </svg>
)

export default IconAttachmentPlay;
