import {SvgProps} from "../index";

export const IconSettingsChat: React.FC<SvgProps> = ({
                                                            width = 28,
                                                            height = 28,
                                                            ariaHidden,
                                                            title,
                                                            description
                                                        }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M9.91671 22.1667H9.33337C4.66671 22.1667 2.33337 21 2.33337 15.1667V9.33334C2.33337 4.66668 4.66671 2.33334 9.33337 2.33334H18.6667C23.3334 2.33334 25.6667 4.66668 25.6667 9.33334V15.1667C25.6667 19.8333 23.3334 22.1667 18.6667 22.1667H18.0834C17.7217 22.1667 17.3717 22.3417 17.15 22.6333L15.4 24.9667C14.63 25.9933 13.37 25.9933 12.6 24.9667L10.85 22.6333C10.6634 22.3767 10.2317 22.1667 9.91671 22.1667Z"
            stroke="#0D112B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.662 12.8333H18.6737M13.9942 12.8333H14.0058M9.32751 12.8333H9.33685" stroke="#0D112B"
              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default IconSettingsChat;
