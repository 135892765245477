import React from "react";
import styles from "./ThemeSelector.module.scss";
import {CallSwitch, PulseHD, Yay} from "./Themes";

export const SchemaChange: React.FC = () => {
    const schema = localStorage.previewTheme;

    const availableThemes = [
        {
            value: "yay",
            title: "Yay",
            preview: <Yay/>
        },
        {
            value: "callswitch",
            title: "Callswitch",
            preview: <CallSwitch/>
        },
        {
            value: "pulsehd",
            title: "PulseHD",
            preview: <PulseHD/>
        },
    ];

    return (
        <div className={styles.selector_container}>
            {availableThemes.map(theme => {
                const {value, title, preview} = theme;

                return (
                    <label
                        className={styles.label}
                        data-is-active={value === schema}
                        key={value}
                    >
                        <button
                            className={styles.selector}
                            onClick={() => {
                                localStorage.setItem("previewTheme", value);
                                (window as any).location.reload();
                            }}
                        >
                            {preview}
                        </button>
                        <p>
                            {title}
                        </p>
                    </label>
                )
            })}
        </div>
    )
}

export default SchemaChange;
