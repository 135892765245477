import {SvgProps} from "../index";

export const IconMinus: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.4744 13.7184C21.9576 13.7184 22.3494 14.1101 22.3494 14.5934C22.3494 15.0766 21.9576 15.4684 21.4744 15.4684C13.0191 15.4684 16.4851 15.4684 7.47437 15.4684C6.99112 15.4684 6.59937 15.0766 6.59937 14.5934C6.59937 14.1101 6.99112 13.7184 7.47437 13.7184C15.5521 13.7184 12.9068 13.7184 21.4744 13.7184Z"
              fill="#0D112B"
        />
    </svg>
)

export default IconMinus;
