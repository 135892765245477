import React, { useEffect, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import {
    resetChatMessagesSearch,
    selectChatEntities,
    selectChatList,
    selectCurrentUserId,
    selectFavouriteChats,
    selectFiltersByPage,
    selectLastUpdatedChatId,
    selectMessageSearchResult,
    selectMutedChats,
    selectThreadsList
} from '../../../redux/slices';
import { useSearchChatMessagesQuery } from '../../../redux/services/chatApi';
import { focusNext } from '../../../helpers';
import { ChatDataType, ChatListPageType } from './ChatSidebar';
import { useAllContacts } from '../Contact/ContactListHooks';
import { usePolyglot } from '../../../context/Polyglot';
import { ChatType, IChat } from '../../../types';

interface UseChatDataSetProps {
    searchQueryDebounced: string;
    page: ChatListPageType;
}

export const useChatDataSet = ({ searchQueryDebounced, page }: UseChatDataSetProps) => {
    const messageSearchResults = useTypedSelector(state =>
        selectMessageSearchResult(state, 'global')
    );
    const filter = useTypedSelector(state => selectFiltersByPage(state, 'chatList'));
    const chatDictionary = useTypedSelector(selectChatEntities);
    const userUuid = useTypedSelector(selectCurrentUserId);

    const { t } = usePolyglot();

    const { allContacts } = useAllContacts({
        searchQuery: searchQueryDebounced,
        filter: ['Internal Users']
    });

    const { chats, threads } = useAllChats({
        searchQuery: searchQueryDebounced,
        filter
    });
    const { isSearching } = useMessageSearch({
        searchQuery: searchQueryDebounced,
        chat_type: undefined
    });

    const dataSet = useMemo(() => {
        const data: ChatDataType[] = [
            {
                divider: 'message',
                message: page === 'chats' ? t('terms.chat', 2) : t('terms.thread', 2)
            }
        ];

        // If no chats when on chats or threads on thread show empty placeholder
        if ((page === 'chats' && chats?.length < 1) || (page === 'threads' && threads.length < 1)) {
            data.push({
                divider: 'empty',
                message:
                    searchQueryDebounced || filter.length > 0
                        ? t('phrases.no_results_match_your_search')
                        : t('phrases.no_chats')
            });
        }

        // If user is searching show messages section
        if (searchQueryDebounced) {
            data.push({ divider: 'message', message: t('terms.contact', 2) });
            const filteredContacts = (allContacts as any[]).filter(
                contact => !chats.some(chat => chat.uuid.includes(contact.uuid)) && !contact.members
            );

            if (allContacts && filteredContacts.length > 0) {
                data.push(...filteredContacts);
            } else {
                data.push({
                    divider: 'empty',
                    message: t('phrases.no_results_match_your_search')
                });
            }

            data.push({ divider: 'message', message: t('terms.message', 2) });

            if (isSearching) {
                data.push({ divider: 'loader' });
            }

            if (messageSearchResults && messageSearchResults.length > 0) {
                const filteredResults = messageSearchResults.filter(searchResult => {
                    const chatId =
                        searchResult.to && userUuid !== searchResult.to
                            ? searchResult.to
                            : searchResult.from;
                    return !!chatDictionary[chatId];
                });
                data.push(...filteredResults);
            } else if (!isSearching) {
                data.push({
                    divider: 'empty',
                    message: t('phrases.no_results_match_your_search')
                });
            }
        }

        return data;
    }, [messageSearchResults?.length, allContacts.length, page, isSearching, filter]);

    return {
        dataSet,
        chats,
        threads
    };
};

export const useAllChats = ({ searchQuery, filter }: { searchQuery: string; filter: string[] }) => {
    const chatsList = useTypedSelector(selectChatList);
    const threadsList = useTypedSelector(selectThreadsList);
    const mutedChats = useTypedSelector(selectMutedChats);
    const favouriteChats = useTypedSelector(selectFavouriteChats);
    const lastUpdatedChatId = useTypedSelector(selectLastUpdatedChatId);

    const filterThrough = allQuery => {
        let data = allQuery;
        filter?.forEach((f: string) => {
            data = data.filter(c => {
                if (f === 'Unread' && c.unread_count !== 0) {
                    return true;
                }
                if (f === 'Read' && c.unread_count < 1) {
                    return true;
                }
                if (f === 'Pinned' && favouriteChats?.[c.uuid]) {
                    return true;
                }
                if (f === 'Muted' && mutedChats[c.uuid]) {
                    return true;
                }
                if (c.integration) {
                    if (f === 'SMS' && c.integration.channel === 'SMS') {
                        return true;
                    }
                }
                if (f === 'Groups' && c.type === 'channel') {
                    return true;
                }
                return f === 'Individual' && c.type === 'user';
            });
        });
        return data;
    };

    const baseChats = useMemo(
        () =>
            filterThrough(chatsList).filter(
                (chat: IChat) =>
                    chat.display_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    (chat.integration?.number && chat.integration.number.includes(searchQuery))
            ),
        [filter.length, chatsList.length, lastUpdatedChatId, searchQuery]
    );

    const threadsChats = useMemo(
        () =>
            filterThrough(threadsList).filter(t => {
                const baseChat = chatsList.find((c: any) => c.uuid === t.source.source_uuid);
                // const messages = t.messages.ids.map(id => t.messages.entities[id]).filter(message => message.from !== "hide");
                return (
                    baseChat &&
                    // (messages.some(message => message?.content?.toLowerCase().includes(searchQuery?.toLowerCase())) ||
                    (baseChat.display_name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
                        (baseChat.integration?.number &&
                            baseChat.integration.number.includes(searchQuery)))
                    // && messages
                    // )
                );
            }),
        [filter.length, threadsList.length, lastUpdatedChatId, searchQuery]
    );

    return {
        chats: baseChats,
        threads: threadsChats
    };
};

export const useMessageSearch = ({
    searchQuery,
    chatId,
    chat_type
}: {
    searchQuery: string;
    chatId?: string;
    chat_type: ChatType | undefined;
}) => {
    const userUuid = useTypedSelector(selectCurrentUserId);
    const chatEnabled = useTypedSelector(state => state.user.chat_enabled);

    const dispatch = useTypedDispatch();

    const { isFetching: isSearching } = useSearchChatMessagesQuery(
        {
            voip_user_uuid: userUuid,
            search_term: searchQuery,
            chat_key: chatId || 'global',
            target_type: chat_type
        },
        {
            skip: !searchQuery || !chatEnabled,
            refetchOnMountOrArgChange: true
        }
    );

    useEffect(() => {
        if (!searchQuery) {
            dispatch(resetChatMessagesSearch(chatId || 'global'));
        }
    }, [searchQuery]);

    useEffect(
        () => () => {
            dispatch(resetChatMessagesSearch(chatId || 'global'));
        },
        []
    );

    return {
        isSearching
    };
};

export const useChatKeydown = () => {
    const handleArrowY = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        const target = e.target as HTMLElement;

        if (
            (target?.parentNode as HTMLElement).className === 'recent-row__options' &&
            target.nextSibling?.firstChild?.nodeName === 'UL'
        ) {
            focusNext({
                container: target.parentNode as HTMLElement,
                before
            });
            return;
        }

        if (target.parentNode?.nodeName === 'UL') {
            focusNext({
                container: target.parentNode?.parentNode?.parentNode as HTMLElement,
                before
            });
            return;
        }

        let navElement: any = before
            ? e.currentTarget.previousSibling
            : e.currentTarget.nextSibling;

        if (!navElement) return;

        if ((navElement?.firstChild as HTMLElement).className === 'list-divider') {
            navElement = before ? navElement?.previousSibling : navElement?.nextSibling;
        }

        if (!navElement) return;

        if (navElement) {
            focusNext({
                container: navElement
            });
        }
    };

    const handleArrowX = (e: React.KeyboardEvent<HTMLElement>, before?: boolean) => {
        focusNext({
            container: e.currentTarget,
            before
        });
    };

    const ACTIONS = {
        ArrowRight: e => handleArrowX(e),
        ArrowLeft: e => handleArrowX(e, true),
        ArrowDown: e => handleArrowY(e),
        ArrowUp: e => handleArrowY(e, true),
        Enter: e => e.target.click(),
        Space: e => e.target.click()
    };

    return e => {
        const handler = ACTIONS[e.key];
        if (handler) {
            e.preventDefault();
            handler(e);
        }
    };
};
