import {SvgProps} from "../index";

export const IconMail: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M24.2744 12.5996H23.0439C22.5254 8.14355 18.6582 4.56641 14 4.56641C11.4248 4.56641 9.08688 5.69141 7.46969 7.44922C7.03903 7.87109 7.05661 8.43359 7.40817 8.75879C7.76852 9.09277 8.28708 9.09277 8.72653 8.68848C10.0361 7.27344 11.917 6.38574 14 6.38574C17.709 6.38574 20.706 9.10156 21.207 12.5996H19.8623C19.1855 12.5996 19.0009 13.1006 19.3965 13.6455L21.497 16.6162C21.8222 17.0557 22.3144 17.0645 22.6308 16.6162L24.7402 13.6543C25.1357 13.1006 24.9599 12.5996 24.2744 12.5996ZM3.72555 14.7178H4.96481C5.48337 19.1738 9.35055 22.751 14 22.751C16.5927 22.751 18.9306 21.6172 20.5478 19.8594C20.9697 19.4375 20.9521 18.875 20.6006 18.5498C20.2402 18.2158 19.7304 18.2158 19.2822 18.6289C17.9902 20.0439 16.1093 20.9316 14 20.9316C10.2998 20.9316 7.3027 18.2158 6.80173 14.7178H8.13766C8.80563 14.7178 8.99899 14.2168 8.60348 13.6719L6.49411 10.7012C6.1777 10.2617 5.68552 10.2529 5.36911 10.7012L3.25973 13.6631C2.85544 14.2168 3.04001 14.7178 3.72555 14.7178Z"
            fill="black"
        />
    </svg>
)

export default IconMail;