import {SvgProps} from "../index";

export const IconMessageUnfilled: React.FC<SvgProps> = ({
                                                            width = 28,
                                                            height = 28,
                                                            ariaHidden,
                                                            title,
                                                            description
                                                        }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M8.28931 23.5627C9.51099 23.5627 11.9807 22.3762 13.844 21.049C20.0491 21.1633 24.6897 17.5773 24.6897 12.8664C24.6897 8.32245 20.1458 4.6662 14.4856 4.6662C8.82544 4.6662 4.29028 8.32245 4.29028 12.8664C4.29028 15.758 6.08325 18.3332 8.83423 19.6779C8.4563 20.3898 7.78833 21.3654 7.4104 21.8752C6.8479 22.6223 7.23462 23.5627 8.28931 23.5627ZM9.35278 21.7433C9.23853 21.7873 9.19458 21.7258 9.27368 21.6291C9.72192 21.0842 10.3811 20.2141 10.7063 19.5988C10.97 19.1154 10.8821 18.6935 10.3196 18.4299C7.63892 17.173 6.08325 15.1603 6.08325 12.8664C6.08325 9.33319 9.80981 6.45917 14.4856 6.45917C19.1614 6.45917 22.8879 9.33319 22.8879 12.8664C22.8879 16.3996 19.1614 19.2824 14.4856 19.2824C14.3274 19.2824 14.0813 19.2736 13.7825 19.2736C13.4045 19.2648 13.1233 19.3791 12.7717 19.6516C11.7083 20.4426 10.135 21.4269 9.35278 21.7433Z"
            fill="black"
        />
    </svg>
)

export default IconMessageUnfilled;