import {SvgProps} from "../index";

export const IconFolder: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M6.81055 22.6738H21.3916C23.0879 22.6738 24.0723 21.6895 24.0723 19.8086V10.7646C24.0723 8.88379 23.0791 7.89941 21.1807 7.89941H13.0332C12.4004 7.89941 12.0225 7.75879 11.5303 7.35449L11.0381 6.9502C10.4141 6.44043 9.95703 6.27344 9.03418 6.27344H6.54688C4.89453 6.27344 3.91895 7.24023 3.91895 9.09473V19.8086C3.91895 21.6982 4.91211 22.6738 6.81055 22.6738ZM5.66797 9.24414C5.66797 8.44434 6.11621 8.02246 6.89844 8.02246H8.56836C9.19238 8.02246 9.56152 8.17188 10.0625 8.57617L10.5547 8.98926C11.1699 9.48145 11.6445 9.65723 12.5674 9.65723H21.0664C21.875 9.65723 22.3232 10.0791 22.3232 10.9229V11.4414H5.66797V9.24414ZM6.9248 20.9248C6.11621 20.9248 5.66797 20.5029 5.66797 19.6504V12.9795H22.3232V19.6592C22.3232 20.5029 21.875 20.9248 21.0664 20.9248H6.9248Z" fill="#020202"/>
    </svg>
)

export default IconFolder;
