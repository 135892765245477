import {SvgProps} from "../index";

export const IconSettingsContactsFilled: React.FC<SvgProps> = ({
                                                             width = 28,
                                                             height = 28,
                                                             ariaHidden,
                                                             title,
                                                             description
                                                         }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M10.5 2.33334C7.44329 2.33334 4.95829 4.81834 4.95829 7.87501C4.95829 10.8733 7.30329 13.3 10.36 13.405C10.4533 13.3933 10.5466 13.3933 10.6166 13.405H10.6983C12.1325 13.3572 13.492 12.7534 14.4891 11.7214C15.4863 10.6894 16.043 9.31006 16.0416 7.87501C16.0416 4.81834 13.5566 2.33334 10.5 2.33334ZM16.4266 16.5072C13.1716 14.3372 7.86329 14.3372 4.58495 16.5072C3.10329 17.4988 2.28662 18.8405 2.28662 20.2755C2.28662 21.7105 3.10329 23.0405 4.57329 24.0205C6.20662 25.1172 8.35329 25.6655 10.5 25.6655C12.6466 25.6655 14.7933 25.1172 16.4266 24.0205C17.8966 23.0288 18.7133 21.6988 18.7133 20.2522C18.7016 18.8172 17.8966 17.4872 16.4266 16.5072ZM23.3216 8.56101C23.5083 10.8243 21.8983 12.8077 19.67 13.076H19.6116C19.5416 13.076 19.4716 13.076 19.4133 13.0993C18.2816 13.1577 17.2433 12.796 16.4616 12.131C17.6633 11.0577 18.3516 9.44768 18.2116 7.69768C18.1316 6.78768 17.8225 5.91274 17.3133 5.15434C17.9246 4.85694 18.5991 4.71272 19.2786 4.73413C19.9581 4.75555 20.6222 4.94195 21.2135 5.27725C21.8049 5.61256 22.3058 6.08671 22.6731 6.65879C23.0404 7.23088 23.2629 7.88372 23.3216 8.56101Z"
            fill="black"/>
        <path
            d="M25.6527 19.355C25.5593 20.4867 24.836 21.4667 23.6227 22.1317C22.456 22.7733 20.986 23.0767 19.5277 23.0417C20.3677 22.2833 20.8577 21.3383 20.951 20.335C21.0677 18.8883 20.3793 17.5 19.0027 16.3917C18.221 15.7733 17.311 15.2833 16.3193 14.9217C18.8977 14.175 22.141 14.6767 24.136 16.2867C25.2093 17.15 25.7577 18.235 25.6527 19.355Z"
            fill="black"/>
    </svg>
)

export default IconSettingsContactsFilled;
