import {SvgProps} from "../index";

export const IconUpload: React.FC<SvgProps> = ({
                                                     width = 28,
                                                     height = 28,
                                                     ariaHidden,
                                                     title,
                                                     description
                                                 }) => (
    <svg
        width={width}
        height={height}
        viewBox="-3 -3 39 39"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
        style={{transform: 'rotate(180deg)'}}
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M25.1568 19.8452H22.4688V13.5322H12.0308V19.8452H6.28076L11.8438 26.4082L17.2188 32.5332L21.0318 27.9082C23.1568 25.3452 25.5948 22.4702 26.4068 21.5332L27.8448 19.8452H25.1568ZM12.0318 9.03217H22.4698V12.3452H12.0318V9.03217ZM12.0318 4.22017H22.4068V7.09517H12.0318V4.22017ZM12.0318 0.782166H22.4698V2.22017H12.0318V0.782166Z"
            fill="black"
        />
    </svg>
)

export default IconUpload;