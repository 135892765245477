import {SvgProps} from "../index";

export const IconPaperclip: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.1729 3.21472C15.7685 0.619176 19.9767 0.619176 22.5722 3.21472C25.1677 5.81026 25.1677 10.0185 22.5722 12.614L15.6511 19.5352C14.1727 21.0136 11.7757 21.0136 10.2973 19.5352C8.81885 18.0567 8.81885 15.6598 10.2973 14.1814L15.4874 8.99125C15.8974 8.5812 16.5622 8.58119 16.9723 8.99124C17.3823 9.40129 17.3823 10.0661 16.9723 10.4762L11.7822 15.6663C11.1239 16.3246 11.1239 17.3919 11.7822 18.0502C12.4405 18.7085 13.5078 18.7085 14.1661 18.0502L21.0873 11.1291C22.8627 9.35364 22.8627 6.47508 21.0873 4.69964C19.3118 2.9242 16.4333 2.9242 14.6578 4.69964L7.76828 11.5892C4.85029 14.5072 4.85029 19.2382 7.76828 22.1562C10.6863 25.0742 15.4173 25.0742 18.3353 22.1562L23.4938 16.9977C23.9038 16.5876 24.5687 16.5876 24.9787 16.9977C25.3888 17.4077 25.3888 18.0725 24.9787 18.4826L19.8202 23.6411C16.0821 27.3792 10.0215 27.3792 6.28336 23.6411C2.54527 19.903 2.54527 13.8424 6.28336 10.1043L13.1729 3.21472Z"
              fill="black"/>
    </svg>
)

export default IconPaperclip;
