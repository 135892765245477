import {SvgProps} from "../index";

export const IconSettingsContacts: React.FC<SvgProps> = ({
                                                             width = 28,
                                                             height = 28,
                                                             ariaHidden,
                                                             title,
                                                             description
                                                         }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M19.145 4.66667C21.4083 4.66667 23.2283 6.49833 23.2283 8.75C23.2283 10.955 21.4783 12.7517 19.2967 12.8333C19.1959 12.8217 19.0941 12.8217 18.9933 12.8333M21.3967 23.3333C22.2367 23.1583 23.03 22.82 23.6833 22.3183C25.5033 20.9533 25.5033 18.7017 23.6833 17.3367C23.0417 16.8467 22.26 16.52 21.4317 16.3333M10.6867 12.6817C10.57 12.67 10.43 12.67 10.3017 12.6817C8.96258 12.6362 7.69376 12.0713 6.76391 11.1066C5.83406 10.1419 5.3162 8.85319 5.32001 7.51333C5.32001 4.655 7.63001 2.33333 10.5 2.33333C11.8723 2.30858 13.1982 2.82997 14.186 3.78281C15.1739 4.73565 15.7428 6.04189 15.7675 7.41417C15.7923 8.78644 15.2709 10.1123 14.318 11.1002C13.3652 12.088 12.0589 12.6569 10.6867 12.6817ZM4.85334 16.9867C2.03001 18.8767 2.03001 21.9567 4.85334 23.835C8.06167 25.9817 13.3233 25.9817 16.5317 23.835C19.355 21.945 19.355 18.865 16.5317 16.9867C13.335 14.8517 8.07334 14.8517 4.85334 16.9867Z"
            stroke="#0D112B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default IconSettingsContacts;
