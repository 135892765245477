import {SvgProps} from "../index";

export const IconMessageFilled: React.FC<SvgProps> = ({
                                                  width = 28,
                                                  height = 28,
                                                  ariaHidden,
                                                  title,
                                                  description
                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M7.86494 23.9114C8.94994 23.9114 11.5379 22.7755 13.1565 21.6306C13.3255 21.5054 13.4678 21.4607 13.6101 21.4607C13.7257 21.4696 13.8324 21.4786 13.9302 21.4786C20.3424 21.4696 24.9314 17.9546 24.9314 13.1247C24.9314 8.50047 20.3335 4.77966 14.6061 4.77966C8.87879 4.77966 4.28979 8.50047 4.28979 13.1247C4.28979 16.0047 6.01511 18.5807 8.93215 20.1638C9.09223 20.2622 9.1367 20.3963 9.05666 20.5573C8.54084 21.4249 7.67818 22.3909 7.32244 22.8471C6.92224 23.3569 7.15347 23.9114 7.86494 23.9114Z"
            fill="#020202"
        />
    </svg>
)

export default IconMessageFilled;