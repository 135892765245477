import {SvgProps} from "../index";

export const IconVideoChat: React.FC<SvgProps> = ({
                                                     width = 28,
                                                     height = 28,
                                                     ariaHidden,
                                                     title,
                                                     description
                                                 }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <circle cx="8.45481" cy="14.3819" r="1.76145" fill="black"/>
        <circle cx="14.0915" cy="14.3819" r="1.76145" fill="black"/>
        <circle cx="19.7282" cy="14.3819" r="1.76145" fill="black"/>
    </svg>
)

export default IconVideoChat;
