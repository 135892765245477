import {SvgProps} from "../index";

export const IconPhoneCall: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.69629 20.8877H15.9424C17.8408 20.8877 18.9307 19.8242 18.9307 17.9609V9.33008C18.9307 7.4668 17.9111 6.40332 16.0127 6.40332H6.69629C4.86816 6.40332 3.70801 7.4668 3.70801 9.33008V18.0225C3.70801 19.877 4.80664 20.8877 6.69629 20.8877ZM20.2227 16.4756L23.2285 19.0596C23.5889 19.376 23.9756 19.5781 24.3447 19.5781C25.0566 19.5781 25.54 19.0684 25.54 18.2949V9.04004C25.54 8.2666 25.0566 7.75684 24.3447 7.75684C23.9668 7.75684 23.5889 7.95898 23.2285 8.27539L20.2227 10.8594V16.4756Z"
            fill="black"
        />
    </svg>
)

export default IconPhoneCall;
