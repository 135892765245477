import {SvgProps} from "../index";

export const IconKeypadSquare: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M20.1492 22.0915C21.319 22.0915 22.2451 21.1654 22.2451 19.9956C22.2451 18.8258 21.319 17.8997 20.1492 17.8997C18.9795 17.8997 18.0533 18.8258 18.0533 19.9956C18.0533 21.1654 18.9795 22.0915 20.1492 22.0915ZM14.4951 22.0915C15.6649 22.0915 16.591 21.1654 16.591 19.9956C16.591 18.8258 15.6649 17.8997 14.4951 17.8997C13.3254 17.8997 12.3992 18.8258 12.3992 19.9956C12.3992 21.1654 13.3254 22.0915 14.4951 22.0915ZM8.84101 22.0915C10.0108 22.0915 10.9369 21.1654 10.9369 19.9956C10.9369 18.8258 10.0108 17.8997 8.84101 17.8997C7.67124 17.8997 6.74512 18.8258 6.74512 19.9956C6.74512 21.1654 7.67124 22.0915 8.84101 22.0915ZM20.1492 16.4374C21.319 16.4374 22.2451 15.5113 22.2451 14.3415C22.2451 13.1717 21.319 12.2456 20.1492 12.2456C18.9795 12.2456 18.0533 13.1717 18.0533 14.3415C18.0533 15.5113 18.9795 16.4374 20.1492 16.4374ZM14.4951 16.4374C15.6649 16.4374 16.591 15.5113 16.591 14.3415C16.591 13.1717 15.6649 12.2456 14.4951 12.2456C13.3254 12.2456 12.3992 13.1717 12.3992 14.3415C12.3992 15.5113 13.3254 16.4374 14.4951 16.4374ZM8.84101 16.4374C10.0108 16.4374 10.9369 15.5113 10.9369 14.3415C10.9369 13.1717 10.0108 12.2456 8.84101 12.2456C7.67124 12.2456 6.74512 13.1717 6.74512 14.3415C6.74512 15.5113 7.67124 16.4374 8.84101 16.4374ZM20.1492 10.7833C21.319 10.7833 22.2451 9.85715 22.2451 8.68738C22.2451 7.51762 21.319 6.59149 20.1492 6.59149C18.9795 6.59149 18.0533 7.51762 18.0533 8.68738C18.0533 9.85715 18.9795 10.7833 20.1492 10.7833ZM14.4951 10.7833C15.6649 10.7833 16.591 9.85715 16.591 8.68738C16.591 7.51762 15.6649 6.59149 14.4951 6.59149C13.3254 6.59149 12.3992 7.51762 12.3992 8.68738C12.3992 9.85715 13.3254 10.7833 14.4951 10.7833ZM8.84101 10.7833C10.0108 10.7833 10.9369 9.85715 10.9369 8.68738C10.9369 7.51762 10.0108 6.59149 8.84101 6.59149C7.67124 6.59149 6.74512 7.51762 6.74512 8.68738C6.74512 9.85715 7.67124 10.7833 8.84101 10.7833Z" fill="#020202"/>
    </svg>
)

export default IconKeypadSquare;
