import {SvgProps} from "../index";

export const IconCheckmark: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="-1 -1 30 30"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M22.5817 6.3296C23.0262 6.70197 23.0847 7.36421 22.7124 7.80876L11.211 21.5397C11.0115 21.7779 10.7167 21.9155 10.406 21.9155C10.0953 21.9155 9.8006 21.7779 9.6011 21.5397L5.28806 16.3906C4.9157 15.9461 4.97421 15.2838 5.41876 14.9114C5.86331 14.5391 6.52556 14.5976 6.89792 15.0421L10.406 19.2303L21.1025 6.4603C21.4749 6.01575 22.1371 5.95723 22.5817 6.3296Z"
              fill="black"
        />
    </svg>
)

export default IconCheckmark;
