import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import {
    CallWhenAvailableUser,
    selectAllChatEntries,
    selectAuthUserByUuid,
    selectChatsOnly,
    selectCurrentUser,
    selectCurrentUserSipName,
    selectFavouriteChats,
    selectMarkUnreadChats,
    selectMutedChats,
    selectParkingSlots,
    selectPhoneSettingByKey,
    selectShortCodes,
    selectTenantClients,
    selectUserGlobalDnd,
    selectUsersDictionary,
    selectUserStatuses
} from '../index';
import { IChat, IParkingSlot, IShortcode, IThread, IUserStatus } from '../../../types';
import { RootState } from '../../store';

interface CallShortCodes {
    listenShortcode?: IShortcode;
    whisperShortcode?: IShortcode;
    bargeShortcode?: IShortcode;
    pickupShortcode?: IShortcode;
}

export const selectCallingShortcodes = createSelector(
    [selectShortCodes, selectCurrentUser],
    (shortCodes, user): CallShortCodes => {
        const base: CallShortCodes = {
            listenShortcode: undefined,
            whisperShortcode: undefined,
            bargeShortcode: undefined,
            pickupShortcode: undefined
        };

        if (!shortCodes || !user) {
            return base;
        }

        for (let i = 0; i < shortCodes.length; i += 1) {
            const sc = shortCodes[i];
            switch (sc.feature.feature) {
                case 'listen':
                    if (user.can_listen) {
                        base.listenShortcode = sc;
                    }
                    break;
                case 'whisper':
                    if (user.can_whisper) {
                        base.whisperShortcode = sc;
                    }
                    break;
                case 'barge':
                    if (user.can_barge) {
                        base.bargeShortcode = sc;
                    }
                    break;
                case 'pickup':
                    if (user.can_pickup) {
                        base.pickupShortcode = sc;
                    }
                    break;
                default:
            }
        }

        return base;
    }
);

export const selectCallingShortcodesOnUser = createSelector(
    [selectCallingShortcodes, selectAuthUserByUuid],
    (shortCodes, user): CallShortCodes => {
        if (!user) {
            return {};
        }

        return {
            listenShortcode: user.can_be_listened ? shortCodes.listenShortcode : undefined,
            whisperShortcode: user.can_be_whispered ? shortCodes.whisperShortcode : undefined,
            bargeShortcode: user.can_be_barged ? shortCodes.bargeShortcode : undefined,
            pickupShortcode: user.can_be_picked_up ? shortCodes.pickupShortcode : undefined
        };
    }
);

export const selectUserOrClient = createSelector(
    [
        selectUsersDictionary || (() => undefined),
        selectTenantClients || (() => undefined),
        (_, user_uuid: string) => user_uuid
    ],
    (users, tenantUsers, user_uuid): CallWhenAvailableUser | undefined => {
        const sip_user = users[user_uuid];

        if (sip_user) {
            return {
                user_uuid: sip_user.uuid,
                sip_name: sip_user.name,
                display_name: sip_user.nickname,
                extension: sip_user.extension.toString()
            };
        }

        const client = tenantUsers[user_uuid];

        if (client) {
            return {
                user_uuid: client.user_uuid,
                sip_name: client.user_sip_name,
                display_name: client.user_display_name,
                extension: `${client.prefix}${client.extension}`
            };
        }

        return undefined;
    }
);

export const selectDnd = createSelector(
    [
        (state: RootState) => state,
        selectCurrentUserSipName,
        (state: RootState) => selectPhoneSettingByKey(state, 'dnd')
    ],
    (state: RootState, sipName: string, localDnd: boolean) => {
        const globalDnd = selectUserGlobalDnd(state, sipName);

        return {
            localDnd,
            globalDnd,
            dnd: localDnd || globalDnd
        };
    }
);

export const selectCurrentUserQueueAvailability = createSelector(
    [selectUserStatuses, selectCurrentUserSipName],
    (statuses, sipName) => {
        if (!sipName) return false;

        return statuses[sipName]?.queue_available;
    }
);

export const selectParkedCallsCount = createSelector(
    [selectUserStatuses, selectParkingSlots],
    (userStatuses: IUserStatus[], parkingSlots?: IParkingSlot[]): number | undefined => {
        if (!parkingSlots || !userStatuses) return undefined;

        let count = 0;

        for (let i = 0; i < parkingSlots.length; i += 1) {
            const status = `${parkingSlots[i].slot_number}@parking`;

            const foundStatus = userStatuses[status];

            if (foundStatus?.status === 'confirmed') {
                count += 1;
            }
        }

        return count;
    }
);

export const selectChatList = createSelector(
    [selectChatsOnly, selectFavouriteChats, selectMarkUnreadChats],
    (
        chats: IChat[],
        favouriteChats: Record<string, boolean>,
        markUnreadChats: Record<string, boolean>
    ): IChat[] =>
        chats.sort(
            (a, b) =>
                +(favouriteChats?.[b.uuid] || false) - +(favouriteChats?.[a.uuid] || false) ||
                +(markUnreadChats?.[b.uuid] || false) - +(markUnreadChats?.[a.uuid] || false) ||
                moment(b.latestMessage.time).unix() - moment(a.latestMessage.time).unix()
        )
);

export const selectThreadsList = createSelector(
    [selectAllChatEntries],
    (allChats: IChat[]): IChat[] =>
        allChats
            .filter(chat => {
                switch (true) {
                    case chat.type !== 'thread':
                        return false;
                    case !allChats.find(singleChat => singleChat.uuid === chat.source?.source_uuid):
                        return false;
                    default:
                        return true;
                }
            })
            .sort(
                (a, b) => moment(b.latestMessage.time).unix() - moment(a.latestMessage.time).unix()
            )
);

// export const selectAllUnreadThreads = createSelector(
//     [selectThreadsList, selectMutedChats, (state: RootState): string => state.user.uuid],
//     (threadList: IThread[], mutedChats: Record<string, boolean>, userUuid: string): number => {
//         if (!threadList || threadList.length < 1) return 0;
//
//         let total = 0;
//
//         const getParentId = (thread: IThread) => {
//             if (!thread) return undefined;
//
//             return thread.thread_message?.to === userUuid
//                 ? thread.thread_message?.from
//                 : thread.thread_message?.to;
//         };
//
//         for (let i = 0; i < threadList.length; i += 1) {
//             const parentId = threadList[i] && getParentId(threadList[i]);
//
//             if (parentId && threadList[i].unread_count && !mutedChats?.[parentId]) {
//                 total += 1;
//             }
//         }
//
//         return total;
//     }
// );

// export const selectAllUnreadChats = createSelector(
//     [selectChatList, selectMutedChats, selectAllUnreadThreads],
//     (chatList: IChat[], mutedChats: Record<string, boolean>, threadUnreadCount: number): number => {
//         if (!chatList || chatList.length < 1) return threadUnreadCount;
//
//         let total = 0;
//
//         for (let i = 0; i < chatList.length; i += 1) {
//             // if not muted and either has unread messages or it is marked as unread
//             if (!mutedChats?.[chatList[i].uuid] && chatList[i].unread_count !== 0) {
//                 total += 1;
//             }
//         }
//
//         return total;
//     }
// );

export const selectUnreadThreadMessages = createSelector(
    [selectThreadsList, selectMutedChats, (state: RootState): string => state.user.uuid],
    (threadList: IThread[], mutedChats: Record<string, boolean>, userUuid: string): number => {
        if (!threadList || threadList.length < 1) return 0;

        let total = 0;

        const getParentId = (thread: IThread) => {
            if (!thread) return undefined;

            return thread.thread_message?.to === userUuid
                ? thread.thread_message?.from
                : thread.thread_message?.to;
        };

        for (let i = 0; i < threadList.length; i += 1) {
            const parentId = threadList[i] && getParentId(threadList[i]);

            if (parentId && !mutedChats?.[parentId]) {
                const unreadCount = threadList[i].unread_count;

                total += unreadCount < 0 ? unreadCount * -1 : unreadCount;
            }
        }

        return total;
    }
);

export const selectAllChatUnreadMessages = createSelector(
    [selectChatList, selectMutedChats, selectUnreadThreadMessages],
    (chatList: IChat[], mutedChats: Record<string, boolean>, threadUnreadCount: number): number => {
        if (!chatList || chatList.length < 1) return threadUnreadCount;

        let total = 0;

        for (let i = 0; i < chatList.length; i += 1) {
            if (!mutedChats?.[chatList[i].uuid]) {
                const unreadCount = chatList[i].unread_count;

                total += unreadCount < 0 ? unreadCount * -1 : unreadCount;
            }
        }

        return total + threadUnreadCount;
    }
);
