import {SvgProps} from "../index";

export const IconMicrophone: React.FC<SvgProps> = ({
                                                           width = 28,
                                                           height = 28,
                                                           ariaHidden,
                                                           title,
                                                           description
                                                       }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
        className='microphone'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.0059 16.3017C15.79 16.3017 17.1084 14.9658 17.1084 13.0146V7.09076C17.1084 5.13959 15.79 3.80365 14.0059 3.80365C12.2217 3.80365 10.9033 5.13959 10.9033 7.09076V13.0146C10.9033 14.9658 12.2217 16.3017 14.0059 16.3017ZM7.29102 13.2167C7.29102 16.8554 9.70801 19.3427 13.1885 19.6767V21.4609H9.96289C9.49707 21.4609 9.11035 21.83 9.11035 22.2958C9.11035 22.7529 9.49707 23.1308 9.96289 23.1308H18.0488C18.5146 23.1308 18.9014 22.7529 18.9014 22.2958C18.9014 21.83 18.5146 21.4609 18.0488 21.4609H14.8232V19.6767C18.3037 19.3427 20.7207 16.8554 20.7207 13.2167V11.4853C20.7207 11.0195 20.3516 10.6591 19.8857 10.6591C19.4199 10.6591 19.0332 11.0195 19.0332 11.4853V13.1552C19.0332 16.1523 17.0029 18.121 14.0059 18.121C11.0088 18.121 8.97852 16.1523 8.97852 13.1552V11.4853C8.97852 11.0195 8.60059 10.6591 8.13477 10.6591C7.66895 10.6591 7.29102 11.0195 7.29102 11.4853V13.2167Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconMicrophone;
