import {SvgProps} from "../index";

export const IconItalic: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.6015 2.45H11.2233C10.6434 2.45 10.1733 2.9201 10.1733 3.5C10.1733 4.0799 10.6434 4.55 11.2233 4.55H15.2802L10.5552 23.45H5.97334C5.39344 23.45 4.92334 23.9201 4.92334 24.5C4.92334 25.0799 5.39344 25.55 5.97334 25.55H11.3531C11.3683 25.5503 11.3834 25.5503 11.3985 25.55H16.765C17.3449 25.55 17.815 25.0799 17.815 24.5C17.815 23.9201 17.3449 23.45 16.765 23.45H12.7198L17.4448 4.55H22.015C22.5949 4.55 23.065 4.0799 23.065 3.5C23.065 2.9201 22.5949 2.45 22.015 2.45H16.647C16.6318 2.44967 16.6166 2.44967 16.6015 2.45Z"
              fill="black"
        />
    </svg>);
export default IconItalic;
