import {SvgProps} from "../index";

export const IconNotificationAndBadgeFilled: React.FC<SvgProps> = ({
                                                                       width = 28,
                                                                       height = 28,
                                                                       ariaHidden,
                                                                       title,
                                                                       description
                                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.96875 19.4639H21.0225C21.9277 19.4639 22.4814 18.9717 22.4814 18.2334C22.4814 17.3018 21.6377 16.502 20.8467 15.7461C20.2842 15.1836 20.1084 14.0498 20.0293 13.0654C20.0029 12.4941 19.959 11.9492 19.8887 11.4307C19.4229 11.624 18.9131 11.7383 18.377 11.7383C16.0391 11.7383 14.1143 9.81348 14.1143 7.4668C14.1143 6.38574 14.5273 5.39258 15.1953 4.64551C14.8525 4.46094 14.4395 4.34668 13.9912 4.34668C12.6992 4.34668 11.7412 5.24316 11.3721 6.40332C9.10449 7.2207 8.15527 9.48828 8.02344 12.7051C7.90918 13.874 7.75977 15.1396 7.14453 15.7461C6.35352 16.502 5.50977 17.3018 5.50977 18.2334C5.50977 18.9717 6.06348 19.4639 6.96875 19.4639ZM18.3857 10.499C20.0381 10.499 21.418 9.12793 21.418 7.4668C21.418 5.80566 20.0381 4.43457 18.3857 4.43457C16.7246 4.43457 15.3535 5.80566 15.3535 7.4668C15.3535 9.12793 16.7246 10.499 18.3857 10.499ZM14 23.085C15.5557 23.085 16.6895 21.9688 16.8037 20.6943H11.1875C11.3018 21.9688 12.4355 23.085 14 23.085Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconNotificationAndBadgeFilled;
