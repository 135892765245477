import {SvgProps} from "../index";

export const IconMessageDots: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M8.30664 23.5627C9.52832 23.5627 11.998 22.3762 13.8613 21.049C20.0664 21.1633 24.707 17.5773 24.707 12.8664C24.707 8.32245 20.1631 4.6662 14.5029 4.6662C8.84277 4.6662 4.30762 8.32245 4.30762 12.8664C4.30762 15.758 6.10059 18.3332 8.85156 19.6779C8.47363 20.3898 7.80566 21.3654 7.42773 21.8752C6.86523 22.6223 7.25195 23.5627 8.30664 23.5627ZM9.37012 21.7433C9.25586 21.7873 9.21191 21.7258 9.29102 21.6291C9.73926 21.0842 10.3984 20.2141 10.7236 19.5988C10.9873 19.1154 10.8994 18.6935 10.3369 18.4299C7.65625 17.173 6.10059 15.1603 6.10059 12.8664C6.10059 9.33319 9.82715 6.45917 14.5029 6.45917C19.1787 6.45917 22.9053 9.33319 22.9053 12.8664C22.9053 16.3996 19.1787 19.2824 14.5029 19.2824C14.3447 19.2824 14.0986 19.2736 13.7998 19.2736C13.4219 19.2648 13.1406 19.3791 12.7891 19.6516C11.7256 20.4426 10.1523 21.4269 9.37012 21.7433Z" fill="#020202"/>
        <circle cx="18.8" cy="12.8" r="1.3" fill="black"/>
        <circle cx="14.8" cy="12.8" r="1.3" fill="black"/>
        <circle cx="10.8" cy="12.8" r="1.3" fill="black"/>
    </svg>
)

export default IconMessageDots;
