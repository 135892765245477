import {SvgProps} from "../index";

export const IconAttachmentFile: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">Attachment File</title>}
        {description && <desc id="desc">Attachment File Icon</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.17629 5.34291C4.07325 6.44594 3.50012 8.24072 3.50012 11.0834V18.0834C3.50012 20.9261 4.07325 22.7209 5.17629 23.8239C6.27932 24.927 8.07409 25.5001 10.9168 25.5001H17.9168C20.7595 25.5001 22.5543 24.927 23.6573 23.8239C24.7603 22.7209 25.3335 20.9261 25.3335 18.0834V12.2501C25.3335 11.8359 25.6692 11.5001 26.0835 11.5001C26.4977 11.5001 26.8335 11.8359 26.8335 12.2501V18.0834C26.8335 21.0741 26.2399 23.3626 24.718 24.8846C23.196 26.4065 20.9074 27.0001 17.9168 27.0001H10.9168C7.92615 27.0001 5.63759 26.4065 4.11563 24.8846C2.59366 23.3626 2.00012 21.0741 2.00012 18.0834V11.0834C2.00012 8.09278 2.59366 5.80422 4.11563 4.28225C5.63759 2.76028 7.92615 2.16675 10.9168 2.16675H16.7501C17.1643 2.16675 17.5001 2.50253 17.5001 2.91675C17.5001 3.33096 17.1643 3.66675 16.7501 3.66675H10.9168C8.07409 3.66675 6.27932 4.23988 5.17629 5.34291Z"
              fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.4631 2.22374C16.7434 2.10765 17.066 2.17182 17.2805 2.38632L26.6138 11.7197C26.8283 11.9341 26.8925 12.2567 26.7764 12.537C26.6603 12.8172 26.3868 13 26.0835 13H21.4168C19.6252 13 18.1922 12.7111 17.2406 11.7595C16.289 10.8079 16.0001 9.37486 16.0001 7.58331V2.91665C16.0001 2.6133 16.1829 2.33982 16.4631 2.22374ZM17.5001 4.72731V7.58331C17.5001 9.29177 17.7945 10.1921 18.3013 10.6988C18.808 11.2056 19.7083 11.5 21.4168 11.5H24.2728L17.5001 4.72731Z"
              fill="black"/>
    </svg>
)

export default IconAttachmentFile;
