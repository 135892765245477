import {SvgProps} from "../../index";

export const IconBLFCallswitchAvailable: React.FC<SvgProps> = ({
                                                         width = 50,
                                                         height = 50,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 50 50"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <circle cx="25" cy="25" r="25" fill="url(#paint0_linear_367_10334)"/>
        <path d="M20.3198 29.6106C23.5608 32.8625 27.5049 35.3674 30.7239 35.3674C32.1631 35.3674 33.4265 34.8621 34.4373 33.7415C35.0305 33.0823 35.3931 32.3242 35.3931 31.5662C35.3931 30.9839 35.1624 30.4126 34.6021 30.0171L31.2292 27.6221C30.6909 27.2485 30.2515 27.0728 29.856 27.0728C29.3396 27.0728 28.8782 27.3584 28.3728 27.8638L27.5818 28.6548C27.45 28.7756 27.2961 28.8306 27.1533 28.8306C26.9775 28.8306 26.8127 28.7646 26.7029 28.6987C26.0107 28.3252 24.8242 27.3145 23.7256 26.2158C22.627 25.1172 21.6052 23.9417 21.2427 23.2385C21.1768 23.1177 21.1218 22.9639 21.1218 22.7881C21.1218 22.6562 21.1658 22.5024 21.2866 22.3706L22.0776 21.5686C22.572 21.0522 22.8687 20.6018 22.8687 20.0854C22.8687 19.679 22.6929 19.2395 22.3193 18.7122L19.9353 15.3723C19.5288 14.801 18.9575 14.5593 18.3313 14.5593C17.5842 14.5593 16.8372 14.8889 16.189 15.5151C15.0903 16.5479 14.6069 17.8333 14.6069 19.2505C14.6069 22.4695 17.0789 26.3696 20.3198 29.6106Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.3428 20.5417L33.4864 22.6853L35.1853 20.9865L33.0416 18.8428L35.1855 16.6989L33.4867 15.0001L31.3428 17.144L29.1988 15L27.5 16.6988L29.644 18.8428L27.5003 20.9866L29.1991 22.6854L31.3428 20.5417Z" fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_367_10334" x1="35.3369" y1="49.9999" x2="8.4854" y2="-47.4943" gradientUnits="userSpaceOnUse">
                <stop stopColor="#BB0000"/>
                <stop offset="1" stopColor="#FFC6BE"/>
            </linearGradient>
        </defs>
    </svg>
)

export default IconBLFCallswitchAvailable;
