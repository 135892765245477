import {SvgProps} from "../index";

export const IconLogout: React.FC<SvgProps> = ({
                                                 width = 28,
                                                 height = 28,
                                                 ariaHidden,
                                                 title,
                                                 description
                                             }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.93652 23.8378H16.2969C18.1777 23.8378 19.1533 22.8535 19.1533 20.955V16.9999H17.4307V20.8495C17.4307 21.6669 17 22.1152 16.1475 22.1152H7.07715C6.22461 22.1152 5.80273 21.6669 5.80273 20.8495V7.79779C5.80273 6.98041 6.22461 6.53217 7.07715 6.53217H16.1475C17 6.53217 17.4307 6.98041 17.4307 7.79779V11.6562H19.1533V7.69232C19.1533 5.80267 18.1777 4.80951 16.2969 4.80951H6.93652C5.05566 4.80951 4.08008 5.80267 4.08008 7.69232V20.955C4.08008 22.8535 5.05566 23.8378 6.93652 23.8378ZM12.5967 15.1279H21.4561L22.7568 15.0663L22.1152 15.6113L20.8057 16.8417C20.6475 16.9824 20.5596 17.1845 20.5596 17.3867C20.5596 17.7822 20.8584 18.1161 21.2627 18.1161C21.4736 18.1161 21.6318 18.0283 21.7812 17.8788L24.6289 14.9345C24.8311 14.7236 24.9014 14.5302 24.9014 14.3193C24.9014 14.1083 24.8311 13.915 24.6289 13.704L21.7812 10.7509C21.6318 10.6015 21.4736 10.5224 21.2627 10.5224C20.8584 10.5224 20.5596 10.8388 20.5596 11.2431C20.5596 11.4365 20.6475 11.6474 20.8057 11.788L22.1152 13.0273L22.7656 13.5722L21.4561 13.5019H12.5967C12.166 13.5019 11.7969 13.871 11.7969 14.3193C11.7969 14.7675 12.166 15.1279 12.5967 15.1279Z"
            fill="black"
        />
    </svg>
)

export default IconLogout;