import {SvgProps} from "../index";

export const IconSmallComment: React.FC<SvgProps> = ({
                                                         width = 26,
                                                         height = 26,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 26 26"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M21.1855 8.6875V12.5125C21.1855 13.465 20.8705 14.2675 20.308 14.8225C19.753 15.385 18.9505 15.7 17.998 15.7V17.0575C17.998 17.5675 17.428 17.875 17.008 17.59L16.2805 17.11C16.348 16.8775 16.378 16.6225 16.378 16.3525V13.3C16.378 11.77 15.358 10.75 13.828 10.75H8.73555C8.63055 10.75 8.53305 10.7575 8.43555 10.765V8.6875C8.43555 6.775 9.71055 5.5 11.623 5.5H17.998C19.9105 5.5 21.1855 6.775 21.1855 8.6875Z"
            stroke="#868895" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M16.378 13.3V16.3525C16.378 16.6225 16.348 16.8775 16.2805 17.11C16.003 18.2125 15.088 18.9025 13.828 18.9025H11.788L9.52305 20.41C9.44712 20.462 9.35839 20.4921 9.26652 20.4972C9.17465 20.5023 9.08314 20.4821 9.00195 20.4388C8.92076 20.3955 8.853 20.3307 8.80603 20.2516C8.75906 20.1725 8.73468 20.082 8.73555 19.99V18.9025C7.97055 18.9025 7.33305 18.6475 6.89055 18.205C6.44055 17.755 6.18555 17.1175 6.18555 16.3525V13.3C6.18555 11.875 7.07055 10.8925 8.43555 10.765C8.53305 10.7575 8.63055 10.75 8.73555 10.75H13.828C15.358 10.75 16.378 11.77 16.378 13.3Z"
            stroke="#868895" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default IconSmallComment;
