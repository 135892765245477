import {SvgProps} from "../index";

export const IconPhoneBookContact: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 16.6953C11.5127 16.6953 9.58789 17.583 8.65625 18.6025C7.46094 17.3105 6.73145 15.5703 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2686 15.5703 20.5391 17.3105 19.335 18.6113C18.4033 17.583 16.4785 16.6953 13.9912 16.6953ZM13.9912 15.2539C15.6963 15.2715 17.0234 13.8125 17.0234 11.9316C17.0234 10.1562 15.6875 8.6709 13.9912 8.6709C12.3037 8.6709 10.959 10.1562 10.9678 11.9316C10.9766 13.8125 12.2949 15.2451 13.9912 15.2539Z"
            fill="black"
        />
    </svg>
)

export default IconPhoneBookContact;
