import {SvgProps} from "../index";

export const IconCallIncoming: React.FC<SvgProps> = ({
                                                         width = 28,
                                                         height = 28,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M22.1118 5.84271C21.9009 5.84271 21.7251 5.92181 21.5669 6.07123L18.0337 9.61322L16.8296 10.9316L16.9702 8.65521V7.38959C16.9702 6.98529 16.6626 6.67767 16.2495 6.6601C15.8364 6.64252 15.4937 6.96771 15.4937 7.3808V12.329C15.4937 12.9091 15.8013 13.2167 16.3813 13.2167H21.356C21.7778 13.2167 22.0942 12.874 22.0854 12.4521C22.0679 12.0478 21.7603 11.7402 21.356 11.7402H20.0552L17.7524 11.8896L19.0972 10.6855L22.6304 7.1347C22.7974 6.96771 22.8765 6.78314 22.8765 6.56342C22.8765 6.15912 22.5513 5.84271 22.1118 5.84271ZM10.5718 18.0331C13.1646 20.6347 16.3198 22.6386 18.895 22.6386C20.0464 22.6386 21.0571 22.2343 21.8657 21.3378C22.3403 20.8105 22.6304 20.204 22.6304 19.5976C22.6304 19.1318 22.4458 18.6747 21.9976 18.3583L19.2905 16.4423C18.8687 16.1435 18.5171 16.0029 18.2007 16.0029C17.7876 16.0029 17.4185 16.2314 17.0142 16.6357L16.3726 17.2685C16.2759 17.3652 16.1528 17.4091 16.0386 17.4091C15.8979 17.4091 15.7661 17.3564 15.6694 17.3036C15.1245 17.0048 14.1753 16.1962 13.2964 15.3173C12.4175 14.4384 11.6001 13.498 11.3101 12.9355C11.2573 12.8388 11.2046 12.7158 11.2046 12.5751C11.2046 12.4697 11.2397 12.3466 11.3452 12.2411L11.978 11.5995C12.3735 11.1865 12.6108 10.8261 12.6108 10.413C12.6108 10.0878 12.4702 9.73627 12.1714 9.31439L10.2642 6.64252C9.93896 6.18549 9.47314 5.99213 8.98096 5.99213C8.3833 5.99213 7.78564 6.2558 7.2583 6.75677C6.38818 7.58295 6.00146 8.61127 6.00146 9.74506C6.00146 12.3203 7.97021 15.4404 10.5718 18.0331Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconCallIncoming;