import {SvgProps} from "../index";

export const IconSpeakerWave: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M13.8992 21.8835C14.5583 21.8835 15.0417 21.3913 15.0417 20.7409V7.32001C15.0417 6.66083 14.5583 6.13348 13.8816 6.13348C13.4333 6.13348 13.1169 6.32684 12.6335 6.78387L8.95972 10.2204C8.90698 10.2731 8.83667 10.2995 8.75757 10.2995H6.27026C4.96948 10.2995 4.26636 11.0202 4.26636 12.3825V15.652C4.26636 17.0143 4.96948 17.735 6.27026 17.735H8.75757C8.83667 17.735 8.89819 17.7614 8.95972 17.8141L12.6335 21.277C13.073 21.6989 13.4421 21.8835 13.8992 21.8835ZM21.7126 20.2751C22.1169 20.5212 22.6091 20.4333 22.8904 20.0202C24.033 18.403 24.7009 16.2321 24.7009 13.9997C24.7009 11.7673 24.0417 9.58759 22.8904 7.97919C22.6091 7.5661 22.1169 7.46942 21.7126 7.7243C21.2908 7.98798 21.2292 8.51532 21.5457 8.98114C22.4773 10.3522 23.0134 12.1364 23.0134 13.9997C23.0134 15.8542 22.4685 17.6384 21.5457 19.0182C21.238 19.4841 21.2908 20.0026 21.7126 20.2751ZM18.1794 17.9196C18.5574 18.1657 19.0496 18.0778 19.322 17.6911C20.0339 16.7419 20.4558 15.3972 20.4558 13.9997C20.4558 12.6022 20.0339 11.2663 19.322 10.3083C19.0496 9.92157 18.5574 9.83368 18.1794 10.0886C17.7488 10.3698 17.6609 10.8884 18.0125 11.3981C18.4958 12.0925 18.7683 13.0241 18.7683 13.9997C18.7683 14.9753 18.4871 15.8981 18.0125 16.6013C17.6697 17.111 17.7488 17.6296 18.1794 17.9196Z"
            fill="black"
        />
    </svg>
)

export default IconSpeakerWave;
