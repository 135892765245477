import {SvgProps} from "../index";

export const IconSettingsNotifications: React.FC<SvgProps> = ({
                                                         width = 28,
                                                         height = 28,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.0233 3.39499C10.1616 3.39499 7.02331 6.53332 7.02331 10.395V13.7667C7.02331 14.4783 6.71998 15.5633 6.35831 16.17L5.01665 18.3983C4.18831 19.775 4.75998 21.3033 6.27665 21.8167C11.305 23.4967 16.73 23.4967 21.7583 21.8167C23.17 21.35 23.7883 19.6817 23.0183 18.3983L21.6766 16.17C21.3266 15.5633 21.0233 14.4783 21.0233 13.7667V10.395C21.0233 6.54499 17.8733 3.39499 14.0233 3.39499Z"
            stroke="#0D112B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
        <path
            d="M16.1817 3.73334C14.7708 3.33152 13.2758 3.33152 11.865 3.73334C12.2033 2.87 13.0433 2.26334 14.0233 2.26334C15.0033 2.26334 15.8433 2.87 16.1817 3.73334Z"
            stroke="#0D112B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M17.5233 22.2367C17.5233 24.1617 15.9483 25.7367 14.0233 25.7367C13.0666 25.7367 12.18 25.34 11.55 24.71C10.8945 24.0535 10.5254 23.1644 10.5233 22.2367"
            stroke="#0D112B" strokeWidth="1.5" strokeMiterlimit="10"/>
    </svg>
)

export default IconSettingsNotifications;
