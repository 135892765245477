import {SvgProps} from "../index";

export const IconAccountFilled: React.FC<SvgProps> = ({
                                                          width = 28,
                                                          height = 28,
                                                          ariaHidden,
                                                          title,
                                                          description
                                                      }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.4792 13.9196C16.3953 13.9196 18.0212 12.2145 18.0212 10.0085C18.0212 7.85516 16.3865 6.20282 14.4792 6.20282C12.5632 6.20282 10.9197 7.88153 10.9285 10.0261C10.9285 12.2145 12.5544 13.9196 14.4792 13.9196ZM8.99487 22.0934H19.946C21.3962 22.0934 21.8972 21.654 21.8972 20.8454C21.8972 18.5866 19.032 15.4753 14.4705 15.4753C9.91772 15.4753 7.0437 18.5866 7.0437 20.8454C7.0437 21.654 7.54468 22.0934 8.99487 22.0934Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconAccountFilled;
