import {SvgProps} from "../index";

export const IconNotificationAndBadge: React.FC<SvgProps> = ({
                                                        width = 28,
                                                        height = 28,
                                                        ariaHidden,
                                                        title,
                                                        description
                                                    }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M7.40747 19.9228H11.0286C11.0901 21.5927 12.4612 23.1396 14.4827 23.1396C16.4954 23.1396 17.8752 21.6015 17.9456 19.9228H21.5579C22.4719 19.9228 23.0344 19.4218 23.0344 18.666C23.0344 17.7255 22.1819 16.9257 21.3821 16.1611C20.7668 15.5546 20.6262 14.3593 20.5032 13.164C20.4944 12.6718 20.4592 12.206 20.4065 11.7578C19.8792 11.9775 19.22 12.0654 18.6575 11.9863C18.7014 12.3906 18.7366 12.8212 18.7454 13.3222C18.8333 14.9921 19.1233 16.2753 19.7209 16.9697C20.1516 17.4619 20.6438 17.9277 20.8723 18.1826V18.288H8.07544V18.1826C8.30396 17.9277 8.81372 17.4619 9.24438 16.9697C9.85083 16.2753 10.1321 14.9921 10.22 13.3222C10.3254 9.79779 11.4065 8.60248 12.8215 8.23334C13.0413 8.17181 13.1555 8.06635 13.1731 7.82904C13.2258 6.94135 13.718 6.34369 14.4827 6.34369C14.6321 6.34369 14.7639 6.36127 14.887 6.40521C15.0452 5.86908 15.3264 5.38568 15.6956 4.98138C15.3352 4.79681 14.9309 4.69135 14.4827 4.69135C13.1731 4.69135 12.1975 5.57904 11.8635 6.74799C9.56958 7.57416 8.60278 9.789 8.47095 13.1025C8.35669 14.3417 8.20728 15.5546 7.58325 16.1611C6.78345 16.9257 5.93091 17.7255 5.93091 18.666C5.93091 19.4218 6.49341 19.9228 7.40747 19.9228ZM18.8772 10.8437C20.5295 10.8437 21.9094 9.4726 21.9094 7.81146C21.9094 6.15033 20.5295 4.77924 18.8772 4.77924C17.2161 4.77924 15.845 6.15033 15.845 7.81146C15.845 9.4726 17.2161 10.8437 18.8772 10.8437ZM14.4827 21.7245C13.4895 21.7245 12.7776 21.0126 12.7161 19.9228H16.2581C16.2053 21.0038 15.4846 21.7245 14.4827 21.7245Z"
            fill="black"
        />
    </svg>
)

export default IconNotificationAndBadge;
