import {SvgProps} from "../index";

export const IconSearch: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M12.354 20.1562C13.9504 20.1562 15.4225 19.6449 16.6269 18.7927L21.158 23.2809C21.3683 23.4892 21.6455 23.5934 21.9419 23.5934C22.5632 23.5934 23.0029 23.1199 23.0029 22.5139C23.0029 22.2299 22.9073 21.9553 22.697 21.7564L18.1946 17.2872C19.141 16.0563 19.705 14.5318 19.705 12.8748C19.705 8.86954 16.3975 5.59337 12.354 5.59337C8.31998 5.59337 5.00293 8.86007 5.00293 12.8748C5.00293 16.8801 8.31042 20.1562 12.354 20.1562ZM12.354 18.5844C9.19942 18.5844 6.58976 15.9995 6.58976 12.8748C6.58976 9.75013 9.19942 7.16517 12.354 7.16517C15.5085 7.16517 18.1182 9.75013 18.1182 12.8748C18.1182 15.9995 15.5085 18.5844 12.354 18.5844Z"
            fill="#0D112B"
        />
    </svg>
)

export default IconSearch;
