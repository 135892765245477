/* eslint-disable react/jsx-props-no-spreading */
import styles from './Icon.module.scss'
import IconSearch from "./IconSearch";
import IconPhoneCall from "./IconPhoneCall";
import IconChatFilled from "./IconChatFilled";
import IconVideoFilled from "./IconVideoFilled";
import IconSettings from "./IconSettings";
import IconWhatsapp from "./IconWhatsapp";
import IconSMS from "./IconSMS";
import IconVideoChat from "./IconVideoChat";
import IconExpand from "./IconExpand";
import IconShrink from "./IconShrink";
import IconSquareAndPencil from "./IconSquareAndPencil";
import IconPaperplane from "./IconPaperplane";
import IconSingleTick from "./IconSingleTick";
import IconDoubleTick from "./IconDoubleTick";
import IconArrowDown from "./IconArrowDown";
import IconCheckedBox from "./IconCheckedBox";
import IconUncheckedBox from "./IconUncheckedBox";
import IconArchive from "./IconArchive";
import IconArrowLeft from "./IconArrowLeft";
import IconSavedMeeting from "./IconSavedMeeting";
import IconCamera from "./IconCamera";
import IconCross from "./IconCross";
import IconCallMissed from "./IconCallMissed";
import IconCallInternal from "./IconCallInternal";
import IconCallIncoming from "./IconCallIncoming";
import IconCallOutgoing from "./IconCallOutgoing";
import IconAddContact from "./IconAddContact";
import IconMessageFilled from "./IconMessageFilled";
import IconBookmark from "./IconBookmark";
import IconStar from "./IconStar";
import IconMuteFilled from "./IconMuteFilled";
import IconBgChat from "./IconBground/IconBgChat";
import IconBgCall from "./IconBground/IconBgCall";
import IconBgVideo from "./IconBground/IconBgVideo";
import IconBgSearch from "./IconBground/IconBgSearch";
import IconFolder from "./IconFolder";
import IconStarUnfilled from "./IconStarUnfilled";
import IconMuteUnfilled from "./IconMuteUnfilled";
import IconThreeDots from "./IconThreeDots";
import IconPaperclip from "./IconPaperclip";
import IconKeypad from "./IconKeypad";
import IconKeypadDelete from "./IconKeypadDelete";
import IconMail from "./IconMail";
import IconParking from "./IconParking";
import IconSwitch from "./IconSwitch";
import IconLogout from "./IconLogout";
import IconContact from "./IconContact";
import IconShortCode from "./IconShortCode";
import IconSpeakerWave from "./IconSpeakerWave";
import IconAbout from "./IconAbout";
import IconAccount from "./IconAccount";
import IconPhoneUnfill from "./IconPhoneUnfill";
import IconMessageUnfilled from "./IconMessageUnfilled";
import IconNotificationAndBadge from "./IconNotificationAndBadge";
import IconContactUnfilled from "./IconContactUnfilled";
import IconSpeakerWaveUnfilled from "./IconSpeakerWaveUnfilled";
import IconArrowRight from "./IconArrowRight";
import IconArrowRightExit from "./IconArrowRightExit";
import IconAccountFilled from "./IconAccountFilled";
import IconNotificationAndBadgeFilled from "./IconNotificationAndBadgeFilled";
import IconAboutFilled from "./IconAboutFilled";
import IconDownload from "./IconDownload";
import IconUpload from "./IconUpload";
import IconDeleteChat from "./IconDeleteChat";
import IconHold from "./IconHold";
import IconKeypadSquare from "./IconKeypadSquare";
import IconTransfer from "./IconTransfer";
import IconMuteMicrophone from "./IconMuteMicrophone";
import IconLoop from "./IconLoop";
import IconHamburger from "./IconHamburger";
import IconPhoneAnswer from "./IconPhoneAnswer";
import IconPhoneDecline from "./IconPhoneDecline";
import IconPin from "./IconPin";
import IconArrowUp from "./IconarrowUp";
import IconBLFAvailable from "./BLFIcons/IconBLFAvailable";
import IconBLFUnavailable from "./BLFIcons/IconBLFUnavailable";
import IconBLFCalling from "./BLFIcons/IconBLFCalling";
import IconBLFOffline from "./BLFIcons/IconBLFOffline";
import IconEar from "./IconEar";
import IconWhisper from "./IconWhisper";
import IconPhoneBookContact from "./IconPhoneBookContact";
import IconCallEAA from "./IconCallEAA";
import IconCallHAA from "./IconCallHAA";
import IconDeviceDesktop from "./IconDeviceDesktop";
import IconDevicePhone from "./IconDevicePhone";
import IconCallswitchBLFUnavailable from "./BLFIcons/IconCallswitchBLFUnavailable";
import IconCallswitchBLFAvailable from "./BLFIcons/IconCallswitchBLFAvailable";
import IconCallswitchBLFCalling from "./BLFIcons/IconCallswitchBLFCalling";
import IconSwitchCalls from "./IconSwitchCalls";
import IconAddContactCircle from "./IconAddContactCircle";
import IconDualChatBubbleUnfill from "./IconDualChatBubbleUnfill";
import IconDualChatBubbleFill from "./IconDualChatBubbleFill";
import IconGridView from "./IconGridView";
import IconRowView from "./IconRowView";
import IconCSLogo from "./IconCSLogo";
import IconPlusCircleFill from "./IconPlusCircleFill";
import IconMinusCircleFill from "./IconMinusCircleFill";
import IconAddCall from "./IconAddCall";
import IconMessageDots from "./IconMessageDots";
import IconMicrophone from "./IconMicrophone";
import IconPlayArrow from "./IconPlayArrow";
import IconPlayArrowFilled from "./IconPlayArrowFilled";
import IconPause from "./IconPause";
import IconPauseFilled from "./IconPauseFilled";
import IconStop from "./IconStop";
import IconStopFilled from "./IconStopFilled";
import IconRecordCircle from "./IconRecordCircle";
import IconRecordCircleFilled from "./IconRecordCircleFilled";
import IconGroup from "./IconGroup";
import IconSingle from "./IconSingle";
import {IconPlus} from "./IconPlus";
import IconUnderline from "./IconUnderline";
import IconBold from "./IconBold";
import IconNumberList from "./IconListNumber";
import IconDashList from "./IconListDash";
import IconItalic from "./IconItalic";
import IconStrikethrough from "./IconStrikethrough";
import IconEmoji from "./IconEmoji";
import IconText from "./IconText";
import IconCheckmark from "./IconCheckmark";
import IconArrowDownWithLine from "./IconArrowDownWithLine";
import IconSettingsAccountFilled from "./IconSettingsAccountFilled";
import IconSettingsCalls from "./IconSettingsCalls";
import IconSettingsChat from "./IconSettingsChat";
import IconSettingsChatFilled from "./IconSettingsChatFilled";
import IconSettingsNotifications from "./IconSettingsNotifications";
import IconSettingsNotificationsFilled from "./IconSettingsNotificationsFilled";
import IconSettingsContacts from "./IconSettingsContacts";
import IconSettingsContactsFilled from "./IconSettingsContactsFilled";
import IconSettingsAudio from "./IconSettingsAudio";
import IconSettingsAudioFilled from "./IconSettingsAudioFilled";
import IconSettingsCallsFilled from "./IconSettingsCallsFilled";
import IconSettingsAccount from "./IconSettingsAccount";
import IconSmallEmoji from "./IconSmallEmoji";
import IconSmallReply from "./IconSmallReply";
import IconSmallComment from "./IconSmallComment";
import IconSmallThreeDots from "./IconSmallThreeDots";
import IconQuestionMark from "./IconQuestionMark";
import IconMinus from "./IconMinus";
import IconProvisionBlf from "./IconProvisionBlf";
import IconProvisionSpeedDial from "./IconProvisionSpeedDial";
import IconAddUser from "./IconAddUser";
import IconAttachmentFile from "./IconAttachmentFile";
import IconAttachmentPlay from "./IconAttachmentPlay";
import IconBLFAux from "./BLFIcons/IconBLFAux";
import IconBin from "./IconBin";
import IconCopy from "./IconCopy";
import IconLink from "./IconLink";

export type IconName =
    'search'
    | 'phoneCall'
    | 'chatFilled'
    | 'videoFilled'
    | 'settings'
    | 'whatsapp'
    | 'SMS'
    | 'videoChat'
    | 'expand'
    | 'shrink'
    | 'squareAndPencil'
    | 'paperplane'
    | 'singleTick'
    | 'doubleTick'
    | 'arrowDown'
    | 'arrowUp'
    | 'arrowLeft'
    | 'arrowRight'
    | 'checkedBox'
    | 'uncheckedBox'
    | 'archive'
    | 'savedMeeting'
    | 'camera'
    | 'cross'
    | 'plus'
    | 'callMissed'
    | 'phonebookContact'
    | 'callInternal'
    | 'callIncoming'
    | 'callOutgoing'
    | 'addContact'
    | 'messageFilled'
    | 'messageDots'
    | 'bookmark'
    | 'star'
    | 'starUnfilled'
    | 'muteFilled'
    | 'muteUnfilled'
    | 'bgChat'
    | 'bgCall'
    | 'bgVideo'
    | 'bgSearch'
    | 'folder'
    | 'threeDots'
    | 'paperclip'
    | 'parking'
    | 'mail'
    | 'logout'
    | 'switchUser'
    | 'contact'
    | 'shortCodes'
    | 'speakerWave'
    | 'speakerWaveUnfilled'
    | 'about'
    | 'aboutFilled'
    | 'account'
    | 'accountFilled'
    | 'phoneUnfilled'
    | 'messageUnfilled'
    | 'notificationAndBadge'
    | 'notificationAndBadgeFilled'
    | 'contactUnfilled'
    | 'keypad'
    | 'keypadDelete'
    | 'download'
    | 'upload'
    | 'deleteChat'
    | 'keypadSquare'
    | 'hold'
    | 'transfer'
    | 'muteMicrophone'
    | 'microphone'
    | 'loop'
    | 'hamburger'
    | 'phoneAnswer'
    | 'phoneDecline'
    | 'pin'
    | 'blfAvailable'
    | 'blfAux'
    | 'blfCalling'
    | 'blfOffline'
    | 'blfUnavailable'
    | 'ear'
    | 'whisper'
    | 'callEAA'
    | 'callHAA'
    | 'deviceDesktop'
    | 'devicePhone'
    | 'switchCalls'
    | 'addContactCircle'
    | 'dualChatBubbleUnfill'
    | 'dualChatBubbleFill'
    | 'gridView'
    | 'rowView'
    | 'csLogo'
    | 'plusCircleFill'
    | 'minusCircleFill'
    | 'callswitchBlfAvailable'
    | 'callswitchBlfUnavailable'
    | 'callswitchBlfCalling'
    | 'addCall'
    | 'arrowRightExit'
    | 'play'
    | 'playFilled'
    | 'pause'
    | 'pauseFilled'
    | 'stop'
    | 'stopFilled'
    | 'record'
    | 'recordFilled'
    | 'group'
    | 'single'
    | 'underline'
    | 'italic'
    | 'bold'
    | 'listNumber'
    | 'listDash'
    | 'strikethrough'
    | 'emoji'
    | 'text'
    | 'tick'
    | 'arrowDownWithLine'
    | 'settingsAccount'
    | 'settingsAccountFilled'
    | 'settingsCalls'
    | 'settingsCallsFilled'
    | 'settingsChat'
    | 'settingsChatFilled'
    | 'settingsNotifications'
    | 'settingsNotificationsFilled'
    | 'settingsContacts'
    | 'settingsContactsFilled'
    | 'settingsAudio'
    | 'settingsAudioFilled'
    | 'smallEmoji'
    | 'smallReply'
    | 'smallComment'
    | 'smallThreeDots'
    | 'questionMark'
    | 'minus'
    | 'provisionBlf'
    | 'provisionSpeedDial'
    | 'attachmentFile'
    | 'attachmentPlay'
    | 'addUser'
    | 'bin'
    | 'copy'
    | 'link'
    ;

export interface SvgProps {
    width?: number;
    height?: number;
    ariaHidden?: boolean;
    title?: string;
    description?: string;
    onClick?: any;
    def?: boolean;
}

interface IconProps extends SvgProps {
    name: IconName;
}

export const Icon: React.FC<IconProps> = (props) => {
    switch (props.name) {
        case 'search':
            return <span className={!props.def ? styles.icon : ''}><IconSearch {...props}/></span>
        case 'phoneCall':
            return <span className={!props.def ? styles.icon : ''}><IconPhoneCall {...props}/></span>
        case 'chatFilled':
            return <span className={!props.def ? styles.icon : ''}><IconChatFilled {...props}/></span>
        case 'videoFilled':
            return <span className={!props.def ? styles.icon : ''}><IconVideoFilled {...props}/></span>
        case 'settings':
            return <span className={!props.def ? styles.icon : ''}><IconSettings {...props}/></span>
        case 'whatsapp':
            return <span className={!props.def ? styles.icon : ''}><IconWhatsapp {...props}/></span>
        case 'SMS':
            return <span className={!props.def ? styles.icon : ''}><IconSMS {...props}/></span>
        case 'videoChat':
            return <span className={!props.def ? styles.icon : ''}><IconVideoChat {...props}/></span>
        case 'expand':
            return <span className={!props.def ? styles.icon : ''}><IconExpand {...props}/></span>
        case 'shrink':
            return <span className={!props.def ? styles.icon : ''}><IconShrink {...props}/></span>
        case 'squareAndPencil':
            return <span className={!props.def ? styles.icon : ''}><IconSquareAndPencil {...props}/></span>
        case 'paperplane':
            return <span className={!props.def ? styles.icon : ''}><IconPaperplane {...props}/></span>
        case 'singleTick':
            return <span className={!props.def ? styles.icon : ''}><IconSingleTick {...props}/></span>
        case 'doubleTick':
            return <span className={!props.def ? styles.icon : ''}><IconDoubleTick {...props}/></span>
        case 'arrowUp':
            return <span className={!props.def ? styles.icon : ''}><IconArrowUp {...props}/></span>
        case 'arrowDown':
            return <span className={!props.def ? styles.icon : ''}><IconArrowDown {...props}/></span>
        case 'arrowRight':
            return <span className={!props.def ? styles.icon : ''}><IconArrowRight {...props}/></span>
        case 'arrowLeft':
            return <span className={!props.def ? styles.icon : ''}><IconArrowLeft {...props}/></span>
        case 'checkedBox':
            return <span className={!props.def ? styles.icon : ''}><IconCheckedBox {...props}/></span>
        case 'uncheckedBox':
            return <span className={!props.def ? styles.icon : ''}><IconUncheckedBox {...props}/></span>
        case 'archive':
            return <span className={!props.def ? styles.icon : ''}><IconArchive {...props}/></span>
        case 'savedMeeting':
            return <span className={!props.def ? styles.icon : ''}><IconSavedMeeting {...props}/></span>
        case 'camera':
            return <span className={!props.def ? styles.icon : ''}><IconCamera {...props}/></span>
        case 'cross':
            return <span className={!props.def ? styles.icon : ''}><IconCross {...props}/></span>
        case 'callMissed':
            return <span className={!props.def ? styles.icon : ''}><IconCallMissed {...props}/></span>
        case 'phonebookContact':
            return <span className={!props.def ? styles.icon : ''}><IconPhoneBookContact {...props}/></span>
        case 'callInternal':
            return <span className={!props.def ? styles.icon : ''}><IconCallInternal {...props}/></span>
        case 'callIncoming':
            return <span className={!props.def ? styles.icon : ''}><IconCallIncoming {...props}/></span>
        case 'callOutgoing':
            return <span className={!props.def ? styles.icon : ''}><IconCallOutgoing {...props}/></span>
        case 'addContact':
            return <span className={!props.def ? styles.icon : ''}><IconAddContact {...props}/></span>
        case 'messageFilled':
            return <span className={!props.def ? styles.icon : ''}><IconMessageFilled {...props}/></span>
        case 'bookmark':
            return <span className={!props.def ? styles.icon : ''}><IconBookmark {...props}/></span>
        case 'star':
            return <span className={!props.def ? styles.icon : ''}><IconStar {...props}/></span>
        case 'muteFilled':
            return <span className={!props.def ? styles.icon : ''}><IconMuteFilled {...props}/></span>
        case 'muteUnfilled':
            return <span className={!props.def ? styles.icon : ''}><IconMuteUnfilled {...props}/></span>
        case 'bgChat':
            return <span className={!props.def ? styles.icon : ''}><IconBgChat {...props}/></span>
        case 'bgCall':
            return <span className={!props.def ? styles.icon : ''}><IconBgCall {...props}/></span>
        case 'bgVideo':
            return <span className={!props.def ? styles.icon : ''}><IconBgVideo {...props}/></span>
        case 'bgSearch':
            return <span className={!props.def ? styles.icon : ''}><IconBgSearch {...props}/></span>
        case 'folder':
            return <span className={!props.def ? styles.icon : ''}><IconFolder {...props}/></span>
        case 'starUnfilled':
            return <span className={!props.def ? styles.icon : ''}><IconStarUnfilled {...props}/></span>
        case 'threeDots':
            return <span className={!props.def ? styles.icon : ''}><IconThreeDots {...props}/></span>
        case 'paperclip':
            return <span className={!props.def ? styles.icon : ''}><IconPaperclip {...props}/></span>
        case 'parking':
            return <span className={!props.def ? styles.icon : ''}><IconParking {...props}/></span>
        case 'mail':
            return <span className={!props.def ? styles.icon : ''}><IconMail {...props}/></span>
        case 'switchUser':
            return <span className={!props.def ? styles.icon : ''}><IconSwitch {...props}/></span>
        case 'logout':
            return <span className={!props.def ? styles.icon : ''}><IconLogout {...props}/></span>
        case 'contact':
            return <span className={!props.def ? styles.icon : ''}><IconContact {...props}/></span>
        case 'shortCodes':
            return <span className={!props.def ? styles.icon : ''}><IconShortCode {...props}/></span>
        case 'speakerWave':
            return <span className={!props.def ? styles.icon : ''}><IconSpeakerWave {...props}/></span>
        case 'speakerWaveUnfilled':
            return <span className={!props.def ? styles.icon : ''}><IconSpeakerWaveUnfilled {...props}/></span>
        case 'about':
            return <span className={!props.def ? styles.icon : ''}><IconAbout {...props}/></span>
        case 'aboutFilled':
            return <span className={!props.def ? styles.icon : ''}><IconAboutFilled {...props}/></span>
        case 'account':
            return <span className={!props.def ? styles.icon : ''}><IconAccount {...props}/></span>
        case 'accountFilled':
            return <span className={!props.def ? styles.icon : ''}><IconAccountFilled {...props}/></span>
        case 'phoneUnfilled':
            return <span className={!props.def ? styles.icon : ''}><IconPhoneUnfill {...props}/></span>
        case 'messageUnfilled':
            return <span className={!props.def ? styles.icon : ''}><IconMessageUnfilled {...props}/></span>
        case 'notificationAndBadge':
            return <span className={!props.def ? styles.icon : ''}><IconNotificationAndBadge {...props}/></span>
        case 'notificationAndBadgeFilled':
            return <span className={!props.def ? styles.icon : ''}><IconNotificationAndBadgeFilled {...props}/></span>
        case 'contactUnfilled':
            return <span className={!props.def ? styles.icon : ''}><IconContactUnfilled {...props}/></span>
        case 'keypad':
            return <span className={!props.def ? styles.icon : ''}><IconKeypad {...props}/></span>
        case 'keypadDelete':
            return <span className={!props.def ? styles.icon : ''}><IconKeypadDelete/></span>;
        case 'download':
            return <span className={!props.def ? styles.icon : ''}><IconDownload/></span>;
        case 'upload':
            return <span className={!props.def ? styles.icon : ''}><IconUpload/></span>;
        case 'deleteChat':
            return <span className={!props.def ? styles.icon : ''}><IconDeleteChat/></span>;
        case 'keypadSquare':
            return <span className={!props.def ? styles.icon : ''}><IconKeypadSquare/></span>;
        case 'hold':
            return <span className={!props.def ? styles.icon : ''}><IconHold/></span>;
        case 'transfer':
            return <span className={!props.def ? styles.icon : ''}><IconTransfer/></span>;
        case 'microphone':
            return <span className={!props.def ? styles.icon : ''}><IconMicrophone/></span>;
        case 'muteMicrophone':
            return <span className={!props.def ? styles.icon : ''}><IconMuteMicrophone/></span>;
        case 'hamburger':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconHamburger {...props}/></span>
        case 'loop':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconLoop {...props}/></span>
        case 'phoneAnswer':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconPhoneAnswer {...props} /></span>
        case 'phoneDecline':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconPhoneDecline {...props} /></span>
        case 'pin':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconPin {...props} /></span>
        case 'blfAvailable':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconBLFAvailable {...props} /></span>
        case 'blfUnavailable':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconBLFUnavailable {...props} /></span>
        case 'blfCalling':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconBLFCalling {...props} /></span>
        case 'blfOffline':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconBLFOffline {...props} /></span>
        case 'callswitchBlfAvailable':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconCallswitchBLFAvailable {...props} /></span>
        case 'callswitchBlfUnavailable':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconCallswitchBLFUnavailable {...props} /></span>
        case 'callswitchBlfCalling':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconCallswitchBLFCalling {...props} /></span>
        case 'ear':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconEar {...props} /></span>
        case 'whisper':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconWhisper {...props} /></span>
        case 'callEAA':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconCallEAA {...props} /></span>
        case 'callHAA':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconCallHAA {...props} /></span>
        case 'deviceDesktop':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconDeviceDesktop {...props} /></span>
        case 'devicePhone':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconDevicePhone {...props} /></span>
        case 'switchCalls':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSwitchCalls {...props} /></span>
        case 'addContactCircle':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconAddContactCircle {...props} /></span>
        case 'dualChatBubbleUnfill':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconDualChatBubbleUnfill {...props} /></span>
        case 'dualChatBubbleFill':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconDualChatBubbleFill {...props} /></span>
        case 'gridView':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconGridView {...props} /></span>
        case 'rowView':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconRowView {...props} /></span>
        case 'csLogo':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconCSLogo {...props} /></span>
        case 'plusCircleFill':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconPlusCircleFill {...props} /></span>
        case 'minusCircleFill':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconMinusCircleFill {...props} /></span>
        case 'addCall':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconAddCall {...props} /></span>
        case 'messageDots':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconMessageDots {...props} /></span>
        case 'arrowRightExit':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconArrowRightExit {...props} /></span>
        case 'play':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconPlayArrow {...props} /></span>
        case 'playFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconPlayArrowFilled {...props} /></span>
        case 'pause':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconPause {...props} /></span>
        case 'pauseFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconPauseFilled {...props} /></span>
        case 'stop':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconStop {...props} /></span>
        case 'stopFilled':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconStopFilled {...props} /></span>
        case 'record':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconRecordCircle {...props} /></span>
        case 'recordFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconRecordCircleFilled {...props} /></span>
        case 'group':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconGroup {...props} /></span>
        case 'single':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSingle {...props} /></span>
        case 'plus':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconPlus {...props} /></span>
        case 'underline':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconUnderline {...props} /></span>
        case 'italic':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconItalic {...props} /></span>
        case 'bold':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconBold {...props} /></span>
        case 'listNumber':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconNumberList {...props} /></span>
        case 'listDash':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconDashList {...props} /></span>
        case 'strikethrough':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconStrikethrough {...props} /></span>
        case 'emoji':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconEmoji {...props} /></span>
        case 'text':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconText {...props} /></span>
        case 'tick':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconCheckmark {...props} /></span>
        case 'arrowDownWithLine':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconArrowDownWithLine {...props} /></span>
        case 'settingsAccount':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsAccount {...props} /></span>
        case 'settingsAccountFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsAccountFilled {...props} /></span>
        case 'settingsCalls':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsCalls {...props} /></span>
        case 'settingsCallsFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsCallsFilled {...props} /></span>
        case 'settingsChat':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsChat {...props} /></span>
        case 'settingsChatFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsChatFilled {...props} /></span>
        case 'settingsNotifications':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsNotifications {...props} /></span>
        case 'settingsNotificationsFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsNotificationsFilled {...props} /></span>
        case 'settingsContacts':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsContacts {...props} /></span>
        case 'settingsContactsFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsContactsFilled {...props} /></span>
        case 'settingsAudio':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsAudio {...props} /></span>
        case 'settingsAudioFilled':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSettingsAudioFilled {...props} /></span>
        case 'smallEmoji':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconSmallEmoji {...props} /></span>
        case 'smallReply':
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconSmallReply {...props} /></span>
        case 'smallComment':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSmallComment {...props} /></span>
        case 'smallThreeDots':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconSmallThreeDots {...props} /></span>
        case 'questionMark':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconQuestionMark {...props} /></span>
        case 'minus':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconMinus {...props} /></span>
        case 'provisionBlf':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconProvisionBlf {...props} /></span>
        case 'provisionSpeedDial':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconProvisionSpeedDial {...props} /></span>
        case 'addUser':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconAddUser {...props} /></span>
        case 'attachmentFile':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconAttachmentFile {...props} /></span>
        case 'attachmentPlay':
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconAttachmentPlay {...props} /></span>
        case "blfAux":
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconBLFAux {...props} /></span>
        case "bin":
            return <span className={!props.def ? styles.icon : ''} onClick={props.onClick}><IconBin {...props} /></span>
        case "copy":
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconCopy {...props} /></span>
        case "link":
            return <span className={!props.def ? styles.icon : ''}
                         onClick={props.onClick}><IconLink {...props} /></span>

        default:
            return (
                <></>
            )
    }

}

export default Icon;
