import {SvgProps} from "../index";

export const IconArrowDown: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M21.166 10.8003C21.5977 11.2319 21.5977 11.9318 21.166 12.3634L15.2711 18.2583C14.8395 18.6899 14.1397 18.6899 13.708 18.2583L7.81315 12.3634C7.38151 11.9318 7.38151 11.2319 7.81315 10.8003C8.24479 10.3687 8.94462 10.3687 9.37626 10.8003L14.4896 15.9136L19.6029 10.8003C20.0345 10.3687 20.7344 10.3687 21.166 10.8003Z"
              fill="black"
        />
    </svg>
)

export default IconArrowDown;