import {SvgProps} from "../index";

export const IconAccount: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M14.4766 14.1944C16.6035 14.1944 18.3262 12.3135 18.3262 10.0108C18.3262 7.74323 16.6035 5.94147 14.4766 5.94147C12.3584 5.94147 10.6182 7.76959 10.627 10.0284C10.6357 12.3223 12.3496 14.1944 14.4766 14.1944ZM14.4766 12.6563C13.2637 12.6563 12.2354 11.5049 12.2354 10.0284C12.2266 8.58698 13.2549 7.47955 14.4766 7.47955C15.707 7.47955 16.7178 8.5694 16.7178 10.0108C16.7178 11.4874 15.6982 12.6563 14.4766 12.6563ZM8.99219 22.3682H19.9521C21.4727 22.3682 22.2021 21.8848 22.2021 20.8477C22.2021 18.4307 19.1875 15.2139 14.4766 15.2139C9.76562 15.2139 6.74219 18.4307 6.74219 20.8477C6.74219 21.8848 7.47168 22.3682 8.99219 22.3682ZM8.71973 20.8301C8.50879 20.8301 8.42969 20.7598 8.42969 20.6016C8.42969 19.2569 10.6006 16.752 14.4766 16.752C18.3438 16.752 20.5146 19.2569 20.5146 20.6016C20.5146 20.7598 20.4355 20.8301 20.2246 20.8301H8.71973Z"
            fill="black"
        />
    </svg>
)

export default IconAccount;
