import {SvgProps} from "../index";

export const IconPin: React.FC<SvgProps> = ({
                                                width = 28,
                                                height = 28,
                                                ariaHidden,
                                                title,
                                                description
                                            }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >

        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM14 17.2314C12.0049 17.2314 10.4053 15.6318 10.4053 13.6367C10.4053 11.6592 12.0049 10.0508 14 10.0508C15.9863 10.0508 17.5859 11.6592 17.5859 13.6367C17.5859 15.6318 15.9863 17.2314 14 17.2314Z" fill="#1C1C1E"/>
    </svg>
)

export default IconPin;
