import {SvgProps} from "../index";

export const IconArrowRightExit: React.FC<SvgProps> = ({
                                                       width = 28,
                                                       height = 28,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10.5042 7.85275C10.9358 7.42111 11.6356 7.42111 12.0673 7.85275L17.9621 13.7476C18.3938 14.1793 18.3938 14.8791 17.9621 15.3107L12.0673 21.2056C11.6356 21.6373 10.9358 21.6373 10.5042 21.2056C10.0725 20.774 10.0725 20.0741 10.5042 19.6425L15.6175 14.5292L10.5042 9.41587C10.0725 8.98423 10.0725 8.2844 10.5042 7.85275Z"
              fill="black"
        />
    </svg>
)

export default IconArrowRightExit;
