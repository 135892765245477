import {SvgProps} from "../index";

export const IconDualChatBubbleUnfill: React.FC<SvgProps> = ({
                                                    width = 28,
                                                    height = 28,
                                                    ariaHidden,
                                                    title,
                                                    description
                                                }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M7.10938 23.4277C7.61035 23.4277 8.0498 23.2168 8.65625 22.6895L11.4775 20.1758C12.1279 20.8525 13.1035 21.2129 14.3252 21.2129H17.3311L20.2578 23.7266C20.8379 24.2188 21.251 24.4912 21.7256 24.4912C22.4287 24.4912 22.8418 23.9902 22.8418 23.2256V21.2129H23.2461C25.4609 21.2129 26.9375 19.8594 26.9375 17.583V12.2129C26.9375 9.90137 25.4346 8.5127 23.1758 8.5127H21.6465V7.94141C21.6465 5.55078 20.1787 4.13574 17.7705 4.13574H4.93848C2.60059 4.13574 1.0625 5.55078 1.0625 7.94141V16.168C1.0625 18.5234 2.57422 19.9209 4.86816 19.9209H5.97559V22.1709C5.97559 22.9443 6.39746 23.4277 7.10938 23.4277ZM7.54883 19.0596C7.54883 18.5322 7.22363 18.2598 6.78418 18.2598H5.14941C3.69043 18.2598 2.81152 17.4424 2.81152 15.9219V8.13477C2.81152 6.61426 3.69922 5.78809 5.14941 5.78809H17.5508C19.0098 5.78809 19.8887 6.61426 19.8887 8.13477V8.5127H14.3252C11.9873 8.5127 10.5635 9.90137 10.5635 12.2129V17.583C10.5635 17.9521 10.5986 18.2861 10.6689 18.6025L7.54883 21.5205V19.0596ZM21.2773 22.584L18.5264 20.0879C18.1396 19.7363 17.8145 19.5781 17.2959 19.5781H14.5098C13.1299 19.5781 12.2949 18.8135 12.2949 17.3545V12.3799C12.2949 10.9385 13.1299 10.1562 14.5098 10.1562H22.9824C24.3711 10.1562 25.2061 10.9385 25.2061 12.3799V17.3545C25.2061 18.8047 24.3623 19.5781 22.9824 19.5781H22.0332C21.6025 19.5781 21.2773 19.8418 21.2773 20.3691V22.584Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconDualChatBubbleUnfill;
