import {SvgProps} from "../index";

export const IconShrink: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.88086 12.9512H11.7588C12.7695 12.9512 13.3584 12.3623 13.3584 11.3516V6.54395C13.3584 5.99023 12.9365 5.57715 12.374 5.57715C11.8203 5.57715 11.416 5.98145 11.416 6.54395V7.16797L11.6006 9.89258L9.57031 7.75684L7.05664 5.22559C6.87207 5.03223 6.63477 4.94434 6.37109 4.94434C5.77344 4.94434 5.34277 5.34863 5.34277 5.94629C5.34277 6.21875 5.44824 6.46484 5.6416 6.64941L8.16406 9.17188L10.2998 11.2021L7.55762 11.0176H6.88086C6.31836 11.0176 5.90527 11.4131 5.90527 11.9756C5.90527 12.5381 6.31836 12.9512 6.88086 12.9512ZM15.5732 21.8457C16.127 21.8457 16.5312 21.4414 16.5312 20.8789V20.167L16.3467 17.4512L18.377 19.5869L20.9434 22.1621C21.1191 22.3555 21.3652 22.4434 21.6201 22.4434C22.2178 22.4434 22.6484 22.0391 22.6484 21.4414C22.6484 21.1689 22.543 20.9316 22.3584 20.7383L19.7832 18.1719L17.6475 16.1416L20.3896 16.3262H21.1455C21.708 16.3262 22.1211 15.9219 22.1211 15.3682C22.1211 14.7969 21.708 14.3926 21.1455 14.3926H16.1885C15.1777 14.3926 14.5889 14.9814 14.5889 15.9922V20.8789C14.5889 21.4326 15.0107 21.8457 15.5732 21.8457Z"
            fill="black"
        />
    </svg>
)

export default IconShrink;
