import {SvgProps} from "../index";

export const IconSettingsChatFilled: React.FC<SvgProps> = ({
                                                         width = 28,
                                                         height = 28,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M19.8334 2.33334H8.16671C4.94671 2.33334 2.33337 4.93501 2.33337 8.14334V16.2867C2.33337 19.495 4.94671 22.0967 8.16671 22.0967H9.91671C10.2317 22.0967 10.6517 22.3067 10.85 22.5633L12.6 24.885C13.37 25.9117 14.63 25.9117 15.4 24.885L17.15 22.5633C17.3717 22.2717 17.7217 22.0967 18.0834 22.0967H19.8334C23.0534 22.0967 25.6667 19.495 25.6667 16.2867V8.14334C25.6667 4.93501 23.0534 2.33334 19.8334 2.33334ZM9.33337 14C8.68004 14 8.16671 13.475 8.16671 12.8333C8.16671 12.1917 8.69171 11.6667 9.33337 11.6667C9.97504 11.6667 10.5 12.1917 10.5 12.8333C10.5 13.475 9.98671 14 9.33337 14ZM14 14C13.3467 14 12.8334 13.475 12.8334 12.8333C12.8334 12.1917 13.3584 11.6667 14 11.6667C14.6417 11.6667 15.1667 12.1917 15.1667 12.8333C15.1667 13.475 14.6534 14 14 14ZM18.6667 14C18.0134 14 17.5 13.475 17.5 12.8333C17.5 12.1917 18.025 11.6667 18.6667 11.6667C19.3084 11.6667 19.8334 12.1917 19.8334 12.8333C19.8334 13.475 19.32 14 18.6667 14Z"
            fill="black"/>
    </svg>
)

export default IconSettingsChatFilled;
