import {SvgProps} from "../index";

export const IconMinusCircleFill: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM10.5371 14.5332C9.9834 14.5332 9.58789 14.208 9.58789 13.6719C9.58789 13.1357 9.96582 12.793 10.5371 12.793H17.4453C18.0166 12.793 18.3857 13.1357 18.3857 13.6719C18.3857 14.208 17.999 14.5332 17.4453 14.5332H10.5371Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconMinusCircleFill;