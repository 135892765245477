import {SvgProps} from "../index";

export const IconCheckedBox: React.FC<SvgProps> = ({
                                                   width = 11,
                                                   height = 11,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 11 11"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <rect x="0.78125" y="0.5" width="10" height="10" rx="3" fill="var(--color-danger)"/>
        <path
            d="M4.97321 7.96414L2.61304 5.67633L3.74188 4.58566L4.97321 5.7818L7.82082 3.03564L8.94967 4.11991L4.97321 7.96414Z"
            fill="#FEFEFE"
        />
    </svg>
)

export default IconCheckedBox;
