import {SvgProps} from "../index";

export const IconSmallReply: React.FC<SvgProps> = ({
                                                       width = 26,
                                                       height = 26,
                                                       ariaHidden,
                                                       title,
                                                       description
                                                   }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 26 26"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <g clipPath="url(#clip0_1436_10567)">
            <path
                d="M11.4224 9.3806V6.98364L5.83032 12.5455L11.4224 18.1073V15.4087C15.6392 15.4647 19.337 17.6689 21.4501 20.9737C21.5095 20.526 21.5407 20.0659 21.5407 19.6026C21.5407 14.0066 17.0239 9.46454 11.4224 9.37749V9.3806Z"
                stroke="#868895" strokeWidth="1.5" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1436_10567">
                <rect width="18" height="18" fill="white" transform="translate(4.68555 4)"/>
            </clipPath>
        </defs>
    </svg>
)

export default IconSmallReply;
