import {SvgProps} from "../index";

export const IconChatFilled: React.FC<SvgProps> = ({
                                                      width = 28,
                                                      height = 28,
                                                      ariaHidden,
                                                      title,
                                                      description
                                                  }) => (
    <svg
        width={width}
        height={height}
        viewBox="-4 -5 43 42"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.4839 28.6176C14.5161 28.8106 15.4844 28.8756 16.5167 28.8756C25.3549 28.8756 32.5156 22.6172 32.5156 14.8756C32.5156 7.13387 25.3538 0.875488 16.5156 0.875488C7.67743 0.875488 0.515625 7.13387 0.515625 14.8756C0.515625 19.3265 2.83821 23.2624 6.51614 25.8429V32.8755L13.4839 28.6176ZM9.35417 16.6901C9.03644 16.8896 8.66048 17.005 8.25758 17.005C7.11738 17.005 6.19306 16.0808 6.19306 14.9406C6.19306 13.9041 6.95696 13.046 7.9525 12.8985C8.05205 12.8838 8.15392 12.8761 8.25758 12.8761C9.39778 12.8761 10.3221 13.8004 10.3221 14.9406C10.3221 15.6779 9.9356 16.3249 9.35417 16.6901ZM18.5802 14.9406C18.5802 16.0808 17.6558 17.005 16.5156 17.005C15.3754 17.005 14.4511 16.0808 14.4511 14.9406C14.4511 13.8004 15.3754 12.8761 16.5156 12.8761C17.6558 12.8761 18.5802 13.8004 18.5802 14.9406ZM24.7737 17.005C25.9139 17.005 26.8382 16.0808 26.8382 14.9406C26.8382 13.8004 25.9139 12.8761 24.7737 12.8761C23.6335 12.8761 22.7092 13.8004 22.7092 14.9406C22.7092 16.0808 23.6335 17.005 24.7737 17.005Z"
              fill="black"
        />
    </svg>
)

export default IconChatFilled;
