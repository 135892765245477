/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */

export const googleLogoutHandler = (): void => {
    if ((window as any).gapi) {
        const auth2 = (window as any).gapi.auth2.getAuthInstance();
        if (auth2 != null) {
            auth2
                .signOut()
                .then(auth2.disconnect())
                .catch(() => {});
        }
    }
};

export const isMobileDevice = (): boolean => {
    let hasTouchScreen;
    if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
        // @ts-ignore
        hasTouchScreen = (navigator as Navigator).msMaxTouchPoints > 0;
    } else {
        // @ts-ignore
        const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
        if (mQ && mQ.media === '(pointer:coarse)') {
            hasTouchScreen = mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = (navigator as Navigator).userAgent;
            hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
    }

    return hasTouchScreen && window.outerWidth < 1025;
};

export const convertUserTag = (tag:any, toTag?) => {
    if (toTag) {
        return `@${tag.display_name}#${tag.uuid}`
    }
    return `${tag.split('#')[0]}`
}

export const checkAudioDevice = ({
    devices,
    deviceId,
    deviceName
}: {
    devices: { deviceId: string; label: string }[];
    deviceId: string;
    deviceName?: string;
}): string => {
    if (devices.some(device => device.deviceId === deviceId)) {
        return deviceId;
    }

    return devices.find(device => device.label === deviceName)?.deviceId || 'default';
};

export const isPreview =
    window.location.hostname.includes('preview') || window.location.hostname.includes('localhost');

export const isSuperUser =
    isPreview &&
    (window.location.hostname.includes('localhost') ||
        (localStorage?.['yay.app.settings'] &&
            JSON.parse(localStorage['yay.app.settings'])?.isSuperUser));

export const isPulseHd =
    isPreview
        ? localStorage.getItem('previewTheme') === 'pulsehd'
        : window.location.hostname.includes('pulsehd');

export const isCallSwitch =
    isPreview
        ? localStorage.getItem('previewTheme') === 'callswitch'
        : window.location.hostname.includes('callswitch');

export const isYay =
    isPreview
        ? localStorage.getItem('previewTheme') === 'yay'
        : window.location.hostname.includes('yay');

export const versionCompare = (a: string, b: string) => {
    if (a === b) {
        return 0;
    }

    const a_components = a.split(".");
    const b_components = b.split(".");

    const len = Math.min(a_components.length, b_components.length);

    // loop while the components are equal
    for (let i = 0; i < len; i += 1) {
        // A bigger than B
        if (parseInt(a_components[i]) > parseInt(b_components[i])) {
            return 1;
        }

        // B bigger than A
        if (parseInt(a_components[i]) < parseInt(b_components[i])) {
            return -1;
        }
    }

    // If one's a prefix of the other, the longer one is greater.
    if (a_components.length > b_components.length) {
        return 1;
    }

    if (a_components.length < b_components.length) {
        return -1;
    }

    // Otherwise they are the same.
    return 0;
}

export const blobToBase64 = (blob) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
        // convert image file to base64 string
        if (typeof reader.result === "string") {
            const baseImgString =
                reader.result
                    .replace("data:", "")
                    .replace(/^.+,/, "")
                    .split(',').pop()
            if (baseImgString) {
                resolve(baseImgString)
            } else {
                reject(new Error('cannot get image string'))
            }
        }
        reject(new Error('error with image'))
    }, false);

    reader.readAsDataURL(blob);
});
