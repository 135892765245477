import {SvgProps} from "../index";

export const IconDevicePhone: React.FC<SvgProps> = ({
                                                          width = 28,
                                                          height = 28,
                                                          ariaHidden,
                                                          title,
                                                          description
                                                      }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M6.42383 21.2305C6.42383 22.7686 7.46094 23.7705 9.05176 23.7705H18.9395C20.5303 23.7705 21.5674 22.7686 21.5674 21.2305V6.07812C21.5674 4.53125 20.5303 3.5293 18.9395 3.5293H9.05176C7.46094 3.5293 6.42383 4.53125 6.42383 6.07812V21.2305ZM8.17285 20.9404V6.35938C8.17285 5.67383 8.57715 5.28711 9.29785 5.28711H18.6934C19.4141 5.28711 19.8184 5.67383 19.8184 6.35938V20.9404C19.8184 21.626 19.4141 22.0215 18.6934 22.0215H9.29785C8.57715 22.0215 8.17285 21.626 8.17285 20.9404ZM11.416 21.2832H16.584C16.8477 21.2832 17.0322 21.1074 17.0322 20.8438C17.0322 20.5713 16.8477 20.3955 16.584 20.3955H11.416C11.1611 20.3955 10.9678 20.5713 10.9678 20.8438C10.9678 21.1074 11.1611 21.2832 11.416 21.2832Z"
            fill="#1C1C1E"
        />
    </svg>
)

export default IconDevicePhone;