import {SvgProps} from "../index";

export const EmojiIcon: React.FC<SvgProps> = ({
                                                  width = 28,
                                                  height = 28,
                                                  ariaHidden,
                                                  title,
                                                  description
                                              }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 29"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.6929 3.49999C8.06348 3.49999 3.4999 8.06357 3.4999 13.693C3.4999 19.3225 8.06348 23.8861 13.6929 23.8861C19.3224 23.8861 23.886 19.3225 23.886 13.693C23.886 8.06357 19.3224 3.49999 13.6929 3.49999ZM1.3999 13.693C1.3999 6.90378 6.90369 1.39999 13.6929 1.39999C20.4822 1.39999 25.986 6.90378 25.986 13.693C25.986 20.4823 20.4822 25.9861 13.6929 25.9861C6.90369 25.9861 1.3999 20.4823 1.3999 13.693Z"
              fill="black"/>
        <path
            d="M12.4177 10.4536C12.4177 11.3283 11.7086 12.0374 10.8338 12.0374C9.95911 12.0374 9.25 11.3283 9.25 10.4536C9.25 9.57886 9.95911 8.86975 10.8338 8.86975C11.7086 8.86975 12.4177 9.57886 12.4177 10.4536Z"
            fill="black"/>
        <path
            d="M18.7502 10.4536C18.7502 11.3283 18.0411 12.0374 17.1664 12.0374C16.2916 12.0374 15.5825 11.3283 15.5825 10.4536C15.5825 9.57886 16.2916 8.86975 17.1664 8.86975C18.0411 8.86975 18.7502 9.57886 18.7502 10.4536Z"
            fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.48758 15.6614C9.98978 15.3715 10.632 15.5435 10.9219 16.0457C11.2339 16.5861 11.6826 17.0348 12.2229 17.3468C12.7633 17.6587 13.3762 17.823 14.0002 17.823C14.6241 17.823 15.237 17.6587 15.7774 17.3468C16.3177 17.0348 16.7664 16.5861 17.0784 16.0457C17.3684 15.5435 18.0105 15.3715 18.5127 15.6614C19.0149 15.9514 19.187 16.5935 18.8971 17.0957C18.4008 17.9553 17.687 18.6691 16.8274 19.1654C15.9678 19.6617 14.9927 19.923 14.0002 19.923C13.0076 19.923 12.0325 19.6617 11.1729 19.1654C10.3133 18.6691 9.59953 17.9553 9.10325 17.0957C8.8133 16.5935 8.98537 15.9514 9.48758 15.6614Z"
              fill="black"/>
    </svg>
)

export default EmojiIcon;
