import {SvgProps} from "../index";

export const IconProvisionBlf: React.FC<SvgProps> = ({
                                                         width = 28,
                                                         height = 28,
                                                         ariaHidden,
                                                         title,
                                                         description
                                                     }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 29 28"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path
            d="M8.12047 14.7559H5.92906L5.91734 13.5488H7.75719C8.08141 13.5488 8.34703 13.5078 8.55406 13.4258C8.76109 13.3398 8.91539 13.2168 9.01695 13.0566C9.12242 12.8926 9.17516 12.6934 9.17516 12.459C9.17516 12.1934 9.12438 11.9785 9.02281 11.8145C8.92516 11.6504 8.77086 11.5312 8.55992 11.457C8.35289 11.3828 8.08531 11.3457 7.75719 11.3457H6.53844V18.5H4.78063V9.96875H7.75719C8.25328 9.96875 8.69664 10.0156 9.08727 10.1094C9.4818 10.2031 9.81578 10.3457 10.0892 10.5371C10.3627 10.7285 10.5716 10.9707 10.7162 11.2637C10.8607 11.5527 10.933 11.8965 10.933 12.2949C10.933 12.6465 10.8529 12.9707 10.6927 13.2676C10.5365 13.5645 10.2884 13.8066 9.94859 13.9941C9.61266 14.1816 9.1732 14.2852 8.63023 14.3047L8.12047 14.7559ZM8.0443 18.5H5.44859L6.13414 17.1289H8.0443C8.35289 17.1289 8.60484 17.0801 8.80016 16.9824C8.99547 16.8809 9.14 16.7441 9.23375 16.5723C9.3275 16.4004 9.37438 16.2031 9.37438 15.9805C9.37438 15.7305 9.33141 15.5137 9.24547 15.3301C9.16344 15.1465 9.03063 15.0059 8.84703 14.9082C8.66344 14.8066 8.42125 14.7559 8.12047 14.7559H6.42711L6.43883 13.5488H8.5482L8.9525 14.0234C9.47203 14.0156 9.89 14.1074 10.2064 14.2988C10.5267 14.4863 10.7591 14.7305 10.9037 15.0312C11.0521 15.332 11.1263 15.6543 11.1263 15.998C11.1263 16.5449 11.0072 17.0059 10.7689 17.3809C10.5306 17.752 10.181 18.0312 9.72008 18.2188C9.26305 18.4062 8.70445 18.5 8.0443 18.5ZM17.7891 17.1289V18.5H13.4941V17.1289H17.7891ZM14.0625 9.96875V18.5H12.3047V9.96875H14.0625ZM20.9147 9.96875V18.5H19.1569V9.96875H20.9147ZM24.3131 13.6133V14.9844H20.4342V13.6133H24.3131ZM24.7233 9.96875V11.3457H20.4342V9.96875H24.7233Z"
            fill="#9BA6AF"
        />
    </svg>
)

export default IconProvisionBlf;
