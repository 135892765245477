import {SvgProps} from "../index";

export const IconLoop: React.FC<SvgProps> = ({
                                                   width = 28,
                                                   height = 28,
                                                   ariaHidden,
                                                   title,
                                                   description
                                               }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 33 33"
        fill="none"
        aria-labelledby={!ariaHidden ? `${title ? 'title' : ''} ${description ? 'desc' : ''}` : ''}
        aria-hidden={ariaHidden}
        role='figure'
    >
        {title && <title id="title">{title}</title>}
        {description && <desc id="desc">{description}</desc>}
        <path d="M10.3057 4.53682L4.49268 10.3498V4.66182H0.492676V16.6618H12.4927V12.6618H7.80468L13.1177 7.34882C16.4927 3.97382 22.4307 3.97382 25.8057 7.34882L28.6807 4.53582C23.7427 -0.402179 15.1807 -0.402179 10.3057 4.53582V4.53682ZM20.4927 16.9118V20.9118H25.1807L19.8677 26.2248C16.4927 29.5998 10.5547 29.5998 7.17968 26.2248L4.30468 29.0378C6.80468 31.4758 10.0547 32.8508 13.4927 32.8508C16.9927 32.8508 20.2427 31.4758 22.6807 29.0378L28.4937 23.2248V28.9128H32.4937V16.9128L20.4927 16.9118Z" fill="black"/>
    </svg>
)

export default IconLoop;
